import React, { useContext } from 'react'
import ContainerFields from './ContainerFields'
import InputContainer from '../inputStyles/InputContainer'
import SelectBoxContainer from '../inputStyles/SelectBoxContainer'
import GlobalStore from '../../store/GlobalStore'
import templatesContext from '../../views/Boarding/Template/context/templates_context'

export default function GroupRow({ handleChange,linkBottonInput, parentField, fieldData, field, className, TooltipToggle, inputType, checkOptions = { state: { visible: true, readOnly: true, required: true } } }) {
	const { RootTemplate, LoadingState } = useContext(templatesContext)
	return (
		<div className="row mb-2">
			{RootTemplate.isRoot || (parentField[field] !== null && parentField[field] !== undefined && parentField[field].visible && !parentField[field].readOnly) ? (
				<ContainerFields
					handleChange={handleChange}
					fieldData={fieldData}
					field={field}
					className={className}
					checkOptions={checkOptions}
					TooltipToggle={TooltipToggle}>
					{inputType.type !== 'select' && inputType.type !== 'selectSearch' ? (
						<InputContainer
							inputType={inputType.type}
							divClass="form-group"
							labelClassName="font-weight-bold"
							classNameInput={`form-control ${inputType?.classNameInput}`}
							linkBottonInput={linkBottonInput}
							//isLoading={LoadingState}
							inputDisabled={false}
							TooltipCaption={inputType?.TooltipCaption}
							TooltipValid={inputType?.TooltipValid}
							TooltipValidColorIcon={inputType?.TooltipValidColorIcon ? inputType?.TooltipValidColorIcon : 'color-red'}
							TooltipValidStyle={{ position: 'relative', width: '100%', textAlign: 'right', height: 0, top: '-30px', right: '8px' }}
							prefix={inputType?.prefix}
							suffix={inputType?.suffix}
							maxValue={inputType?.maxValue}
							decimalScale={inputType?.decimalScale}
							allowedNullDate={inputType?.allowedNullDate}
							maxDate={inputType?.maxDate}
							labelValue={inputType.title}
							maxLength={inputType?.maxLength}
							name={field}
							placeholder={inputType?.placeholder}
							value={!LoadingState && (inputType.type !== 'dateBox' ? (inputType.type === 'number' && ['', null].includes(fieldData[field].value) ? 0 : fieldData[field].value) : inputType?.allowedNullDate && fieldData[field].value === null ? null : GlobalStore.validateDate(fieldData[field].value))}
							setValue={(values) => handleChange(field, 'value', inputType.type === 'number' && ['', null].includes(values) ? 0 : values)}
							mask={inputType.mask}
						/>
					) : (
						<SelectBoxContainer
							inputDisabled={false}
							TooltipCaption={inputType?.TooltipCaption}
							TooltipValid={inputType?.TooltipValid}
							TooltipValidColorIcon={inputType?.TooltipValidColorIcon ? inputType?.TooltipValidColorIcon : 'color-red'}
							TooltipValidStyle={{ position: 'relative', width: '100%', textAlign: 'right', height: 0, top: '-34px', paddingRight: '30px' }}
							classNameInput={`${inputType.type !== 'selectSearch' && 'form-control form-select'} ${inputType?.classNameInput}`}
							divClass="form-group mb-1"
							labelClassName="font-weight-bold"
							selectSearch={inputType.type !== 'selectSearch' ? false : true}
							placeholder={inputType?.placeholder}
							labelValue={inputType.title}
							linkBottonInput={linkBottonInput}
							//isLoading={LoadingState}
							name={field}
							value={!LoadingState && fieldData[field].value}
							setValue={(values) => handleChange(field, 'value', values)}
							optionList={
								inputType.type !== 'selectSearch' ? (
									<>
										{inputType.mask.map((opt) => (
											<option
												key={field + opt.value}
												value={opt.value}>
												{opt.text}
											</option>
										))}
									</>
								) : (
									inputType.mask
								)
							}
						/>
					)}
				</ContainerFields>
			) : (
				parentField[field]?.visible && (
					<div className="row mb-2">
						<ContainerFields
							handleChange={handleChange}
							fieldData={fieldData}
							field={field}
							TooltipToggle={TooltipToggle}
							className={className}
							checkOptions={{ state: { visible: checkOptions.state.visible, readOnly: false, required: false }, label: { visible: checkOptions.label?.visible ? checkOptions.label?.visible : '', readOnly: '', required: '' } }}>
							{inputType.type !== 'select' || inputType.type !== 'selectSearch' ? (
								<InputContainer
									inputType={inputType.type}
									divClass="form-group"
									classNameInput={inputType?.classNameInput}
									labelClassName="font-weight-bold"
									//isLoading={LoadingState}
									inputDisabled={false}
									TooltipCaption={inputType?.TooltipCaption}
									TooltipValid={inputType?.TooltipValid}
									TooltipValidColorIcon={inputType?.TooltipValidColorIcon ? inputType?.TooltipValidColorIcon : 'color-red'}
									TooltipValidStyle={{ position: 'relative', width: '100%', textAlign: 'right', height: 0, top: '-30px', right: '8px' }}
									prefix={inputType?.prefix}
									suffix={inputType?.suffix}
									maxValue={inputType?.maxValue}
									decimalScale={inputType?.decimalScale}
									allowedNullDate={inputType?.allowedNullDate}
									maxDate={inputType?.maxDate}
									labelValue={inputType.title}
									maxLength={inputType?.maxLength}
									name={field}
									placeholder={inputType?.placeholder}
									readonly={true}
									value={!LoadingState && (inputType.type !== 'dateBox' ? (inputType.type === 'number' && ['', null].includes(fieldData[field].value) ? 0 : fieldData[field].value) : inputType?.allowedNullDate && fieldData[field].value === null ? null : GlobalStore.validateDate(fieldData[field].value))}
									setValue={(values) => handleChange(field, 'value', inputType.type === 'number' && ['', null].includes(values) ? 0 : values)}
									mask={inputType.mask}
								/>
							) : (
								<SelectBoxContainer
									inputDisabled={false}
									TooltipCaption={inputType?.TooltipCaption}
									TooltipValid={inputType?.TooltipValid}
									TooltipValidColorIcon={inputType?.TooltipValidColorIcon ? inputType?.TooltipValidColorIcon : 'color-red'}
									TooltipValidStyle={{ position: 'relative', width: '100%', textAlign: 'right', height: 0, top: '-34px', paddingRight: '30px' }}
									divClass="form-group mb-1"
									classNameInput={`${inputType.type !== 'selectSearch' && 'form-control form-select'} ${inputType?.classNameInput}`}
									selectSearch={inputType.type !== 'selectSearch' ? false : true}
									placeholder={inputType?.placeholder}
									labelClassName="font-weight-bold"
									labelValue={inputType.title}
									//isLoading={LoadingState}
									name={field}
									readonly={true}
									value={!LoadingState && fieldData[field].value}
									setValue={(values) => handleChange(field, 'value', values)}
									optionList={
										inputType.type !== 'selectSearch' ? (
											<>
												{inputType.mask.map((opt) => (
													<option
														key={field + opt.value}
														value={opt.value}>
														{opt.text}
													</option>
												))}
											</>
										) : (
											inputType.mask
										)
									}
								/>
							)}
						</ContainerFields>
					</div>
				)
			)}
		</div>
	)
}
