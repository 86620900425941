import axios from 'axios'
import { PayabliStorageManager } from '../../../api/localStorageManager'

let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()

export const payPointServiceGET = async (signing, entryPoint, serviceName) => {
	const res = await axios.get(process.env.REACT_APP_URL_API + 'Management/service/' + entryPoint + '/' + serviceName, {
		headers: {
			requestToken: encryptStorage.getItem('pToken'),
			signing: signing
		}
	})
	return res
}

export const servicePOST = async (entry, signing, serviceData) => {
	const res = await axios.post(process.env.REACT_APP_URL_API + 'Management/service/' + entry, serviceData, {
		headers: {
			requestToken: encryptStorage.getItem('pToken'),
			signing: signing
		}
	})
	return res
}

export const servicePUT = async (entry, signing, serviceData) => {
	const res = await axios.put(process.env.REACT_APP_URL_API + 'Management/service/' + entry, serviceData, {
		headers: {
			requestToken: encryptStorage.getItem('pToken'),
			signing: signing
		}
	})
	return res
}

export const serviceDELETE = async (entry, signing, serviceData) => {
	const res = await axios.delete(process.env.REACT_APP_URL_API + 'Management/service/' + entry, {
		headers: {
			requestToken: encryptStorage.getItem('pToken'),
			signing: signing
		},
		data: serviceData
	})
	return res
}
