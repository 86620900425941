import { fraudcontrolGET, fraudcontrolPOST } from '../Connections/FraudControlConnections'
import { toast } from 'react-toastify'
import { action } from 'mobx'

export default function FraudControlFunction({ fraudControl, setFraudControl, signingKey, paramsURL, setLoading }) {
	const handleChangeFraud = action((field, value) => {
		setFraudControl({ ...fraudControl, [field]: value })
	})
	const loadFraudCtr = action(async (id, signing) => {
		const fraud = await loadFraudcontrol(id ? id : paramsURL.id, signing ? signing : signingKey)
		setFraudControl(fraud)
	})
	const loadFraudcontrol = action(async (id, signing) => {
		try {
			const res = await fraudcontrolGET(id, signing)
			return res.data
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			setLoading(false)
			return
		}
	})
	const updateFraudcontrol = action(async (signing) => {
		try {
			const res = await fraudcontrolPOST(paramsURL.id, signing ? signing : signingKey, fraudControl)
			if (res.data.isSuccess === true) {
				toast.success('Status changed successfully!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
			}
			return true
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return false
		}
	})

	return { handleChangeFraud, loadFraudCtr, updateFraudcontrol }
}
