import { managementAuthGET } from '../Connections/AuthConnections'
import { toast } from 'react-toastify'
import { action } from 'mobx'

export default function ReadFunction({ setPayPoint, setBusiness, setOwner, setProcessing, setBanking, setDocumentsRef, setLoading, SetSigningKey }) {
	const fillPayPoint = action((data) => {
		if (data) {
			setPayPoint({
				idPaypoint: data.idPaypoint,
				paypointStatus: data.paypointStatus,
				serviceData: {
					card: {
						acceptVisa: true,
						acceptMastercard: true,
						acceptDiscover: true,
						acceptAmex: true
					},
					ach: {
						acceptWeb: true,
						acceptPPD: true,
						acceptCCD: true
					}
				},
				salesCode: data.salesCode,
				externalPaypointID: data.externalPaypointID,
				credentials: data.credentials,
				entryPoints: data.entryPoints
			})

			setBusiness({
				dbaName: data.dbaName,
				legalName: data.legalName,
				ownType: data.ownType,
				ein: data.ein,
				taxfillname: data.taxfillname,
				license: data.license,
				licenseState: data.licenseState,
				websiteAddress: data.websiteAddress,
				startdate: data.startdate,
				phone: data.phone,
				fax: data.fax,
				bTimezone: data.bTimezone,
				address1: data.address1,
				address2: data.address2,
				city: data.city,
				zip: data.zip,
				state: data.state,
				country: data.country,
				mailAddress1: data.mailAddress1,
				mailAddress2: data.mailAddress2,
				mailCity: data.mailCity,
				mailZip: data.mailZip,
				mailState: data.mailState,
				mailCountry: data.mailCountry,
				sameAddress: data.address1 === data.mailAddress1 && data.address2 === data.mailAddress2 && data.city === data.mailCity && data.zip === data.mailZip && data.state === data.mailState && data.country === data.mailCountry ? true : false
			})
			setOwner({
				ownership: data.ownership,
				contacts: data.contacts
			})
			setProcessing({
				mcc: data.mcc,
				binPerson: data.binPerson,
				binPhone: data.binPhone,
				binWeb: data.binWeb,
				summary: data.summary,
				averageMonthlyVolume: data.averageMonthlyVolume,
				averageTicketAmount: data.averageTicketAmount,
				highTicketAmount: data.highTicketAmount,
				whencharged: data.whencharged,
				whenprovided: data.whenprovided,
				whendelivered: data.whendelivered,
				whenrefund: data.whenrefund,
				actualCurrencySet: data.actualCurrencySet
			})
			setBanking({
				bankData: data.bankData,
				bankDataOld: data.bankData
			})
			setDocumentsRef({ documents: !data.documentsRef ? { filelist: [] } : JSON.parse(data.documentsRef) })
		}
	})
	const getAuth = action(async () => {
		try {
			const res = await managementAuthGET()
			if (!res.data.responseData || res.data.responseData === null) {
				let errorMessage = 'Failed Authentication!'
				toast.error(errorMessage, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
				setLoading(false)

				return
			}
			SetSigningKey(res.data.responseData)
			return res.data.responseData
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			setLoading(false)
			return
		}
	})

	return { fillPayPoint, getAuth }
}
