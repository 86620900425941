import { makeObservable, observable, action } from 'mobx'
import { PayabliStorageManager } from '../api/localStorageManager'
import axios from 'axios'

class BoardingStore {
	constructor() {
		makeObservable(this)
	}

	orgId = 0

	//	@observable
	template_data = {}

	@observable
	error_data = {}

	@observable
	nextClicked = false

	componentDidMount() {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		this.orgId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId
	}

	@action
	errors() {
		if (Object.keys(this.error_data).length === 0) return false
		return true
	}

	@action
	setNextClicked(value) {
		this.nextClicked = value
	}

	@action
	isValidTab(n, o, c) {
		switch (n) {
			case 0:
				if (this.error_data.dbaname || this.error_data.legalname || this.error_data.btype || this.error_data.website || this.error_data.ein || this.error_data.taxfillname || this.error_data.license || this.error_data.licstate || this.error_data.startdate || this.error_data.phonenumber || this.error_data.faxnumber) return false
				if (this.error_data.baddress || this.error_data.baddress1 || this.error_data.bcity || this.error_data.bstate || this.error_data.bzip || this.error_data.bcountry) return false
				if (this.error_data.maddress || this.error_data.maddress1 || this.error_data.mcity || this.error_data.mstate || this.error_data.mzip || this.error_data.mcountry) return false
				break
			case 1:
				if (this.error_data.ownername_0 || this.error_data.ownertitle_0 || this.error_data.ownerpercent_0 || this.error_data.ownerssn_0 || this.error_data.ownerdob_0) return false
				if (this.error_data.ownerphone1_0 || this.error_data.ownerphone2_0 || this.error_data.owneremail_0 || this.error_data.ownerdriver_0 || this.error_data.odriverstate_0) return false
				if (this.error_data.oaddress_0 || this.error_data.ocity_0 || this.error_data.ostate_0 || this.error_data.ozip_0 || this.error_data.ocountry_0) return false
				if (o.length > 0) {
					for (var j = 0; j < o.length; j++) {
						let v = o[j]
						if (this.error_data['ownername_' + v] || this.error_data['ownertitle_' + v] || this.error_data['ownerpercent_' + v] || this.error_data['ownerssn_' + v] || this.error_data['ownerdob_' + v]) return false
						if (this.error_data['ownerphone1_' + v] || this.error_data['ownerphone2_' + v] || this.error_data['owneremail_' + v] || this.error_data['ownerdriver_' + v] || this.error_data['odriverstate_' + v]) return false
						if (this.error_data['oaddress_' + v] || this.error_data['ocity_' + v] || this.error_data['ostate_' + v] || this.error_data['ozip_' + v] || this.error_data['ocountry_' + v]) return false
					}
				}
				if (this.error_data.contactName_0 || this.error_data.contactTitle_0 || this.error_data.contactEmail_0 || this.error_data.contactPhone_0) return false
				if (c.length > 0) {
					for (var jj = 0; jj < c.length; jj++) {
						let vv = c[jj]
						if (this.error_data['contactName_' + vv] || this.error_data['contactTitle_' + vv] || this.error_data['contactEmail_' + vv] || this.error_data['contactPhone_' + vv]) return false
					}
				}
				break
			case 2:
				if (this.error_data.mcc || this.error_data.bsummary || this.error_data.binperson || this.error_data.binweb || this.error_data.binphone || this.error_data.avgmonthly || this.error_data.highticketamt || this.error_data.ticketamt || this.error_data.whenCharged || this.error_data.whenDelivered || this.error_data.whenProvided || this.error_data.whenRefunded) return false
				break
			case 3:
				if (this.template_data.card && this.template_data.card.value === false && !this.template_data.ach) return false
				if (this.template_data.ach && this.template_data.ach.value === false && !this.template_data.card) return false
				if (this.template_data.card && this.template_data.card.value === false && this.template_data.ach && this.template_data.ach.value === false) return false
				break
			case 4:
				if (
					this.error_data.deposit_accountNumber ||
					this.error_data.deposit_routingNumber ||
					this.error_data.deposit_bankName ||
					this.error_data.withdrawal_accountNumber ||
					this.error_data.withdrawal_bankName ||
					this.error_data.withdrawal_routingNumber ||
					this.error_data.signer_address ||
					this.error_data.signer_city ||
					this.error_data.signer_country ||
					this.error_data.signer_dob ||
					this.error_data.signer_email ||
					this.error_data.signer_name ||
					this.error_data.signer_phone ||
					this.error_data.signer_ssn ||
					this.error_data.signer_state ||
					this.error_data.signer_zip
				)
					return false
				let ek = Object.keys(this.error_data)
				if (ek.length > 0) {
					let dc = ek.filter((element) => element.startsWith('doc_'))
					if (dc.length > 0) return false
				}
				break
			default:
				return true
		}
		return true
	}

	@action
	existsTemplateData(name) {
		if (this.template_data[name]) return true
		return false
	}

	@action
	setTemplateData(name, value) {
		this.template_data[name] = { value: value }
	}

	@action
	clearTemplateData() {
		this.template_data = {}
	}

	@action
	setErrorData(name, value) {
		if (value === false) {
			if (this.error_data[name]) delete this.error_data[name]
		} else {
			this.error_data[name] = { value: value }
		}
	}

	@action
	clearErrorData() {
		this.error_data = {}
	}

	newTemplate(orgId, nTemplate) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.post(process.env.REACT_APP_URL_API + 'Templates/' + orgId, nTemplate, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	updateTemplate(orgId, nTemplate) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.put(process.env.REACT_APP_URL_API + 'Templates/' + orgId, nTemplate, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	updateApplicationStatus(appId, status) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let tk = encryptStorage.getItem('pToken') || process.env.REACT_APP_TOKEN
		return axios
			.get(process.env.REACT_APP_URL_API + 'Boarding/appsts/' + appId + '/' + status, {
				headers: { requestToken: tk }
			})
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	async sendToProcessor(appId) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let tk = encryptStorage.getItem('pToken') || process.env.REACT_APP_TOKEN
		return axios
			.get(`${process.env.REACT_APP_URL_API}Management/auth`, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				}
			})
			.then(async (dt) => {
				if (!dt.data.responseData || dt.data.responseData === null) {
					return
				}
				let signing = dt.data.responseData
				try {
					return await axios.post(
						`${process.env.REACT_APP_URL_API}Management/sendapptoprocessor`,
						{
							appId: appId,
							processorName: 'GP'
						},
						{
							headers: { requestToken: tk, signing: signing }
						}
					)
				} catch (error) {
					return error
				}
			})
	}

	sendDocToProcessor(appId, filename) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let tk = encryptStorage.getItem('pToken') || process.env.REACT_APP_TOKEN
		return axios
			.get(`${process.env.REACT_APP_URL_API}Management/auth`, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				}
			})
			.then((dt) => {
				if (!dt.data.responseData || dt.data.responseData === null) {
					return
				}
				let signing = dt.data.responseData
				axios
					.post(
						`${process.env.REACT_APP_URL_API}Management/uploadfilefromattachmenttoprocessor`,
						{
							appId: appId,
							processorName: 'GP',
							filename: filename
						},
						{
							headers: {
								requestToken: tk,
								signing: signing
							}
						}
					)
					.then((res) => {
						return res
					})
					.catch((error) => {
						throw error
					})
			})
	}

	updatePaypointStatus(paypointId, status) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let tk = encryptStorage.getItem('pToken')
		return axios
			.get(process.env.REACT_APP_URL_API + 'Management/auth', {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				}
			})
			.then((dt) => {
				if (!dt.data.responseData || dt.data.responseData === null) {
					return
				}
				let signing = dt.data.responseData

				axios
					.get(process.env.REACT_APP_URL_API + 'Management/paypointStatus/' + paypointId + '/' + status, {
						headers: { requestToken: tk, signing: signing }
					})
					.then((res) => {
						return res
					})
					.catch((error) => {
						throw error
					})
			})
	}

	@action
	sendLinkApplication(appid, email) {
		return new Promise((resolve, reject) => {
			let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
			var app = {}
			axios
				.put(process.env.REACT_APP_URL_API + 'Boarding/app/' + appid, app, {
					headers: { requestToken: encryptStorage.getItem('pToken') },
					params: { mail2: email }
				})
				.then((res) => {
					resolve(res)
				})
				.catch((error) => {
					reject(error)
				})
		})
	}

	@action
	sendLink(appid, email) {
		return new Promise((resolve, reject) => {
			let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
			axios
				.get(process.env.REACT_APP_URL_API + 'Boarding/linkbyId/' + appid, {
					headers: {
						requestToken: encryptStorage.getItem('pToken')
					},
					params: { mail2: email }
				})
				.then((res) => {
					resolve(res)
				})
				.catch((error) => {
					reject(error)
				})
		})
	}

	@observable
	lastsavedapp = {}

	@action
	setAppData(app) {
		this.lastsavedapp = app
	}

	@action
	clearAppData() {
		this.lastsavedapp = {}
	}

	@action
	saveApplication(orgId, nLink, o, c, upd, appid, email) {
		return new Promise((resolve, reject) => {
			this.clearAppData()
			var app = {}
			app.orgId = orgId
			if (orgId === 0) {
				let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
				app.orgId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId
			}
			app.templateId = nLink
			if (this.template_data.dbaname) app.dbaname = this.template_data.dbaname.value
			if (this.template_data.legalname) app.legalname = this.template_data.legalname.value
			if (this.template_data.website) app.website = this.template_data.website.value
			if (this.template_data.ein) app.ein = this.template_data.ein.value
			if (this.template_data.license) app.license = this.template_data.license.value
			if (this.template_data.licstate) app.licstate = this.template_data.licstate.value
			if (this.template_data.taxfillname) app.taxfillname = this.template_data.taxfillname.value
			if (this.template_data.startdate) app.startdate = this.template_data.startdate.value
			if (this.template_data.phonenumber) app.phonenumber = this.template_data.phonenumber.value
			if (this.template_data.faxnumber) app.faxnumber = this.template_data.faxnumber.value
			if (this.template_data.btype) app.btype = this.template_data.btype.value
			if (this.template_data.baddress) app.baddress = this.template_data.baddress.value
			if (this.template_data.baddress1) app.baddress1 = this.template_data.baddress1.value
			if (this.template_data.bcity) app.bcity = this.template_data.bcity.value
			if (this.template_data.bstate) app.bstate = this.template_data.bstate.value
			if (this.template_data.bzip) app.bzip = this.template_data.bzip.value
			if (this.template_data.bcountry) app.bcountry = this.template_data.bcountry.value
			if (this.template_data.maddress) app.maddress = this.template_data.maddress.value
			if (this.template_data.maddress1) app.maddress1 = this.template_data.maddress1.value
			if (this.template_data.mcity) app.mcity = this.template_data.mcity.value
			if (this.template_data.mstate) app.mstate = this.template_data.mstate.value
			if (this.template_data.mzip) app.mzip = this.template_data.mzip.value
			if (this.template_data.mcountry) app.mcountry = this.template_data.mcountry.value
			if (this.template_data.mcc) app.mcc = this.template_data.mcc.value
			if (this.template_data.bsummary) app.bsummary = this.template_data.bsummary.value
			if (this.template_data.whenCharged) app.whenCharged = this.template_data.whenCharged.value
			if (this.template_data.whenProvided) app.whenProvided = this.template_data.whenProvided.value
			if (this.template_data.whenDelivered) app.whenDelivered = this.template_data.whenDelivered.value
			if (this.template_data.whenRefunded) app.whenRefunded = this.template_data.whenRefunded.value
			if (this.template_data.binperson) app.binperson = parseInt(this.template_data.binperson.value, 10)
			if (this.template_data.binphone) app.binphone = parseInt(this.template_data.binphone.value, 10)
			if (this.template_data.binweb) app.binweb = parseInt(this.template_data.binweb.value, 10)
			if (this.template_data.avgmonthly) app.avgmonthly = parseFloat(this.template_data.avgmonthly.value)
			if (this.template_data.ticketamt) app.ticketamt = parseFloat(this.template_data.ticketamt.value)
			if (this.template_data.highticketamt) app.highticketamt = parseFloat(this.template_data.highticketamt.value)
			if (this.template_data.annualRevenue) app.annualRevenue = parseFloat(this.template_data.annualRevenue.value)
			var ow = o
			ow.splice(0, 0, 0)
			if (o.length > 0) {
				app.ownership = []
				for (var j = 0; j < o.length; j++) {
					let v = o[j]
					let owner = {}
					if (this.template_data['ownername_' + v]) owner.ownername = this.template_data['ownername_' + v].value
					if (this.template_data['ownertitle_' + v]) owner.ownertitle = this.template_data['ownertitle_' + v].value
					if (this.template_data['ownerpercent_' + v]) owner.ownerpercent = parseInt(this.template_data['ownerpercent_' + v].value, 10)
					if (this.template_data['ownerssn_' + v]) owner.ownerssn = this.template_data['ownerssn_' + v].value
					if (this.template_data['ownerdob_' + v]) owner.ownerdob = this.template_data['ownerdob_' + v].value
					if (this.template_data['ownerphone1_' + v]) owner.ownerphone1 = this.template_data['ownerphone1_' + v].value
					if (this.template_data['ownerphone2_' + v]) owner.ownerphone2 = this.template_data['ownerphone2_' + v].value
					if (this.template_data['owneremail_' + v]) owner.owneremail = this.template_data['owneremail_' + v].value
					if (this.template_data['ownerdriver_' + v]) owner.ownerdriver = this.template_data['ownerdriver_' + v].value
					if (this.template_data['odriverstate_' + v]) owner.odriverstate = this.template_data['odriverstate_' + v].value
					if (this.template_data['oaddress_' + v]) owner.oaddress = this.template_data['oaddress_' + v].value
					if (this.template_data['ocity_' + v]) owner.ocity = this.template_data['ocity_' + v].value
					if (this.template_data['ostate_' + v]) owner.ostate = this.template_data['ostate_' + v].value
					if (this.template_data['ozip_' + v]) owner.ozip = this.template_data['ozip_' + v].value
					if (this.template_data['ocountry_' + v]) owner.ocountry = this.template_data['ocountry_' + v].value
					app.ownership.push(owner)
				}
			}
			var cw = c
			cw.push(0)
			if (c.length > 0) {
				app.contacts = []
				for (var jj = 0; jj < c.length; jj++) {
					let vv = c[jj]
					let ct = {}
					if (this.template_data['contactName_' + vv]) ct.contactName = this.template_data['contactName_' + vv].value
					if (this.template_data['contactTitle_' + vv]) ct.contactTitle = this.template_data['contactTitle_' + vv].value
					if (this.template_data['contactEmail_' + vv]) ct.contactEmail = this.template_data['contactEmail_' + vv].value
					if (this.template_data['contactPhone_' + vv]) ct.contactPhone = this.template_data['contactPhone_' + vv].value
					app.contacts.push(ct)
				}
			}
			if (this.template_data.deposit_bankName || this.template_data.deposit_accountNumber || this.template_data.deposit_routingAccount || this.template_data.deposit_typeAccount) {
				app.depositAccount = {}
				if (this.template_data.deposit_bankName) app.depositAccount.bankName = this.template_data.deposit_bankName.value
				if (this.template_data.deposit_accountNumber && !isNaN(this.template_data.deposit_accountNumber.value)) app.depositAccount.accountNumber = this.template_data.deposit_accountNumber.value
				if (this.template_data.deposit_routingAccount) app.depositAccount.routingAccount = this.template_data.deposit_routingAccount.value
				if (this.template_data.deposit_typeAccount) app.depositAccount.typeAccount = this.template_data.deposit_typeAccount.value
			}
			if (this.template_data.withdrawal_bankName || this.template_data.withdrawal_accountNumber || this.template_data.withdrawal_routingAccount || this.template_data.withdrawal_typeAccount) {
				app.withdrawalAccount = {}
				if (this.template_data.withdrawal_bankName) app.withdrawalAccount.bankName = this.template_data.withdrawal_bankName.value
				if (this.template_data.withdrawal_accountNumber && !isNaN(this.template_data.withdrawal_accountNumber.value)) app.withdrawalAccount.accountNumber = this.template_data.withdrawal_accountNumber.value
				if (this.template_data.withdrawal_routingAccount) app.withdrawalAccount.routingAccount = this.template_data.withdrawal_routingAccount.value
				if (this.template_data.withdrawal_typeAccount) app.withdrawalAccount.typeAccount = this.template_data.withdrawal_typeAccount.value
			}
			if (this.template_data.card || this.template_data.ach) {
				app.services = { card: null, ach: null }
				if (this.template_data.card) {
					app.services.card = { acceptVisa: false, acceptMastercard: false, acceptDiscover: false, acceptAmex: false, tiersName: '' }
					if (this.template_data.card_visa && this.template_data.card_visa.value === true) app.services.card.acceptVisa = true
					if (this.template_data.card_mastercard && this.template_data.card_mastercard.value === true) app.services.card.acceptMastercard = true
					if (this.template_data.card_discover && this.template_data.card_discover.value === true) app.services.card.acceptDiscover = true
					if (this.template_data.card_amex && this.template_data.card_amex.value === true) app.services.card.acceptAmex = true
					if (this.template_data.card_tierName?.value) app.services.card.tiersName = this.template_data.card_tierName.value?.toUpperCase()
				}
				if (this.template_data.ach) {
					app.services.ach = { acceptWeb: false, acceptPPD: false, acceptCCD: false, tiersName: '' }
					if (this.template_data.ach_web && this.template_data.ach_web.value === true) app.services.ach.acceptWeb = true
					if (this.template_data.ach_tierName?.value) app.services.card.tiersName = this.template_data.ach_tierName.value?.toUpperCase()

				}
			}
			app.signer = {
				name: null,
				ssn: null,
				dob: null,
				phone: null,
				email: null,
				address: null,
				state: null,
				country: null,
				city: null,
				zip: null,
				acceptance: null
			}
			if (this.template_data.signer_name) app.signer.name = this.template_data.signer_name.value
			if (this.template_data.signer_ssn) app.signer.ssn = this.template_data.signer_ssn.value
			if (this.template_data.signer_dob) app.signer.dob = this.template_data.signer_dob.value
			if (this.template_data.signer_phone) app.signer.phone = this.template_data.signer_phone.value
			if (this.template_data.signer_email) app.signer.email = this.template_data.signer_email.value
			if (this.template_data.signer_address) app.signer.address = this.template_data.signer_address.value
			if (this.template_data.signer_address2) app.signer.address1 = this.template_data.signer_address2.value
			if (this.template_data.signer_state) app.signer.state = this.template_data.signer_state.value
			if (this.template_data.signer_country) app.signer.country = this.template_data.signer_country.value
			if (this.template_data.signer_city) app.signer.city = this.template_data.signer_city.value
			if (this.template_data.signer_zip) app.signer.zip = this.template_data.signer_zip.value
			if (this.template_data.signer_acceptance) app.signer.acceptance = this.template_data.signer_acceptance.value
			if(app.btype === 'Non-Profit Org'){
				const ownerSigner = {
					ownername: app.signer.name,
					ownerssn: app.signer.ssn,
					ownerdob: app.signer.dob,
					ownerphone1: app.signer.phone,
					owneremail: app.signer.email,
					oaddress: app.signer.address,
					ocity: app.signer.city,
					ostate: app.signer.state,
					ozip: app.signer.zip,
					ocountry: app.signer.country,
				}
				if (this.template_data['ownertitle_0']) ownerSigner.ownertitle = this.template_data['ownertitle_0'].value
				if (this.template_data['ownerpercent_0']) ownerSigner.ownerpercent = parseInt(this.template_data['ownerpercent_0'].value, 10)
				if (this.template_data['ownerphone2_0']) ownerSigner.ownerphone2 = this.template_data['ownerphone2_0'].value
				if (this.template_data['ownerdriver_0']) ownerSigner.ownerdriver = this.template_data['ownerdriver_0'].value
				if (this.template_data['odriverstate_0']) ownerSigner.odriverstate = this.template_data['odriverstate_0'].value
				app.ownership[0] = ownerSigner;
			}
			this.readAttachments().then((res) => {
				app.attachments = res
				this.setAppData(app)
				//ready to submit
				let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
				let tk = encryptStorage.getItem('pToken') || process.env.REACT_APP_TOKEN
				if (upd && appid > 0) {
					axios
						.put(process.env.REACT_APP_URL_API + 'Boarding/app/' + appid, app, {
							headers: { requestToken: tk },
							params: { mail2: email }
						})
						.then((res) => {
							resolve(res)
						})
						.catch((error) => {
							reject(error)
						})
				} else {
					axios
						.post(process.env.REACT_APP_URL_API + 'Boarding/app', app, {
							headers: { requestToken: tk },
							params: { mail2: email }
						})
						.then((res) => {
							resolve(res)
						})
						.catch((error) => {
							reject(error)
						})
				}
			})
		})
	}

	readAttachments() {
		return new Promise((resolve) => {
			let fs = []
			let fp = []
			let ek = Object.keys(this.template_data)
			if (ek.length > 0) {
				let dc = ek.filter((element) => element.startsWith('doc_'))
				if (dc.length > 0) {
					for (var fi = 0; fi < dc.length; fi++) {
						if (this.template_data[dc[fi]].value && this.template_data[dc[fi]].value !== null) {
							fp.push(this.template_data[dc[fi]].value)
						}
					}
				}
			}
			if (fp.length === 0) {
				resolve(fs)
			}
			let xi = 0
			let pfx = 0
			while (xi < fp.length) {
				let ff = fp[xi]
				this.getBase64(ff)
					.then((res) => {
						let fo = {}
						fo.filename = ff.name
						fo.ftype = fo.filename.split('.').pop()
						fo.fContent = res.split(',').pop()
						fs.push(fo)
						pfx++
						if (pfx >= fp.length) {
							resolve(fs)
						}
					})
					.catch((err) => {
						console.log(err)
						pfx++
						if (pfx >= fp.length) {
							resolve(fs)
						}
					})
				xi++
			}
		})
	}

	async getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()

			reader.onload = (res) => {
				resolve(res.target.result)
			}
			reader.onerror = (err) => reject(err)
			reader.readAsDataURL(file)
		})
	}

	insertValues(object, element) {
		if (this.template_data[element] || this.template_data[element]?.value !== undefined) {
			object === null ? (object = { value: this.template_data[element].value }) : (object.value = this.template_data[element].value)
		}
		if (this.template_data[element + '_v']?.value !== undefined) {
			object === null ? (object = { visible: this.template_data[element + '_v'].value }) : (object.visible = this.template_data[element + '_v'].value)
		}
		if (this.template_data[element + '_ro']?.value !== undefined) {
			object === null ? (object = { readOnly: this.template_data[element + '_ro'].value }) : (object.readOnly = this.template_data[element + '_ro'].value)
		}
		if (this.template_data[element + '_rq']?.value !== undefined) {
			object === null ? (object = { required: this.template_data[element + '_rq'].value }) : (object.required = this.template_data[element + '_rq'].value)
		}
		return object
	}

	fillOutContent(ntemplate) {
		const structure = {
			businessData: ['dbaname', 'legalname', 'btype', 'website', 'ein', 'taxfillname', 'license', 'licstate', 'startdate', 'phonenumber', 'faxnumber', 'baddress', 'baddress1', 'bcity', 'bstate', 'bzip', 'bcountry', 'maddress', 'maddress1', 'mcity', 'mstate', 'mzip', 'mcountry'],
			ownershipData: ['ownername', 'ownertitle', 'ownerpercent', 'ownerssn', 'ownerdob', 'ownerphone1', 'ownerphone2', 'owneremail', 'ownerdriver', 'odriverstate', 'oaddress', 'ocity', 'ostate', 'ozip', 'ocountry', 'contactName', 'contactTitle', 'contactEmail', 'contactPhone'],
			processingData: ['mcc', 'bsummary', 'binperson', 'binphone', 'binweb', 'avgmonthly', 'ticketamt', 'highticketamt', 'annualRevenue', 'whenCharged', 'whenProvided', 'whenDelivered', 'whenRefunded'],
			servicesData: { card: ['provider', 'processor', 'gateway', 'tierName', 'pdfTemplateId', 'pricingType', 'discountFrequency', 'fundingRollup', 'passthroughCost'], ach: ['provider', 'processor', 'gateway', 'tierName', 'pdfTemplateId', 'pricingType', 'discountFrequency', 'fundingRollup'] }
		}

		for (let parentElement in structure) {
			if (Array.isArray(structure[parentElement])) {
				structure[parentElement].forEach((element) => {
					ntemplate.templateContent[parentElement][element] = this.insertValues(ntemplate.templateContent[parentElement][element], element)
				})
			} else {
				for (let childElement in structure[parentElement]) {
					structure[parentElement][childElement].forEach((element) => {
						ntemplate.templateContent[parentElement][childElement][element] = this.insertValues(ntemplate.templateContent[parentElement][childElement][element], childElement + '_' + element)
					})
				}
			}
		}

		//ownership =========================================================================================
		if (this.template_data.multipleOwners_v?.value !== undefined) {
			ntemplate.templateContent.ownershipData.multipleOwners = this.template_data.multipleOwners_v.value
		}
		if (this.template_data.multipleContacts_v?.value !== undefined) {
			ntemplate.templateContent.ownershipData.multipleContacts = this.template_data.multipleContacts_v.value
		}

		//CARD
		if (this.template_data.card_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.visible = this.template_data.card_v.value
		}

		if (this.template_data.cardAcceptance_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardAcceptance.visible = this.template_data.cardAcceptance_v.value
		}
		if (this.template_data.card_visa_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardAcceptance.types.visa.visible = this.template_data.card_visa_v.value
		}
		if (this.template_data.card_mc_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardAcceptance.types.masterCard.visible = this.template_data.card_mc_v.value
		}
		if (this.template_data.card_dc_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardAcceptance.types.discover.visible = this.template_data.card_dc_v.value
		}
		if (this.template_data.card_amex_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardAcceptance.types.amex.visible = this.template_data.card_amex_v.value
		}
		//ICP
		if (this.template_data.cardICP_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardICP.visible = this.template_data.cardICP_v.value
		}
		if (this.template_data.cardICP_visible_percentxAuth_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardICP_percentxAuth = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.cardICP_visible_percentxAuth_v.value.toString(),
				readOnly: true
			}
		}
		if (this.template_data.cardICP_visible_amountxAuth_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardICP_amountxAuth = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.cardICP_visible_amountxAuth_v.value.toString(),
				readOnly: true
			}
		}
		if (this.template_data.cardICP_visible_lowPayRange_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardICP_lowPayRange = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.cardICP_visible_lowPayRange_v.value.toString(),
				readOnly: true
			}
		}
		if (this.template_data.cardICP_visible_highPayRange_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardICP_highPayRange = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.cardICP_visible_highPayRange_v.value.toString(),
				readOnly: true
			}
		}
		if (this.template_data.icp_visa_percentxAuth) {
			ntemplate.templateContent.servicesData.card.cardICP.tiers[0].visa.percentxAuth = parseFloat(this.template_data.icp_visa_percentxAuth.value)
		}
		if (this.template_data.icp_visa_amountxAuth) {
			ntemplate.templateContent.servicesData.card.cardICP.tiers[0].visa.amountxAuth = parseFloat(this.template_data.icp_visa_amountxAuth.value)
		}
		if (this.template_data.icp_visa_lowPayRange) {
			ntemplate.templateContent.servicesData.card.cardICP.tiers[0].visa.lowPayRange = parseFloat(this.template_data.icp_visa_lowPayRange.value)
		}
		if (this.template_data.icp_visa_highPayRange) {
			ntemplate.templateContent.servicesData.card.cardICP.tiers[0].visa.highPayRange = parseFloat(this.template_data.icp_visa_highPayRange.value)
		}
		if (this.template_data.icp_masterCard_percentxAuth) {
			ntemplate.templateContent.servicesData.card.cardICP.tiers[0].masterCard.percentxAuth = parseFloat(this.template_data.icp_masterCard_percentxAuth.value)
		}
		if (this.template_data.icp_masterCard_amountxAuth) {
			ntemplate.templateContent.servicesData.card.cardICP.tiers[0].masterCard.amountxAuth = parseFloat(this.template_data.icp_masterCard_amountxAuth.value)
		}
		if (this.template_data.icp_masterCard_lowPayRange) {
			ntemplate.templateContent.servicesData.card.cardICP.tiers[0].masterCard.lowPayRange = parseFloat(this.template_data.icp_masterCard_lowPayRange.value)
		}
		if (this.template_data.icp_masterCard_highPayRange) {
			ntemplate.templateContent.servicesData.card.cardICP.tiers[0].masterCard.highPayRange = parseFloat(this.template_data.icp_masterCard_highPayRange.value)
		}
		if (this.template_data.icp_discover_percentxAuth) {
			ntemplate.templateContent.servicesData.card.cardICP.tiers[0].discover.percentxAuth = parseFloat(this.template_data.icp_discover_percentxAuth.value)
		}
		if (this.template_data.icp_discover_amountxAuth) {
			ntemplate.templateContent.servicesData.card.cardICP.tiers[0].discover.amountxAuth = parseFloat(this.template_data.icp_discover_amountxAuth.value)
		}
		if (this.template_data.icp_discover_lowPayRange) {
			ntemplate.templateContent.servicesData.card.cardICP.tiers[0].discover.lowPayRange = parseFloat(this.template_data.icp_discover_lowPayRange.value)
		}
		if (this.template_data.icp_discover_highPayRange) {
			ntemplate.templateContent.servicesData.card.cardICP.tiers[0].discover.highPayRange = parseFloat(this.template_data.icp_discover_highPayRange.value)
		}
		if (this.template_data.icp_amex_percentxAuth) {
			ntemplate.templateContent.servicesData.card.cardICP.tiers[0].amex.percentxAuth = parseFloat(this.template_data.icp_amex_percentxAuth.value)
		}
		if (this.template_data.icp_amex_amountxAuth) {
			ntemplate.templateContent.servicesData.card.cardICP.tiers[0].amex.amountxAuth = parseFloat(this.template_data.icp_amex_amountxAuth.value)
		}
		if (this.template_data.icp_amex_lowPayRange) {
			ntemplate.templateContent.servicesData.card.cardICP.tiers[0].amex.lowPayRange = parseFloat(this.template_data.icp_amex_lowPayRange.value)
		}
		if (this.template_data.icp_amex_highPayRange) {
			ntemplate.templateContent.servicesData.card.cardICP.tiers[0].amex.highPayRange = parseFloat(this.template_data.icp_amex_highPayRange.value)
		}
		//flat
		if (this.template_data.cardFlat_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardFlat.visible = this.template_data.cardFlat_v.value
		}
		// Visibility
		if (this.template_data.cardFlat_visible_percentxAuth_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardFlat_percentxAuth = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.cardFlat_visible_percentxAuth_v.value.toString(),
				readOnly: true
			}
		}
		if (this.template_data.cardFlat_visible_amountxAuth_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardFlat_amountxAuth = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.cardFlat_visible_amountxAuth_v.value.toString(),
				readOnly: true
			}
		}
		if (this.template_data.cardFlat_visible_lowPayRange_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardFlat_lowPayRange = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.cardFlat_visible_lowPayRange_v.value.toString(),
				readOnly: true
			}
		}
		if (this.template_data.cardFlat_visible_highPayRange_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardFlat_highPayRange = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.cardFlat_visible_highPayRange_v.value.toString(),
				readOnly: true
			}
		}

		if (this.template_data.flat_visa_percentxAuth) {
			ntemplate.templateContent.servicesData.card.cardFlat.tiers[0].visa.percentxAuth = parseFloat(this.template_data.flat_visa_percentxAuth.value)
		}
		if (this.template_data.flat_visa_amountxAuth) {
			ntemplate.templateContent.servicesData.card.cardFlat.tiers[0].visa.amountxAuth = parseFloat(this.template_data.flat_visa_amountxAuth.value)
		}
		if (this.template_data.flat_visa_lowPayRange) {
			ntemplate.templateContent.servicesData.card.cardFlat.tiers[0].visa.lowPayRange = parseFloat(this.template_data.flat_visa_lowPayRange.value)
		}
		if (this.template_data.flat_visa_highPayRange) {
			ntemplate.templateContent.servicesData.card.cardFlat.tiers[0].visa.highPayRange = parseFloat(this.template_data.flat_visa_highPayRange.value)
		}
		if (this.template_data.flat_masterCard_percentxAuth) {
			ntemplate.templateContent.servicesData.card.cardFlat.tiers[0].masterCard.percentxAuth = parseFloat(this.template_data.flat_masterCard_percentxAuth.value)
		}
		if (this.template_data.flat_masterCard_amountxAuth) {
			ntemplate.templateContent.servicesData.card.cardFlat.tiers[0].masterCard.amountxAuth = parseFloat(this.template_data.flat_masterCard_amountxAuth.value)
		}
		if (this.template_data.flat_masterCard_lowPayRange) {
			ntemplate.templateContent.servicesData.card.cardFlat.tiers[0].masterCard.lowPayRange = parseFloat(this.template_data.flat_masterCard_lowPayRange.value)
		}
		if (this.template_data.flat_masterCard_highPayRange) {
			ntemplate.templateContent.servicesData.card.cardFlat.tiers[0].masterCard.highPayRange = parseFloat(this.template_data.flat_masterCard_highPayRange.value)
		}
		if (this.template_data.flat_discover_percentxAuth) {
			ntemplate.templateContent.servicesData.card.cardFlat.tiers[0].discover.percentxAuth = parseFloat(this.template_data.flat_discover_percentxAuth.value)
		}
		if (this.template_data.flat_discover_amountxAuth) {
			ntemplate.templateContent.servicesData.card.cardFlat.tiers[0].discover.amountxAuth = parseFloat(this.template_data.flat_discover_amountxAuth.value)
		}
		if (this.template_data.flat_discover_lowPayRange) {
			ntemplate.templateContent.servicesData.card.cardFlat.tiers[0].discover.lowPayRange = parseFloat(this.template_data.flat_discover_lowPayRange.value)
		}
		if (this.template_data.flat_discover_highPayRange) {
			ntemplate.templateContent.servicesData.card.cardFlat.tiers[0].discover.highPayRange = parseFloat(this.template_data.flat_discover_highPayRange.value)
		}
		if (this.template_data.flat_amex_percentxAuth) {
			ntemplate.templateContent.servicesData.card.cardFlat.tiers[0].amex.percentxAuth = parseFloat(this.template_data.flat_amex_percentxAuth.value)
		}
		if (this.template_data.flat_amex_amountxAuth) {
			ntemplate.templateContent.servicesData.card.cardFlat.tiers[0].amex.amountxAuth = parseFloat(this.template_data.flat_amex_amountxAuth.value)
		}
		if (this.template_data.flat_amex_lowPayRange) {
			ntemplate.templateContent.servicesData.card.cardFlat.tiers[0].amex.lowPayRange = parseFloat(this.template_data.flat_amex_lowPayRange.value)
		}
		if (this.template_data.flat_amex_highPayRange) {
			ntemplate.templateContent.servicesData.card.cardFlat.tiers[0].amex.highPayRange = parseFloat(this.template_data.flat_amex_highPayRange.value)
		}
		//PT
		if (this.template_data.cardPassThrough_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.visible = this.template_data.cardPassThrough_v.value
		}
		if (this.template_data.cardPassThrough_visible_percentxAuth_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardPassThrough_percentxAuth = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.cardPassThrough_visible_percentxAuth_v.value.toString(),
				readOnly: true
			}
		}
		if (this.template_data.cardPassThrough_visible_amountxAuth_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardPassThrough_amountxAuth = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.cardPassThrough_visible_amountxAuth_v.value.toString(),
				readOnly: true
			}
		}
		if (this.template_data.cardPassThrough_visible_percentRecurring_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardPassThrough_percentRecurring = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.cardPassThrough_visible_percentRecurring_v.value.toString(),
				readOnly: true
			}
		}
		if (this.template_data.cardPassThrough_visible_amountRecurring_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardPassThrough_amountRecurring = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.cardPassThrough_visible_amountRecurring_v.value.toString(),
				readOnly: true
			}
		}
		if (this.template_data.cardPassThrough_visible_lowPayRange_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardPassThrough_lowPayRange = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.cardPassThrough_visible_lowPayRange_v.value.toString(),
				readOnly: true
			}
		}
		if (this.template_data.cardPassThrough_visible_highPayRange_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardPassThrough_highPayRange = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.cardPassThrough_visible_highPayRange_v.value.toString(),
				readOnly: true
			}
		}
		if (this.template_data.pt_amex_lowPayRange) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].amex.lowPayRange = parseFloat(this.template_data.pt_amex_lowPayRange.value)
		}
		if (this.template_data.pt_amex_highPayRange) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].amex.highPayRange = parseFloat(this.template_data.pt_amex_highPayRange.value)
		}
		if (this.template_data.pt_amex_amountFeeRecurring) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].amex.amountFeeRecurring = parseFloat(this.template_data.pt_amex_amountFeeRecurring.value)
		}
		if (this.template_data.pt_amex_percentFeeRecurring) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].amex.percentFeeRecurring = parseFloat(this.template_data.pt_amex_percentFeeRecurring.value)
		}
		if (this.template_data.pt_amex_amountFeeOneTime) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].amex.amountFeeOneTime = parseFloat(this.template_data.pt_amex_amountFeeOneTime.value)
		}
		if (this.template_data.pt_amex_percentFeeOneTime) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].amex.percentFeeOneTime = parseFloat(this.template_data.pt_amex_percentFeeOneTime.value)
		}
		if (this.template_data.pt_visa_lowPayRange) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].visa.lowPayRange = parseFloat(this.template_data.pt_visa_lowPayRange.value)
		}
		if (this.template_data.pt_visa_highPayRange) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].visa.highPayRange = parseFloat(this.template_data.pt_visa_highPayRange.value)
		}
		if (this.template_data.pt_visa_amountFeeRecurring) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].visa.amountFeeRecurring = parseFloat(this.template_data.pt_visa_amountFeeRecurring.value)
		}
		if (this.template_data.pt_visa_percentFeeRecurring) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].visa.percentFeeRecurring = parseFloat(this.template_data.pt_visa_percentFeeRecurring.value)
		}
		if (this.template_data.pt_visa_amountFeeOneTime) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].visa.amountFeeOneTime = parseFloat(this.template_data.pt_visa_amountFeeOneTime.value)
		}
		if (this.template_data.pt_visa_percentFeeOneTime) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].visa.percentFeeOneTime = parseFloat(this.template_data.pt_visa_percentFeeOneTime.value)
		}
		if (this.template_data.pt_masterCard_lowPayRange) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].masterCard.lowPayRange = parseFloat(this.template_data.pt_masterCard_lowPayRange.value)
		}
		if (this.template_data.pt_masterCard_highPayRange) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].masterCard.highPayRange = parseFloat(this.template_data.pt_masterCard_highPayRange.value)
		}
		if (this.template_data.pt_masterCard_amountFeeRecurring) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].masterCard.amountFeeRecurring = parseFloat(this.template_data.pt_masterCard_amountFeeRecurring.value)
		}
		if (this.template_data.pt_masterCard_percentFeeRecurring) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].masterCard.percentFeeRecurring = parseFloat(this.template_data.pt_masterCard_percentFeeRecurring.value)
		}
		if (this.template_data.pt_masterCard_amountFeeOneTime) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].masterCard.amountFeeOneTime = parseFloat(this.template_data.pt_masterCard_amountFeeOneTime.value)
		}
		if (this.template_data.pt_masterCard_percentFeeOneTime) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].masterCard.percentFeeOneTime = parseFloat(this.template_data.pt_masterCard_percentFeeOneTime.value)
		}
		if (this.template_data.pt_discover_lowPayRange) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].discover.lowPayRange = parseFloat(this.template_data.pt_discover_lowPayRange.value)
		}
		if (this.template_data.pt_discover_highPayRange) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].discover.highPayRange = parseFloat(this.template_data.pt_discover_highPayRange.value)
		}
		if (this.template_data.pt_discover_amountFeeRecurring) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].discover.amountFeeRecurring = parseFloat(this.template_data.pt_discover_amountFeeRecurring.value)
		}
		if (this.template_data.pt_discover_percentFeeRecurring) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].discover.percentFeeRecurring = parseFloat(this.template_data.pt_discover_percentFeeRecurring.value)
		}
		if (this.template_data.pt_discover_amountFeeOneTime) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].discover.amountFeeOneTime = parseFloat(this.template_data.pt_discover_amountFeeOneTime.value)
		}
		if (this.template_data.pt_discover_percentFeeOneTime) {
			ntemplate.templateContent.servicesData.card.cardPassThrough.tiers[0].discover.percentFeeOneTime = parseFloat(this.template_data.pt_discover_percentFeeOneTime.value)
		}
		//Fees
		if (this.template_data.cardFees_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardFees.visible = this.template_data.cardFees_v.value
		}
		if (this.template_data.transactionCardFee) {
			ntemplate.templateContent.servicesData.card.cardFees.transactionCardFee.value = this.template_data.transactionCardFee.value
		}
		if (this.template_data.transactionCardFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardFees.transactionCardFee.visible = this.template_data.transactionCardFee_v.value
		}
		if (this.template_data.chargebackCardFee) {
			ntemplate.templateContent.servicesData.card.cardFees.chargebackCardFee.value = this.template_data.chargebackCardFee.value
		}
		if (this.template_data.chargebackCardFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardFees.chargebackCardFee.visible = this.template_data.chargebackCardFee_v.value
		}
		if (this.template_data.achBatchCardFee) {
			ntemplate.templateContent.servicesData.card.cardFees.achBatchCardFee.value = this.template_data.achBatchCardFee.value
		}
		if (this.template_data.achBatchCardFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardFees.achBatchCardFee.visible = this.template_data.achBatchCardFee_v.value
		}
		if (this.template_data.avsCardFee) {
			ntemplate.templateContent.servicesData.card.cardFees.avsCardFee.value = this.template_data.avsCardFee.value
		}
		if (this.template_data.avsCardFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardFees.avsCardFee.visible = this.template_data.avsCardFee_v.value
		}
		if (this.template_data.montlyPlatformCardFee) {
			ntemplate.templateContent.servicesData.card.cardFees.montlyPlatformCardFee.value = this.template_data.montlyPlatformCardFee.value
		}
		if (this.template_data.montlyPlatformCardFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardFees.montlyPlatformCardFee.visible = this.template_data.montlyPlatformCardFee_v.value
		}
		if (this.template_data.annualCardFee) {
			ntemplate.templateContent.servicesData.card.cardFees.annualCardFee.value = this.template_data.annualCardFee.value
		}
		if (this.template_data.annualCardFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardFees.annualCardFee.visible = this.template_data.annualCardFee_v.value
		}
		if (this.template_data.minimumProcessingCardFee) {
			ntemplate.templateContent.servicesData.card.cardFees.minimumProcessingCardFee.value = this.template_data.minimumProcessingCardFee.value
		}
		if (this.template_data.minimumProcessingCardFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardFees.minimumProcessingCardFee.visible = this.template_data.minimumProcessingCardFee_v.value
		}
		if (this.template_data.ddaRejectsCardFee) {
			ntemplate.templateContent.servicesData.card.cardFees.ddaRejectsCardFee.value = this.template_data.ddaRejectsCardFee.value
		}
		if (this.template_data.ddaRejectsCardFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardFees.ddaRejectsCardFee.visible = this.template_data.ddaRejectsCardFee_v.value
		}
		if (this.template_data.retrievalCardFee) {
			ntemplate.templateContent.servicesData.card.cardFees.retrievalCardFee.value = this.template_data.retrievalCardFee.value
		}
		if (this.template_data.retrievalCardFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardFees.retrievalCardFee.visible = this.template_data.retrievalCardFee_v.value
		}
		if (this.template_data.monthlyPCICardFee) {
			ntemplate.templateContent.servicesData.card.cardFees.monthlyPCICardFee.value = this.template_data.monthlyPCICardFee.value
		}
		if (this.template_data.monthlyPCICardFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardFees.monthlyPCICardFee.visible = this.template_data.monthlyPCICardFee_v.value
		}
		if (this.template_data.earlyTerminationCardFee) {
			ntemplate.templateContent.servicesData.card.cardFees.earlyTerminationCardFee.value = this.template_data.earlyTerminationCardFee.value
		}
		if (this.template_data.earlyTerminationCardFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.card.cardFees.earlyTerminationCardFee.visible = this.template_data.earlyTerminationCardFee_v.value
		}

		//ACH
		if (this.template_data.ach_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.visible = this.template_data.ach_v.value
		}

		if (this.template_data.achAcceptance_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achAcceptance.visible = this.template_data.achAcceptance_v.value
		}
		if (this.template_data.ach_web_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achAcceptance.types.web.visible = this.template_data.ach_web_v.value
		}
		if (this.template_data.ach_ppd_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achAcceptance.types.ppd.visible = this.template_data.ach_ppd_v.value
		}
		if (this.template_data.ach_ccd_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achAcceptance.types.ccd.visible = this.template_data.ach_ccd_v.value
		}
		//absorb
		if (this.template_data.achAbsorb_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achAbsorb.visible = this.template_data.achAbsorb_v.value
		}
		if (this.template_data.achAbsorb_visible_lowPayRange_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achAbsorb_lowPayRange = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.achAbsorb_visible_lowPayRange_v.value.toString(),
				readOnly: true
			}
		}
		if (this.template_data.achAbsorb_visible_highPayRange_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achAbsorb_highPayRange = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.achAbsorb_visible_highPayRange_v.value.toString(),
				readOnly: true
			}
		}
		if (this.template_data.absorb_web_percentxAuth) {
			ntemplate.templateContent.servicesData.ach.achAbsorb.tiers[0].web.percentxAuth = parseFloat(this.template_data.absorb_web_percentxAuth.value)
		}
		if (this.template_data.absorb_web_amountxAuth) {
			ntemplate.templateContent.servicesData.ach.achAbsorb.tiers[0].web.amountxAuth = parseFloat(this.template_data.absorb_web_amountxAuth.value)
		}
		if (this.template_data.absorb_web_lowPayRange) {
			ntemplate.templateContent.servicesData.ach.achAbsorb.tiers[0].web.lowPayRange = parseFloat(this.template_data.absorb_web_lowPayRange.value)
		}
		if (this.template_data.absorb_web_highPayRange) {
			ntemplate.templateContent.servicesData.ach.achAbsorb.tiers[0].web.highPayRange = parseFloat(this.template_data.absorb_web_highPayRange.value)
		}
		if (this.template_data.absorb_ppd_percentxAuth) {
			ntemplate.templateContent.servicesData.ach.achAbsorb.tiers[0].ppd.percentxAuth = parseFloat(this.template_data.absorb_ppd_percentxAuth.value)
		}
		if (this.template_data.absorb_ppd_amountxAuth) {
			ntemplate.templateContent.servicesData.ach.achAbsorb.tiers[0].ppd.amountxAuth = parseFloat(this.template_data.absorb_ppd_amountxAuth.value)
		}
		if (this.template_data.absorb_ppd_lowPayRange) {
			ntemplate.templateContent.servicesData.ach.achAbsorb.tiers[0].ppd.lowPayRange = parseFloat(this.template_data.absorb_ppd_lowPayRange.value)
		}
		if (this.template_data.absorb_ppd_highPayRange) {
			ntemplate.templateContent.servicesData.ach.achAbsorb.tiers[0].ppd.highPayRange = parseFloat(this.template_data.absorb_ppd_highPayRange.value)
		}
		if (this.template_data.absorb_ccd_percentxAuth) {
			ntemplate.templateContent.servicesData.ach.achAbsorb.tiers[0].ccd.percentxAuth = parseFloat(this.template_data.absorb_ccd_percentxAuth.value)
		}
		if (this.template_data.absorb_ccd_amountxAuth) {
			ntemplate.templateContent.servicesData.ach.achAbsorb.tiers[0].ccd.amountxAuth = parseFloat(this.template_data.absorb_ccd_amountxAuth.value)
		}
		if (this.template_data.absorb_ccd_lowPayRange) {
			ntemplate.templateContent.servicesData.ach.achAbsorb.tiers[0].ccd.lowPayRange = parseFloat(this.template_data.absorb_ccd_lowPayRange.value)
		}
		if (this.template_data.absorb_ccd_highPayRange) {
			ntemplate.templateContent.servicesData.ach.achAbsorb.tiers[0].ccd.highPayRange = parseFloat(this.template_data.absorb_ccd_highPayRange.value)
		}
		//PT
		if (this.template_data.achPassThrough_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.visible = this.template_data.achPassThrough_v.value
		}
		if (this.template_data.achPass_visible_lowPayRange_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achPass_lowPayRange = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.achPass_visible_lowPayRange_v.value.toString(),
				readOnly: true
			}
		}
		if (this.template_data.achPass_visible_highPayRange_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achPass_highPayRange = {
				visible: true,
				posRow: 0,
				posCol: 0,
				value: this.template_data.achPass_visible_highPayRange_v.value.toString(),
				readOnly: true
			}
		}
		if (this.template_data.pt_web_lowPayRange) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].web.lowPayRange = parseFloat(this.template_data.pt_web_lowPayRange.value)
		}
		if (this.template_data.pt_web_highPayRange) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].web.highPayRange = parseFloat(this.template_data.pt_web_highPayRange.value)
		}
		if (this.template_data.pt_web_amountFeeRecurring) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].web.amountFeeRecurring = parseFloat(this.template_data.pt_web_amountFeeRecurring.value)
		}
		if (this.template_data.pt_web_percentFeeRecurring) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].web.percentFeeRecurring = parseFloat(this.template_data.pt_web_percentFeeRecurring.value)
		}
		if (this.template_data.pt_web_amountFeeOneTime) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].web.amountFeeOneTime = parseFloat(this.template_data.pt_web_amountFeeOneTime.value)
		}
		if (this.template_data.pt_web_percentFeeOneTime) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].web.percentFeeOneTime = parseFloat(this.template_data.pt_web_percentFeeOneTime.value)
		}
		if (this.template_data.pt_ppd_lowPayRange) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].ppd.lowPayRange = parseFloat(this.template_data.pt_ppd_lowPayRange.value)
		}
		if (this.template_data.pt_ppd_highPayRange) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].ppd.highPayRange = parseFloat(this.template_data.pt_ppd_highPayRange.value)
		}
		if (this.template_data.pt_ppd_amountFeeRecurring) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].ppd.amountFeeRecurring = parseFloat(this.template_data.pt_ppd_amountFeeRecurring.value)
		}
		if (this.template_data.pt_ppd_percentFeeRecurring) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].ppd.percentFeeRecurring = parseFloat(this.template_data.pt_ppd_percentFeeRecurring.value)
		}
		if (this.template_data.pt_ppd_amountFeeOneTime) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].ppd.amountFeeOneTime = parseFloat(this.template_data.pt_ppd_amountFeeOneTime.value)
		}
		if (this.template_data.pt_ppd_percentFeeOneTime) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].ppd.percentFeeOneTime = parseFloat(this.template_data.pt_ppd_percentFeeOneTime.value)
		}
		if (this.template_data.pt_ccd_lowPayRange) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].ccd.lowPayRange = parseFloat(this.template_data.pt_ccd_lowPayRange.value)
		}
		if (this.template_data.pt_ccd_highPayRange) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].ccd.highPayRange = parseFloat(this.template_data.pt_ccd_highPayRange.value)
		}
		if (this.template_data.pt_ccd_amountFeeRecurring) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].ccd.amountFeeRecurring = parseFloat(this.template_data.pt_ccd_amountFeeRecurring.value)
		}
		if (this.template_data.pt_ccd_percentFeeRecurring) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].ccd.percentFeeRecurring = parseFloat(this.template_data.pt_ccd_percentFeeRecurring.value)
		}
		if (this.template_data.pt_ccd_amountFeeOneTime) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].ccd.amountFeeOneTime = parseFloat(this.template_data.pt_ccd_amountFeeOneTime.value)
		}
		if (this.template_data.pt_ccd_percentFeeOneTime) {
			ntemplate.templateContent.servicesData.ach.achPassThrough.tiers[0].ccd.percentFeeOneTime = parseFloat(this.template_data.pt_ccd_percentFeeOneTime.value)
		}
		//Fees
		if (this.template_data.achFees_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achFees.visible = this.template_data.achFees_v.value
		}
		if (this.template_data.earlyTerminationAchFee) {
			ntemplate.templateContent.servicesData.ach.achFees.earlyTerminationAchFee.value = this.template_data.earlyTerminationAchFee.value
		}
		if (this.template_data.earlyTerminationAchFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achFees.earlyTerminationAchFee.visible = this.template_data.earlyTerminationAchFee_v.value
		}
		if (this.template_data.quarterlyPCIAchFee) {
			ntemplate.templateContent.servicesData.ach.achFees.quarterlyPCIAchFee.value = this.template_data.quarterlyPCIAchFee.value
		}
		if (this.template_data.quarterlyPCIAchFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achFees.quarterlyPCIAchFee.visible = this.template_data.quarterlyPCIAchFee_v.value
		}
		if (this.template_data.advancedSettlementAchFee) {
			ntemplate.templateContent.servicesData.ach.achFees.advancedSettlementAchFee.value = this.template_data.advancedSettlementAchFee.value
		}
		if (this.template_data.advancedSettlementAchFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achFees.advancedSettlementAchFee.visible = this.template_data.advancedSettlementAchFee_v.value
		}
		if (this.template_data.sameDayAchFee) {
			ntemplate.templateContent.servicesData.ach.achFees.sameDayAchFee.value = this.template_data.sameDayAchFee.value
		}
		if (this.template_data.sameDayAchFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achFees.sameDayAchFee.visible = this.template_data.sameDayAchFee_v.value
		}
		if (this.template_data.sundayOriginationAchFee) {
			ntemplate.templateContent.servicesData.ach.achFees.sundayOriginationAchFee.value = this.template_data.sundayOriginationAchFee.value
		}
		if (this.template_data.sundayOriginationAchFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achFees.sundayOriginationAchFee.visible = this.template_data.sundayOriginationAchFee_v.value
		}
		if (this.template_data.verifyFundAchFee) {
			ntemplate.templateContent.servicesData.ach.achFees.verifyFundAchFee.value = this.template_data.verifyFundAchFee.value
		}
		if (this.template_data.verifyFundAchFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achFees.verifyFundAchFee.visible = this.template_data.verifyFundAchFee_v.value
		}
		if (this.template_data.verifyNegativeAchFee) {
			ntemplate.templateContent.servicesData.ach.achFees.verifyNegativeAchFee.value = this.template_data.verifyNegativeAchFee.value
		}
		if (this.template_data.verifyNegativeAchFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achFees.verifyNegativeAchFee.visible = this.template_data.verifyNegativeAchFee_v.value
		}
		if (this.template_data.verifyBankAchFee) {
			ntemplate.templateContent.servicesData.ach.achFees.verifyBankAchFee.value = this.template_data.verifyBankAchFee.value
		}
		if (this.template_data.verifyBankAchFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achFees.verifyBankAchFee.visible = this.template_data.verifyBankAchFee_v.value
		}
		if (this.template_data.annualAchFee) {
			ntemplate.templateContent.servicesData.ach.achFees.annualAchFee.value = this.template_data.annualAchFee.value
		}
		if (this.template_data.annualAchFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achFees.annualAchFee.visible = this.template_data.annualAchFee_v.value
		}
		if (this.template_data.monthlyAchFee) {
			ntemplate.templateContent.servicesData.ach.achFees.monthlyAchFee.value = this.template_data.monthlyAchFee.value
		}
		if (this.template_data.monthlyAchFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achFees.monthlyAchFee.visible = this.template_data.monthlyAchFee_v.value
		}
		if (this.template_data.chargebackAchFee) {
			ntemplate.templateContent.servicesData.ach.achFees.chargebackAchFee.value = this.template_data.chargebackAchFee.value
		}
		if (this.template_data.chargebackAchFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achFees.chargebackAchFee.visible = this.template_data.chargebackAchFee_v.value
		}
		if (this.template_data.returnedAchFee) {
			ntemplate.templateContent.servicesData.ach.achFees.returnedAchFee.value = this.template_data.returnedAchFee.value
		}
		if (this.template_data.returnedAchFee_v?.value !== undefined) {
			ntemplate.templateContent.servicesData.ach.achFees.returnedAchFee.visible = this.template_data.returnedAchFee_v.value
		}
		//documents
		if (this.template_data.uploadDocuments_v?.value !== undefined) {
			ntemplate.templateContent.documentsData.uploadDocuments = this.template_data.uploadDocuments_v.value
		}
		if (this.template_data.minimumDocuments) {
			ntemplate.templateContent.documentsData.minimumDocuments = this.template_data.minimumDocuments.value
		}
		if (this.template_data.depositBank_v?.value !== undefined) {
			ntemplate.templateContent.documentsData.depositBank.visible = this.template_data.depositBank_v.value
		}
		if (this.template_data.deposit_bankName) ntemplate.templateContent.documentsData.depositBank.bankName.value = this.template_data.deposit_bankName.value
		if (this.template_data.deposit_bankName_v?.value !== undefined) ntemplate.templateContent.documentsData.depositBank.bankName.visible = this.template_data.deposit_bankName_v.value
		if (this.template_data.deposit_bankName_ro?.value !== undefined) ntemplate.templateContent.documentsData.depositBank.bankName.readOnly = this.template_data.deposit_bankName_ro.value
		if (this.template_data.deposit_bankName_rq?.value !== undefined) ntemplate.templateContent.documentsData.depositBank.bankName.required = this.template_data.deposit_bankName_rq.value

		if (this.template_data.deposit_routingNumber) ntemplate.templateContent.documentsData.depositBank.routingNumber.value = this.template_data.deposit_routingNumber.value
		if (this.template_data.deposit_routingNumber_v?.value !== undefined) ntemplate.templateContent.documentsData.depositBank.routingNumber.visible = this.template_data.deposit_routingNumber_v.value
		if (this.template_data.deposit_routingNumber_ro?.value !== undefined) ntemplate.templateContent.documentsData.depositBank.routingNumber.readOnly = this.template_data.deposit_routingNumber_ro.value
		if (this.template_data.deposit_routingNumber_rq?.value !== undefined) ntemplate.templateContent.documentsData.depositBank.routingNumber.required = this.template_data.deposit_routingNumber_rq.value

		if (this.template_data.deposit_accountNumber) ntemplate.templateContent.documentsData.depositBank.accountNumber.value = this.template_data.deposit_accountNumber.value
		if (this.template_data.deposit_accountNumber_v?.value !== undefined) ntemplate.templateContent.documentsData.depositBank.accountNumber.visible = this.template_data.deposit_accountNumber_v.value
		if (this.template_data.deposit_accountNumber_ro?.value !== undefined) ntemplate.templateContent.documentsData.depositBank.accountNumber.readOnly = this.template_data.deposit_accountNumber_ro.value
		if (this.template_data.deposit_accountNumber_rq?.value !== undefined) ntemplate.templateContent.documentsData.depositBank.accountNumber.required = this.template_data.deposit_accountNumber_rq.value

		if (this.template_data.deposit_accountType) ntemplate.templateContent.documentsData.depositBank.accountType.value = this.template_data.deposit_accountType.value
		if (this.template_data.deposit_accountType_v?.value !== undefined) ntemplate.templateContent.documentsData.depositBank.accountType.visible = this.template_data.deposit_accountType_v.value
		if (this.template_data.deposit_accountType_ro?.value !== undefined) ntemplate.templateContent.documentsData.depositBank.accountType.readOnly = this.template_data.deposit_accountType_ro.value
		if (this.template_data.deposit_accountType_rq?.value !== undefined) ntemplate.templateContent.documentsData.depositBank.accountType.required = this.template_data.deposit_accountType_rq.value

		if (this.template_data.withdrawalBank_v?.value !== undefined) {
			ntemplate.templateContent.documentsData.withdrawalBank.visible = this.template_data.withdrawalBank_v.value
		}
		if (this.template_data.withdrawal_bankName) ntemplate.templateContent.documentsData.withdrawalBank.bankName.value = this.template_data.withdrawal_bankName.value
		if (this.template_data.withdrawal_bankName_v?.value !== undefined) ntemplate.templateContent.documentsData.withdrawalBank.bankName.visible = this.template_data.withdrawal_bankName_v.value
		if (this.template_data.withdrawal_bankName_ro?.value !== undefined) ntemplate.templateContent.documentsData.withdrawalBank.bankName.readOnly = this.template_data.withdrawal_bankName_ro.value
		if (this.template_data.withdrawal_bankName_rq?.value !== undefined) ntemplate.templateContent.documentsData.withdrawalBank.bankName.required = this.template_data.withdrawal_bankName_rq.value

		if (this.template_data.withdrawal_routingNumber) ntemplate.templateContent.documentsData.withdrawalBank.routingNumber.value = this.template_data.withdrawal_routingNumber.value
		if (this.template_data.withdrawal_routingNumber_v?.value !== undefined) ntemplate.templateContent.documentsData.withdrawalBank.routingNumber.visible = this.template_data.withdrawal_routingNumber_v.value
		if (this.template_data.withdrawal_routingNumber_ro?.value !== undefined) ntemplate.templateContent.documentsData.withdrawalBank.routingNumber.readOnly = this.template_data.withdrawal_routingNumber_ro.value
		if (this.template_data.withdrawal_routingNumber_rq?.value !== undefined) ntemplate.templateContent.documentsData.withdrawalBank.routingNumber.required = this.template_data.withdrawal_routingNumber_rq.value

		if (this.template_data.withdrawal_accountNumber) ntemplate.templateContent.documentsData.withdrawalBank.accountNumber.value = this.template_data.withdrawal_accountNumber.value
		if (this.template_data.withdrawal_accountNumber_v?.value !== undefined) ntemplate.templateContent.documentsData.withdrawalBank.accountNumber.visible = this.template_data.withdrawal_accountNumber_v.value
		if (this.template_data.withdrawal_accountNumber_ro?.value !== undefined) ntemplate.templateContent.documentsData.withdrawalBank.accountNumber.readOnly = this.template_data.withdrawal_accountNumber_ro.value
		if (this.template_data.withdrawal_accountNumber_rq?.value !== undefined) ntemplate.templateContent.documentsData.withdrawalBank.accountNumber.required = this.template_data.withdrawal_accountNumber_rq.value

		if (this.template_data.withdrawal_accountType) ntemplate.templateContent.documentsData.withdrawalBank.accountType.value = this.template_data.withdrawal_accountType.value
		if (this.template_data.withdrawal_accountType_v?.value !== undefined) ntemplate.templateContent.documentsData.withdrawalBank.accountType.visible = this.template_data.withdrawal_accountType_v.value
		if (this.template_data.withdrawal_accountType_ro?.value !== undefined) ntemplate.templateContent.documentsData.withdrawalBank.accountType.readOnly = this.template_data.withdrawal_accountType_ro.value
		if (this.template_data.withdrawal_accountType_rq?.value !== undefined) ntemplate.templateContent.documentsData.withdrawalBank.accountType.required = this.template_data.withdrawal_accountType_rq.value
	}
}

const boardingStore = new BoardingStore()
export default boardingStore
