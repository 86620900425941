import { observable,makeObservable , action } from "mobx";
import axios from 'axios';
import { PayabliStorageManager } from '../api/localStorageManager'

class DevelopersStore {

    constructor() {
        makeObservable(this)
    }

    @observable
    statistics = null;   

    @observable
    records = []

    @observable
    count = 20

    @observable
    totalRecords = 0

    @observable
    from = 0
    
    @action
    setRecords(records){
        this.records = records;
    }

    @action
    setTotalPages(total){
        this.totalPages = total;
    }

    @action
    setTotalRecords(total){
        this.totalRecords = total;
    }

    @action
    setFrom(from){
        this.from = from * this.count;
    }

    @action
    getAPIKeysFromApi(entry, level, signing){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.get(process.env.REACT_APP_URL_API+ 'Management/apiToken/' + level + "/" + entry,{
        headers: {
            'requestToken': encryptStorage.getItem('pToken'),
            'signing': signing
        }})
        .then(res => {
            this.setRecords(res.data.Records);
            this.setTotalPages(res.data.Summary.totalPages);
            this.setTotalRecords(res.data.Summary.totalRecords);
        })
        .catch(error => {
            throw error;
        });
    }

    getSigning(){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.get(process.env.REACT_APP_URL_API + 'Management/auth', {
            headers: {
                'requestToken': encryptStorage.getItem('pToken')
            }
        }).then(dt => {
            return dt;
        }).catch(error => {
            throw error;
        });
    }

    deleteAPIKey(token, signing){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        
        return axios.delete(process.env.REACT_APP_URL_API + 'Management/apiToken',
        {
            headers: { 
                'requestToken': encryptStorage.getItem('pToken'),
                'signing': signing,
                'content-type': 'application/json; charset=utf-8'
            },
            data: '"'+token+'"'
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            throw error;
        });
    }

    createApiKey(token,signing){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.post(process.env.REACT_APP_URL_API + "Management/apiToken", token,
        {
            headers: { 
                'requestToken': encryptStorage.getItem('pToken'),
                'signing': signing
            }
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            throw error;
        });
    }
}

const developersStore = new DevelopersStore();
export default developersStore;