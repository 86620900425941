import { makeObservable, observable, action } from "mobx";
import { PayabliStorageManager } from '../api/localStorageManager'
import axios from 'axios';

class ManagementStore {

    constructor() {
        makeObservable(this);
    }

    orgId = 0;

    @observable
    template_data = {};

    @observable
    error_data = {};

    componentDidMount() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        this.orgId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId;
    }

    @action
    apiRequest = async (endpoint, data) => {
        try {
            const encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
            const response = await axios.post(process.env.REACT_APP_URL_API + endpoint, data, {
                headers: {
                    'requestToken': encryptStorage.getItem('pToken'),
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    @action
    errors() {
        if (Object.keys(this.error_data).length === 0) return false;
        return true;
    }

    @action
    existsTemplateData(name) {
        if (this.template_data[name]) return true;
        return false;
    }

    @action
    setTemplateData(name, value) {
        this.template_data[name] = { value: value };
    }

    @action
    copyTemplateData(value) {
        this.template_data = value;
    }

    @action
    clearTemplateData() {
        this.template_data = {};
    }

    @action
    setErrorData(name, value) {
        if (value === false) {
            if (this.error_data[name])
                delete this.error_data[name];
        }
        else {
            this.error_data[name] = { value: value };
        }
    }

    @action
    clearErrorData() {
        this.error_data = {};
    }


    @action
    savePaypoint(appbase, orgId, o, c, appid, signing, entryname, settingsGeneral) {
        return new Promise((resolve, reject) => {
            var app = appbase;
            let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
            app.orgId = orgId;
            if (orgId === 0 || orgId === null) {
                app.orgId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId;
            }
            if (this.template_data.paypointStatus) app.paypointStatus = this.template_data.paypointStatus.value;
            if (this.template_data.dbaName) app.dbaName = this.template_data.dbaName.value;
            if (this.template_data.legalName) app.legalName = this.template_data.legalName.value;
            if (this.template_data.websiteAddress) app.websiteAddress = this.template_data.websiteAddress.value;
            if (this.template_data.ein) app.ein = this.template_data.ein.value;
            if (this.template_data.license) app.license = this.template_data.license.value;
            if (this.template_data.licenseState) app.licenseState = this.template_data.licenseState.value;
            if (this.template_data.taxfillname) app.Taxfillname = this.template_data.taxfillname.value;
            if (this.template_data.startdate) app.BStartdate = this.template_data.startdate.value;
            if (this.template_data.phone) app.phone = this.template_data.phone.value;
            if (this.template_data.fax) app.fax = this.template_data.fax.value;
            if (this.template_data.ownType) app.ownType = this.template_data.ownType.value;
            if (this.template_data.address1) app.address1 = this.template_data.address1.value;
            if (this.template_data.address2) app.address2 = this.template_data.address2.value;
            if (this.template_data.city) app.city = this.template_data.city.value;
            if (this.template_data.state) app.state = this.template_data.state.value;
            if (this.template_data.zip) app.zip = this.template_data.zip.value;
            if (this.template_data.country) app.country = this.template_data.country.value;
            if (this.template_data.mailAddress1) app.mailAddress1 = this.template_data.mailAddress1.value;
            if (this.template_data.mailAddress2) app.mailAddress2 = this.template_data.mailAddress2.value;
            if (this.template_data.mailCity) app.mailCity = this.template_data.mailCity.value;
            if (this.template_data.mailState) app.mailState = this.template_data.mailState.value;
            if (this.template_data.mailZip) app.mailZip = this.template_data.mailZip.value;
            if (this.template_data.mailCountry) app.mailCountry = this.template_data.mailCountry.value;
            if (this.template_data.mcc) app.mcc = this.template_data.mcc.value;
            if (this.template_data.summary) app.summary = this.template_data.summary.value;
            if (this.template_data.whencharged) app.whencharged = this.template_data.whencharged.value;
            if (this.template_data.whenprovided) app.whenprovided = this.template_data.whenprovided.value;
            if (this.template_data.whendelivered) app.whendelivered = this.template_data.whendelivered.value;
            if (this.template_data.whenrefund) app.whenrefund = this.template_data.whenrefund.value;
            if (this.template_data.binperson) app.binPerson = parseInt(this.template_data.binperson.value, 10);
            if (this.template_data.binphone) app.binPhone = parseInt(this.template_data.binphone.value, 10);
            if (this.template_data.binweb) app.binWeb = parseInt(this.template_data.binweb.value, 10);
            if (this.template_data.avgmonthly) app.averageMonthlyVolume = parseFloat(this.template_data.avgmonthly.value);
            if (this.template_data.ticketamt) app.averageTicketAmount = parseFloat(this.template_data.ticketamt.value);
            if (this.template_data.highticketamt) app.highTicketAmount = parseFloat(this.template_data.highticketamt.value);
            app.timeZone = this.template_data.timezone.value ? this.template_data.timezone.value : "0";

            var ow = o;
            ow.push(0);
            if (o.length > 0) {
                app.ownership = [];
                for (var j = 0; j < o.length; j++) {
                    let v = o[j];
                    let owner = {};
                    if (this.template_data["ownername_" + v]) owner.ownername = this.template_data["ownername_" + v].value;
                    if (this.template_data["ownertitle_" + v]) owner.ownertitle = this.template_data["ownertitle_" + v].value;
                    if (this.template_data["ownerpercent_" + v]) owner.ownerpercent = parseInt(this.template_data["ownerpercent_" + v].value, 10);
                    if (this.template_data["ownerssn_" + v]) owner.ownerssn = this.template_data["ownerssn_" + v].value;
                    if (this.template_data["ownerdob_" + v]) owner.ownerdob = this.template_data["ownerdob_" + v].value;
                    if (this.template_data["ownerphone1_" + v]) owner.ownerphone1 = this.template_data["ownerphone1_" + v].value;
                    if (this.template_data["ownerphone2_" + v]) owner.ownerphone2 = this.template_data["ownerphone2_" + v].value;
                    if (this.template_data["owneremail_" + v]) owner.owneremail = this.template_data["owneremail_" + v].value;
                    if (this.template_data["ownerdriver_" + v]) owner.ownerdriver = this.template_data["ownerdriver_" + v].value;
                    if (this.template_data["odriverstate_" + v]) owner.odriverstate = this.template_data["odriverstate_" + v].value;
                    if (this.template_data["oaddress_" + v]) owner.oaddress = this.template_data["oaddress_" + v].value;
                    if (this.template_data["ocity_" + v]) owner.ocity = this.template_data["ocity_" + v].value;
                    if (this.template_data["ostate_" + v]) owner.ostate = this.template_data["ostate_" + v].value;
                    if (this.template_data["ozip_" + v]) owner.ozip = this.template_data["ozip_" + v].value;
                    if (this.template_data["ocountry_" + v]) owner.ocountry = this.template_data["ocountry_" + v].value;
                    app.ownership.push(owner);
                }
            }
            var cw = c;
            cw.push(0);
            if (c.length > 0) {
                app.contacts = [];
                for (var jj = 0; jj < c.length; jj++) {
                    let vv = c[jj];
                    let ct = {};
                    if (this.template_data["contactName_" + vv]) ct.contactName = this.template_data["contactName_" + vv].value;
                    if (this.template_data["contactTitle_" + vv]) ct.contactTitle = this.template_data["contactTitle_" + vv].value;
                    if (this.template_data["contactEmail_" + vv]) ct.contactEmail = this.template_data["contactEmail_" + vv].value;
                    if (this.template_data["contactPhone_" + vv]) ct.contactPhone = this.template_data["contactPhone_" + vv].value;
                    app.contacts.push(ct);
                }
            }
            app.bankData = [];
            if (this.template_data.deposit_bankName || this.template_data.deposit_accountNumber || this.template_data.deposit_routingAccount || this.template_data.deposit_typeAccount) {
                var depositAccount = {};
                if (this.template_data.deposit_bankName) depositAccount.bankName = this.template_data.deposit_bankName.value;
                if (this.template_data.deposit_accountNumber) depositAccount.accountNumber = this.template_data.deposit_accountNumber.value;
                if (this.template_data.deposit_routingAccount) depositAccount.routingAccount = this.template_data.deposit_routingAccount.value;
                if (this.template_data.deposit_typeAccount) depositAccount.typeAccount = this.template_data.deposit_typeAccount.value;
                app.bankData.push(depositAccount);
            }
            if (this.template_data.withdrawal_bankName || this.template_data.withdrawal_accountNumber || this.template_data.withdrawal_routingAccount || this.template_data.withdrawal_typeAccount) {
                var withdrawalAccount = {};
                if (this.template_data.withdrawal_bankName) withdrawalAccount.bankName = this.template_data.withdrawal_bankName.value;
                if (this.template_data.withdrawal_accountNumber) withdrawalAccount.accountNumber = this.template_data.withdrawal_accountNumber.value;
                if (this.template_data.withdrawal_routingAccount) withdrawalAccount.routingAccount = this.template_data.withdrawal_routingAccount.value;
                if (this.template_data.withdrawal_typeAccount) withdrawalAccount.typeAccount = this.template_data.withdrawal_typeAccount.value;
                app.bankData.push(withdrawalAccount);
            }

            if (appid > 0) {
                axios.all([
                    axios.put(process.env.REACT_APP_URL_API + 'Management/paypoint/' + appid, app, {
                        headers: {
                            'requestToken': encryptStorage.getItem('pToken'),
                            'signing': signing
                        }
                    }),
                    (entryname !== '') && (axios.put(process.env.REACT_APP_URL_API + 'Paypoint/settings/' + entryname, { "general": settingsGeneral },
                        {
                            headers: { 'requestToken': encryptStorage.getItem('pToken') }
                        }))
                ])
                    .then(axios.spread((resManag, resSett) => {
                        resolve(resManag);
                    }))
                    .catch(error => {
                        reject(error);
                    });
            } else {
                axios.all([
                    axios.post(process.env.REACT_APP_URL_API + 'Management/paypoint', app,
                        {
                            headers: {
                                'requestToken': encryptStorage.getItem('pToken'),
                                'signing': signing
                            }
                        }),
                    (entryname !== '') && (axios.put(process.env.REACT_APP_URL_API + 'Paypoint/settings/' + entryname, { "general": settingsGeneral },
                        {
                            headers: { 'requestToken': encryptStorage.getItem('pToken') }
                        }))
                ])
                    .then(axios.spread((resManag, resSett) => {
                        resolve(resManag);
                    }))
                    .catch(error => {
                        reject(error);
                    });
            }
        });
    }

    @action
    async getSigning() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        try {
            const dt = await axios.get(process.env.REACT_APP_URL_API + 'Management/auth', {
                headers: {
                    'requestToken': encryptStorage.getItem('pToken')
                }
            });
            return dt?.data?.responseData;
        } catch (error) {
            throw error;
        }
    }

    @action
    async getAvailableBalanceMerchant(entry, signing) {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        try {
            const dt = await axios.get(process.env.REACT_APP_URL_API + `Management/getAvailableBalanceMerchant?entry=${entry}&sync=true`, {
                headers: {
                    'requestToken': encryptStorage.getItem('pToken'),
                    'signing': signing
                }
            });
            return dt?.data?.balanceData;
        } catch (error) {
            throw error;
        }
    }

    @action
    async actionFraudControl(type, fraudHistoryId) {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage(),
            id = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId,
            signing = await this.getSigning(),
            data = {
                "type": type,
                "fraudHistoryId": fraudHistoryId
            };
        return axios.post(process.env.REACT_APP_URL_API + 'Management/fraudblacklist/0/' + id, data, {
            headers: {
                'requestToken': encryptStorage.getItem('pToken'),
                'signing': signing
            }
        })
            .then(res => {
                return res;
            })
            .catch(error => {
                throw error;
            });
    }

    @action
    async actionRemoveFraudControl(fraudHistoryId) {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage(),
            id = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId,
            signing = await this.getSigning();
        return axios.delete(process.env.REACT_APP_URL_API + 'Management/fraudblacklist/0/' + id + '/' + fraudHistoryId, {
            headers: {
                'requestToken': encryptStorage.getItem('pToken'),
                'signing': signing
            }
        })
            .then(res => {
                return res;
            })
            .catch(error => {
                throw error;
            });
    }

    async actionBlockPaypointFraudControl(idPaypoint) {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage(),
            signing = await this.getSigning();
        return axios.put(process.env.REACT_APP_URL_API + 'Management/paypointStatus/' + idPaypoint, { "paypointStatus": 0 }, {
            headers: {
                'requestToken': encryptStorage.getItem('pToken'),
                'signing': signing
            }
        })
            .then(res => {
                return res;
            })
            .catch(error => {
                throw error;
            });
    }

    actionHoldTransaction = async (payabliTransId) => {
        const data = {
            "payabliTransId": payabliTransId
        };
        return this.apiRequest('Management/holdTransaction', data);
    };

    actionReleaseTransaction = async (payabliTransId) => {
        const data = {
            "payabliTransId": payabliTransId
        };
        return this.apiRequest('Management/releaseTransaction', data);
    };

    actionHoldBatch = async (idBatch) => {
        const data = {
            "idBatch": idBatch
        };
        return this.apiRequest('Management/holdBatch', data);
    };

    actionReleaseBatch = async (idBatch) => {
        const data = {
            "idBatch": idBatch
        };
        return this.apiRequest('Management/releaseBatch', data);
    };

}

const managementStore = new ManagementStore();
export default managementStore;
