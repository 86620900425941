import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { managementPaypointGET, payPointStatusPUT, payPointPOST, payPointPUT, payPointSettingsGET, payPointSettingsPUT } from '../Connections/PaypointConnections'
import { action } from 'mobx'

export default function PaypointFunction({ payPoint, business, owner, processing, setPaypointSetting, setLoading, setPaypointCurrency, paypointSetting, paypointCurrency, paramsURL, signingKey, SetParamsURL, loadData, setPayPoint }) {
	const history = useHistory()

	const loadPaypoint = action(async (id, signing) => {
		try {
			if (id <= 0) return
			const res = await managementPaypointGET(id, signing)
			return res.data
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			setLoading(false)
			return
		}
	})
	const loadPaypointSettings = action(async (entry) => {
		if (!entry && payPoint.idPaypoint <= 0) return
		const settings = await payPointSettingsGET(entry ? entry : payPoint.entryPoints)
		setPaypointSetting(settings.data)

		let general = {}
		settings.data.general.forEach((data) => {
			if (data.key === 'currency') {
				general = {
					readOnly: data.readOnly,
					key: 'currency',
					value: data.value
				}
			}
		})
		if (typeof general !== 'undefined' && general !== null && general !== '') setPaypointCurrency(general)
	})
	const updatePaypointSettings = action(async () => {
		try {
			let findCurrency = false
			const general = paypointSetting.general.map((data) => {
				if (data.key === 'currency') {
					findCurrency = true
					return {
						readOnly: data.readOnly,
						key: data.key,
						value: paypointCurrency.value
					}
				} else {
					return data
				}
			})

			if (!findCurrency) {
				general.push(paypointCurrency)
			}
			setPaypointSetting({ ...paypointSetting, general: general })
			const settingData = { ...paypointSetting, general: general }

			const res = await payPointSettingsPUT(payPoint.entryPoints[0].entryName, settingData)
			if (res.data.isSuccess === true) {
				return true
			}
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return false
		}
	})
	const updateStatus = action(async (state) => {
		try {
			const res = await payPointStatusPUT(paramsURL.id, signingKey, state)
			return res.data
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return
		}
	})
	const updatePayPoint = action(async (form) => {
		try {
			if (form === 'Processing') {
				const response = await updatePaypointSettings()
				if (!response) return
			}

			const payPointData = selectStrutures(form, paramsURL.oid)
			let res = ''

			if (paramsURL.id > 0) {
				res = await payPointPUT(paramsURL.id, signingKey, payPointData)
			} else {
				res = await payPointPOST(signingKey, payPointData)
				if (paramsURL.id <= 0 && res.data.responseData) {
					history.push('/payabli/management/paypoints/' + paramsURL.oid + '/' + res.data.responseData)
					SetParamsURL({ ...paramsURL, id: res.data.responseData })
					await loadData(res.data.responseData)
				}
			}
			if (res.data.isSuccess === true) {
				toast.success('Status changed successfully!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
				return true
			}
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : error.response && error.response.request && error.response.request.responseText ? error.response.request?.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return false
		}
	})
	const selectStrutures = (form, orgId) => {
		switch (form) {
			case 'Business':
				return {
					orgId: orgId,
					paypointStatus: payPoint.paypointStatus,
					dbaName: business.dbaName,
					legalName: business.legalName,
					address1: business.address1,
					address2: business.address2,
					city: business.city,
					zip: business.zip,
					state: business.state,
					country: business.country,
					websiteAddress: business.websiteAddress,
					phone: business.phone,
					fax: business.fax,
					ein: business.ein,
					taxfillname: business.taxfillname,
					licenseState: business.licenseState,
					license: business.license,
					mailAddress1: business.mailAddress1,
					mailAddress2: business.mailAddress2,
					mailCity: business.mailCity,
					mailZip: business.mailZip,
					mailState: business.mailState,
					mailCountry: business.mailCountry,
					startdate: business.startdate,
					ownType: business.ownType,
					timeZone: business.bTimezone
				}

			case 'Ownership':
				return {
					orgId: orgId,
					paypointStatus: payPoint.paypointStatus,
					contacts: owner.contacts,
					ownership: owner.ownership
				}

			case 'Processing':
				return {
					orgId: orgId,
					paypointStatus: payPoint.paypointStatus,
					summary: processing.summary,
					whencharged: processing.whencharged,
					whenprovided: processing.whenprovided,
					whendelivered: processing.whendelivered,
					whenrefund: processing.whenrefund,
					averageMonthlyVolume: processing.averageMonthlyVolume,
					highTicketAmount: processing.highTicketAmount,
					averageTicketAmount: processing.averageTicketAmount,
					mcc: processing.mcc,
					binPerson: processing.binPerson,
					binPhone: processing.binPhone,
					binWeb: processing.binWeb
				}

			case 'New':
				return {
					orgId: orgId,
					paypointStatus: 1,
					dbaName: business.dbaName,
					legalName: business.legalName,
					address1: business.address1,
					address2: business.address2,
					city: business.city,
					zip: business.zip,
					state: business.state,
					country: business.country,
					websiteAddress: business.websiteAddress,
					phone: business.phone,
					fax: business.fax,
					ein: business.ein,
					taxfillname: business.taxfillname,
					licenseState: business.licenseState,
					license: business.license,
					mailAddress1: business.mailAddress1,
					mailAddress2: business.mailAddress2,
					mailCity: business.mailCity,
					mailZip: business.mailZip,
					mailState: business.mailState,
					mailCountry: business.mailCountry,
					startdate: business.startdate,
					ownType: business.ownType,
					timeZone: business.bTimezone,
					salesCode: payPoint.salesCode,
					externalPaypointID: payPoint.externalPaypointID,
					summary: processing.summary,
					whencharged: processing.whencharged,
					whenprovided: processing.whenprovided,
					whendelivered: processing.whendelivered,
					whenrefund: processing.whenrefund,
					averageMonthlyVolume: processing.averageMonthlyVolume,
					highTicketAmount: processing.highTicketAmount,
					averageTicketAmount: processing.averageTicketAmount,
					mcc: processing.mcc,
					binPerson: processing.binPerson,
					binPhone: processing.binPhone,
					binWeb: processing.binWeb,
					contacts: owner.contacts,
					ownership: owner.ownership,
					serviceData: payPoint.serviceData
				}
			default:
				break
		}
	}
	const handleChangePayPoint = action((field, value) => {
		setPayPoint({ ...payPoint, [field]: value })
	})

	return { handleChangePayPoint, loadPaypoint, loadPaypointSettings, updateStatus, updatePayPoint }
}
