import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ReportsLinks } from '../../components/ReportsLinks';
import { ReportPaginator } from '../../components/ReportPaginator';
import { RightPanel } from '../../components/RightPanel';
import { ParentFilterPanel } from '../../components/filters/ParentFilterPanel';
import { Modal, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { VirtualTerminal } from '../../components/VirtualTerminal';
import { toJS } from "mobx";
import { DropdownButton, Dropdown } from 'react-bootstrap';
import {Link} from "react-router-dom";
import { PayabliStorageManager } from '../../api/localStorageManager'
import { Layout } from '../../components/Layout'
import { PaypointLinks } from '../../components/PaypointLinks';
import { CustomerLinks } from '../../components/CustomerLinks';
import { BiSortAlt2, BiDotsVerticalRounded, BiDollarCircle, BiCheckCircle, BiXCircle, BiNotepad, BiX } from 'react-icons/bi'
import { MdOutlineCallSplit } from 'react-icons/md'
import { AiOutlineAlert } from 'react-icons/ai';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import { MainBar } from '../../components/MainBar';
import { QuickViewTransaction } from '../../components/QuickViewTransaction';

@inject('reports', 'global', 'vTerminal', 'entry', 'customer', 'management', 'filter')
@observer
class TransactionsReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            head: "",
            sortDirection: "desc",
            reversalCreditModalIsOpen: false,
            transIdToRefund: null,
            refundAmount: "0.00",
            refundAmountMax: 0,
            captureModalIsOpen: false,
            transIdToCapture: null,
            captureAmount: "0.00",
            captureAmountError: false,
            captureAmountMax: 0,
            transEmailToSendReceipt: null,
            transIdToSendReceipt: null,
            sendReceiptAddress: "",
            sendReceiptError: true,
            captureReceiptModalIsOpen: false,
            vterminalRightPanelOpen: false,
            vTerminalAutopay: 1,
            customerId: null,
            entry: null,
            idPaypoint: null,
            customFields: [],
            currentPaypointName: '',
            currentCustomer: null,
            paymentTransId: null,
            flagItemAllColumn: true,
            actionCapture: false
        };
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.exportFile = this.exportFile.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.filter = this.filter.bind(this);
        this.handleQuickFilter = this.handleQuickFilter.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.clearFilter = this.clearFilter.bind(this);              
        this.closeCaptureModal = this.closeCaptureModal.bind(this);
        this.captureTransactionAction = this.captureTransactionAction.bind(this);
        this.handleCaptureAmount = this.handleCaptureAmount.bind(this);
        this.captureTransaction = this.captureTransaction.bind(this);
        this.closeCaptureReceiptModal = this.closeCaptureReceiptModal.bind(this);        
        this.closeVterminalPanel = this.closeVterminalPanel.bind(this);
        this.openVterminalPanel = this.openVterminalPanel.bind(this);
        this.chargeAgain = this.chargeAgain.bind(this);
        this.getCustomFieldsFromApi = this.getCustomFieldsFromApi.bind(this);
        this.focus = this.focus.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.renderLast4Report = this.renderLast4Report.bind(this);
        this.sortBy = this.sortBy.bind(this);
        this.reversalCreditTransaction = this.reversalCreditTransaction.bind(this);
        this.renderModalRisks = this.renderModalRisks.bind(this);
        this.showModalConfirmationRisks = this.showModalConfirmationRisks.bind(this);
        this.savedActionsRisks = this.savedActionsRisks.bind(this);
        this.renderActionHoldReleasedTrx = this.renderActionHoldReleasedTrx.bind(this);
        this.quickViewTransaction = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.location.pathname !==prevProps.location.pathname){
            window.location.href = "/" + this.props.global.getURLEntry() +"/report/transactions"
        }
    }
    
    componentDidMount() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let idOrg = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId;
        this.setState({ 
            customerId: this.props.match.params.id ? this.props.match.params.id : null, 
            entry: this.props.match.params.entry ? this.props.match.params.entry : null, 
            idPaypoint: this.props.match.params.idPaypoint ? this.props.match.params.idPaypoint : null,
            paymentTransId: this.props.match.params.paymentTransId ? this.props.match.params.paymentTransId : null,
            idtransRefund: this.props.match.params.idtransRefund ? this.props.match.params.idtransRefund : null,
            orgId: idOrg
        });

        if(this.props.match.params.id){
            this.props.customer.getCustomerFromApi(this.props.match.params.id).then(res => {
                this.setState({currentCustomer:res.data});
            })
            .catch(error => {
                toast.error("Something is Wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }else{
            this.setState({currentCustomer:null});
        }

        if(this.props.match.params.entry){
            this.props.entry.getEntryFromApi(this.props.match.params.entry).then(res => {
                this.setState({currentPaypointName:res.responseData.Paypoint.LegalName});
            })
            .catch(error => {
                toast.error("Something is Wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }

        this.props.global.protect(this.props.history);
        this.props.reports.setFrom(0);
        this.props.global.setRightPanelOpen(false);
        this.props.global.setLoading(true);
        this.props.reports.setHeaders(
            {
                paymentId         : { label:'Payment ID', class: '', display: false},
                gatewayTransId    : { label:'Transaction ID', class: '', display: false}, 
                orderId           : { label:'Order ID', class: '', display: false}, 
                batchNumber       : { label:'Batch Number', class: '', display: false},
                ParentOrgName     : { label:'Organization', class: '', display: false},
                PaypointLegalname : { label:'Paypoint', class: '', display: true},
                PaypointDbaname   : { label:'DBA Name', class: '', display: false},
                paypointId        : { label:'Paypoint Id', class: '', display: false },
                ExternalPaypointID    : { label:'External Paypoint ID', class: '', display: false },  
                customer          : { label:'Customer', class: '', display: true}, 
                company           : { label:'Company', class: '', display: true},
                customerNumber    : { label:'Customer #', class: '', display: false}, 
                billingEmail      : { label:'Email', class: '', display: false}, 
                payorPhone        : { label:'Phone #', class: '', display: false},
                billingAddress    : { label:'Billing Address', class: '', display: false},
                shippingAddress   : { label:'Shipping Address', class: '', display: false},
                invoiceNumber     : { label:'Invoice #', class: '', display: true},                
                date              : { label:'Date', class: '', display: true}, 
                time              : { label:'Time (' + this.props.global.getTimeZone('v1') + ')' , class: '', display: true}, 
                brand             : { label:'Pay Method', class: 'text-center', display: true}, 
                last4             : { label:'Last 4', class: '', display: true}, 
                type              : { label:'Type', class: '', display: true},
		        cycle             : { label:'Cycle', class: '', display: false},                
		        status            : { label:'Payment Status', class: '', display: true}, 
                settlementStatus  : { label:'Batch Status', class: '', display: true}, 
                netAmount         : { label:'Amount', class: 'text-right', display: true}, 
                feeAmount         : { label:'Fee', class: 'text-right', display: false}, 
                totalAmount       : { label:'Total Amount', class: 'text-right', display: false}, 
                currency          : { label:'Currency', class: 'text-center', display: false}, 
                authCode          : { label:'Auth. Code', class: '', display: false}, 
                source            : { label:'Source', class: '', display: false},
                notes             : { label:'Notes', class: 'text-center', display: true}
            }
        )
        this.getCustomFieldsFromApi();
        this.props.reports.setRecords([]);
        this.clearFilters();

        if(this.props.match.params.gobackData){
            let urlData = decodeURIComponent(this.props.match.params.gobackData);
            try {
                let gobackData = JSON.parse(window.atob(urlData));
                this.setState({gobackData: gobackData});
              } catch (ex) {}
        }
    }

    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }
    
    closeVterminalPanel(){
        this.setState({ vterminalRightPanelOpen: false});
    }

    focus(e){
        e.target.focus();
        e.target.setSelectionRange(0, 1000000000);
    }

    chargeAgain(customer, categories, autopay, record, capture){
        if(capture && capture === true){
            this.setState({actionCapture: true});
            this.props.vTerminal.setOperation("authorize");
        }
        else{
            this.setState({actionCapture: false});
            this.props.vTerminal.setOperation("sale");
        }
        
        this.props.vTerminal.setPaymentMethod('');
        this.props.vTerminal.setDefaultPaymentMethodActiveKey('2');
        this.props.vTerminal.setPaymentMethodSavedPosition('2');
        this.props.vTerminal.setPaymentMethodSaved(null);
        
        let categoriesJS = toJS(categories);
       
        if (categoriesJS !== null && categoriesJS.length > 0) {
            categoriesJS.forEach(function (item, index) {
                if (typeof item.value === 'undefined' && typeof item.amount !== 'undefined') {
                    item.value = String(item.amount);
                    delete item.amount;
                }
                else {
                    item.value = String(item.value);
                }
                if (typeof item.description === 'undefined') {
                    item.description = item.label;
                }
                if (typeof item.quantity === 'undefined' && typeof item.qty !== 'undefined') {
                    item.quantity = item.qty;
                    delete item.qty;
                }
                if (typeof item.showDescription === 'undefined') {
                    item.showDescription = false;
                }
                if (typeof item.type === 'undefined') {
                    item.type = "quantity";
                }
                if (typeof item.name === 'undefined') {
                    item.name = String(Date.now());
                }
            });
        }
        else {
            categoriesJS = [{name: "amountDefaultPayabliApp",
                type: 'customer',
                label: 'Amount you wish to charge',
                value: String(record.NetAmount),
                description: '',
                quantity: 1,
                showDescription: true}];
        }
        
        
        this.props.global.setLoading(true);
        this.props.customer.getCustomerFromApi(customer.customerId).then(res => {
            if(res.data){
                let customer = res.data;
                this.props.vTerminal.selectCustomerObject(customer);
                let credentialsPermissions = this.props.vTerminal.getCredentialsPermissions;
                
                if(this.props.vTerminal.getCustomerSelected.StoredMethods && this.props.vTerminal.getCustomerSelected.StoredMethods.length === 0){
                    if(this.props.vTerminal.hasCards && ((credentialsPermissions.card.onetime && !this.props.vTerminal.isAutopay) || (credentialsPermissions.card.recurring && this.props.vTerminal.isAutopay))){
                        this.props.vTerminal.setPaymentMethod('card');
                        this.props.vTerminal.setDefaultPaymentMethodActiveKey("0");
                    }else if(this.props.vTerminal.hasECheck && ((credentialsPermissions.ach.onetime && !this.props.vTerminal.isAutopay) || (credentialsPermissions.ach.recurring && this.props.vTerminal.isAutopay))){
                        this.props.vTerminal.setPaymentMethod('ach');
                        this.props.vTerminal.setDefaultPaymentMethodActiveKey("1");
                    }
                    
                }
                
                this.props.vTerminal.setAmountCategories(categoriesJS);
                this.openVterminalPanel(autopay, record);
            }
            else{
                this.props.global.setLoading(false);
                toast.error("Something is Wrong!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
                }
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
        
    }

    openVterminalPanel(autopay,record){
        if(autopay === 1){
            this.props.vTerminal.disableAutopay(true);
        }
        else{
            this.props.vTerminal.disableAutopay(false);
        }

        this.props.global.setLoading(true);
        this.props.vTerminal.setEntryPoint(record.PaypointEntryname ? record.PaypointEntryname : '');
        this.props.vTerminal.getPaypointCredentials(record.PaypointEntryname ? record.PaypointEntryname : '').then(res => {
            this.props.vTerminal.updateCustomerPaymentsOptions();
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });


        this.setState({ vterminalRightPanelOpen: true, vTerminalAutopay: autopay});
    }

    handleCaptureAmount(value) {
        this.setState({ captureAmount: value });
        if (value > this.state.captureAmountMax) {
          this.setState({ captureAmountError: true });
        } else {
          this.setState({ captureAmountError: false });
        }
    }

    captureTransaction(transaction, totalAmount) {
        this.setState({
          transIdToCapture: transaction,
          captureAmount: String(totalAmount),
          captureAmountMax: totalAmount,
          captureAmountError: false,
        });
        this.openCaptureModal();
    }
    
    reversalCreditTransaction(){
        let transIdToVoid = this.state.transIdToVoid;
        if(transIdToVoid){
            this.props.global.setLoading(true);
            this.props.reports.reversalCreditTransaction(transIdToVoid)
            .then(result => {
                this.closeReversalCreditModal();
                this.props.global.setLoading(false);
                toast.success("Reversal credit successful!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    renderModalRisks(){
        if (this.state.actionsRisks) {
            const {action, payabliTransId} = this.state.actionsRisks
            return (
                <Modal style={{ textAlign: 'center' }} show={this.state.modalConfirmationRisks} onHide={()=> this.deleteModalStatus(false)} size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body>
                        <AiOutlineAlert className="icon-modal" />
                        <h5>{action === 'hold' ? 'Hold' : 'Release'}</h5>
                        <p className="small mb-4">This action will <strong>{action === 'hold' ? 'Hold' : 'Release'}</strong> the Transaction selected, do you want to continue?</p>
                        <Button className="btn cancel-btn" variant="default" onClick={()=> this.showModalConfirmationRisks()}>Cancel</Button>
                        &nbsp;&nbsp;
                        <Button className="btn" variant="primary" onClick={()=> this.savedActionsRisks(action, payabliTransId)}>Save</Button>
                    </Modal.Body>
                </Modal>
            )
        }
        return null
    }
    
    showModalConfirmationRisks(action, payabliTransId){
        let modalConfirmationRisks = !this.state.modalConfirmationRisks
        let actionsRisks = {
            'action': action,
            'payabliTransId': payabliTransId
        }
        this.setState({modalConfirmationRisks: modalConfirmationRisks, actionsRisks: actionsRisks})
    }

    savedActionsRisks(action, payabliTransId){
        if (action && payabliTransId) {
            this.props.global.setLoading(true);
            if (action === 'hold') {
                this.props.management.actionHoldTransaction(payabliTransId)
                .then(result => {
                    this.props.global.setLoading(false);
                    toast.success("Transaction has been successfully held!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });
                    this.getReportFromApi();
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
            }
            if (action === 'release') {
                this.props.management.actionReleaseTransaction(payabliTransId)
                .then(result => {
                    this.props.global.setLoading(false);
                    toast.success("Transaction has been successfully released!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });
                    this.getReportFromApi();
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
            }
            this.showModalConfirmationRisks()
            this.props.global.setLoading(false);
        }
        return
    }

    captureTransactionAction() {
        if (this.state.captureAmountError) {
          return;
        }
 
        let transIdToCapture = this.state.transIdToCapture;    

        if (transIdToCapture) {
          this.props.global.setLoading(true);
          this.props.reports
            .captureTransaction(transIdToCapture, this.state.captureAmount)
            .then((result) => {
              this.setState({ captureModalIsOpen: false });
              this.props.global.setLoading(false);
              if (
                result.data.responseData.resultCode &&
                result.data.responseData.resultCode !== 1
              ) {
                let message = result.data.responseText
                  ? "Sorry, the payment was " + result.data.responseText
                  : "Sorry, we're having a problem capturing your payment.";
                toast.error(message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "toast-error-container",
                });
              } else {
                this.setState({
                  paymentApproved: true,
                  captureReceiptModalIsOpen: true,
                  referenceId: result.data.responseData.referenceId
                    ? result.data.responseData.referenceId
                    : "-",
                  paymentAuthCode: result.data.responseData.authCode
                    ? result.data.responseData.authCode
                    : "-",
                });
              }
            })
            .catch((error) => {
              this.props.global.setLoading(false);
              let errorMessage =
                error.response && error.response.data.responseText
                  ? error.response.data.responseText
                  : "Something is Wrong!";
              toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "toast-error-container",
              });
            });
        }
    }

    openCaptureModal() {
        this.setState({ captureModalIsOpen: true });
    }
  
    closeCaptureModal() {
        this.setState({ captureModalIsOpen: false });
    }

    openReversalCreditModal(transId){
        this.setState({ reversalCreditModalIsOpen: true, transIdToVoid: transId });
    }

    closeReversalCreditModal(){
        this.setState({ reversalCreditModalIsOpen: false, transIdToVoid: null });
    }
    
    closeCaptureReceiptModal() {
        this.setState({ captureReceiptModalIsOpen: false });
    }

    getCustomFieldsFromApi(){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let idOrg = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId;
        let reactObj = this;
        this.props.reports.getOrgSettings(idOrg).then(res => {
            if(res.data.customFields){
                let fields = res.data.customFields;
                fields.forEach(function (item, index) {
                    reactObj.props.reports.addHeader(item.key ,{ label:item.key, class: '', display: true, custom: true});
                });
                this.setState({customFields:fields});
            }
        })
        .catch(error => {
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    getMappingFields(){
        return {
            'date': 'transactiondate',
            'paymentId': 'transid',
            'gatewayTransId': 'gatewaytransid',
            'orderId': 'orderid',
            'cycle': 'scheduleid',
            'OrgId': 'orgid',
            'PaypointId': 'paypointid',
            'PaypointLegalname': 'paypointlegal',
            'ExternalPaypointID': 'externalpaypointid',
            'PaypointDbaname': 'paypointdba',
            'ParentOrgName': 'orgname',
            'brand': 'method',
            'totalAmount': 'totalamount',
            'netAmount': 'netamount',
            'feeAmount': 'feeamount',
            'type': 'operation',
            'source': 'source',
            'status': 'status',
            'settlementStatus': 'settlementstatus',
            'batchNumber': 'batchnumber',
            'customer': 'customer',
            'company': 'company',
            'customerNumber': 'customernumber',
            'invoiceNumber': 'invoicenumber',
            // 'authCode': 'authcode',
            'last4': 'lastfour',
        }
    }
    middlwareFields(field) {
        const apiFields = this.getMappingFields();
        return apiFields[field];
    }
    sortBy(e, record){
        this.setState({ sortBy: this.middlwareFields(record[0]) || '', sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc' }, () => {
            this.getReportFromApi();
        });
    }
    getReportFromApi(){
        let tempFilterValue = {};
        if(this.state.sortBy){
            tempFilterValue['sortBy'] = `${this.state.sortDirection}(${this.state.sortBy})`;
        }
        if(this.state.customerId){
            tempFilterValue['customerId(eq)'] = this.state.customerId;
        }
        if(this.state.idPaypoint){
            tempFilterValue['paypointId(eq)'] = this.state.idPaypoint;
        }
        if(this.state.paymentTransId){
            tempFilterValue['transId(eq)'] = this.state.paymentTransId;
        }
        if(this.state.idtransRefund){
            tempFilterValue['idTrans(eq)'] = this.state.idtransRefund;
            this.setState({head: "Original Payment ID: "+this.state.idtransRefund});
        }
        this.filter(tempFilterValue);
    }

    clearFilters(){
        this.setState({ head: "" }, () => {
            this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }

    showHideFilters(){
        this.props.global.setFilterRightPanelOpen(true);
    }

    viewRecordDetails(index, object){
        if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || object.target.tagName.toLowerCase() === "a" || object.target.tagName.toLowerCase() === "path")){
            return;
        }
        if(object?.target?.id !== "actionsMenuButton"){
            let thisObj = this;
            if(thisObj.props.global.rightPanelOpen && thisObj.props.reports.recordDetails !== index){
                thisObj.props.global.setRightPanelOpen(false);
                thisObj.props.reports.setRecordDetails(index);
                setTimeout(function(){
                    thisObj.props.global.setRightPanelOpen(true);
                }, 500)                
            }else{
                thisObj.props.reports.setRecordDetails(index);
                thisObj.props.global.setRightPanelOpen(true);
            }
        }
    }

    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }
    
    exportFile(format){
        this.props.reports.exportOrgFile('transactions',format,this.state.orgId);
    }

    handleQuickFilter(value){
        this.filter('operation(eq)',value)
        this.setState({ head: value });
    }

    filter(type, value){
        this.props.global.setLoading(true);
        this.props.reports.filter(type,value, 'transactions').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    clearFilter(e, filter, filterType){
        e.preventDefault();
        this.setState({ [filterType]: {} });
        this.filter(filter,"");
    }

    renderLast4Report(record){
        if (record) {
            if (record.PaymentData.MaskedAccount && record.PaymentData.MaskedAccount.toLowerCase() === "poi") {
                return (" - ");
            } else if (record.Method && record.Method.toLowerCase() === "check") {
                return (record.PaymentData.paymentDetails.checkNumber && this.props.global.maskedCardNumber(record.PaymentData.paymentDetails.checkNumber, "v7"));
            }else if (record.Method && record.Method.toLowerCase() === "cash") {
                return (" - ");
            }else{
                return (this.props.global.maskedCardNumber(record.PaymentData.MaskedAccount, "v5"));
            }            
        }
        return (" - ");
    }

    renderActionHoldReleasedTrx(record) {
        const { TransStatus, SettlementStatus, PaymentTransId, ConnectorName } = record;
        if (TransStatus === 1 && ConnectorName?.toLowerCase() === 'gp') {
            return <>
                <Dropdown.Divider />
                <span className='small' style={{ fontSize: '10px', marginLeft: '15px' }}>RISK</span>
                {(SettlementStatus === 0 || SettlementStatus === 1) && <Dropdown.Item onClick={() => this.showModalConfirmationRisks('hold', PaymentTransId)}>Hold Transaction</Dropdown.Item>}
                {(SettlementStatus === -5) && <Dropdown.Item onClick={() => this.showModalConfirmationRisks('release', PaymentTransId)}>Release Transaction</Dropdown.Item>}
            </>;
        }
    }

    allowCaptureTransaction(record) {
        const { TransStatus, TransactionTime } = record;        
        const currentDate = new Date();
        const expirationDate = new Date(TransactionTime);
        expirationDate.setDate(expirationDate.getDate() + 10);
        return (TransStatus === 11 && currentDate <= expirationDate);
    }

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions =  encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        const { totalAmount = 0, totalRecords = 0 } = this.props?.reports || {};
        const rangeDate = this.props.filter?.stateDate?.endDateDate
			? ` ${
					this.props.filter.stateDate.startDateDate
						? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateDate)
						: ''
			  } → ${
					this.props.filter.stateDate.endDateDate
						? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateDate)
						: ''
			  }`
			: 'Last 60 days';

        return (
            <Layout {...this.props}>

                <div id="vterminal-rigth-panel" className={ this.state.vterminalRightPanelOpen ? 'open' : ''}>
                    <div className="popover-body popover-body-2">
                        <BiX id="right-panel-close" onClick={(e) => this.closeVterminalPanel()}/>
                        <h5 className="header mb-3" data-qaid="transactionMoneyInChargeAgainView">{this.state.actionCapture === true ? "Capture": "Charge Again"}</h5>
                        <VirtualTerminal paymentSuccessFunctionCallBack={this.closeVterminalPanel} autopay={0}/>
                    </div>
                </div>
                {this.state.vterminalRightPanelOpen  &&
                <div onClick={(e) => this.closeVterminalPanel()} id="right-panel-layer"></div>
                }

            <div>
                

                <Modal style={{textAlign: "center"}} show={this.state.reversalCreditModalIsOpen} onHide={this.closeReversalCreditModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <BiXCircle className="icon-modal"/>
                        <h5>Reversal Credit</h5>
                        <p className="small">Are you sure you want to Reversal Credit this transaction?</p>
                        <Button className="btn cancel-btn" variant="default" onClick={() => this.closeReversalCreditModal()}>
                            Cancel
                        </Button>
                        &nbsp;&nbsp;
                        <Button className="btn" variant="primary" onClick={this.reversalCreditTransaction}>
                            Reversal Credit
                        </Button>
                    </Modal.Body>
                </Modal>


                {userPermissions.indexOf("Accept Payments") !== -1 && (
                            <Modal
                            style={{ textAlign: "center" }}
                            show={this.state.captureModalIsOpen}
                            onHide={this.closeCaptureModal}
                            size="sm"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            >
                            <Modal.Body>
                                <BiDollarCircle className="icon-modal" />
                                <h5>Capture Payment</h5>
                                <p className="small">Transaction Id:</p>
                                <p className="small-grey">{this.state.transIdToCapture}</p>

                                <NumberFormat
                                onFocus={(e) => this.focus(e)}
                                thousandsGroupStyle="thousand"
                                decimalSeparator="."
                                displayType="input"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={false}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={this.state.captureAmount}
                                placeholder={
                                    "Max amount $" +
                                    this.props.global.numberWithCommas(
                                    this.state.captureAmountMax.toFixed(2)
                                    )
                                }
                                className={
                                    this.state.captureAmountError
                                    ? "form-control input-money-v2 mb-3 input-error"
                                    : "form-control input-money-v2 mb-3"
                                }
                                onValueChange={(values) =>
                                    this.handleCaptureAmount(values.value)
                                }
                                />

                                <Button
                                className="btn full-w"
                                variant="primary"
                                onClick={this.captureTransactionAction}
                                >
                                Capture
                                </Button>
                            </Modal.Body>
                            </Modal>
                        )
                }

                <Modal
                            show={this.state.captureReceiptModalIsOpen}
                            onHide={this.closeCaptureReceiptModal}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Body>
                            <div className="text-center mt-4">
                                <b>Capture Receipt</b>
                                <h2 className="mb-4 mt-4">
                                $
                                {this.props.global.numberWithCommas(
                                    parseFloat(this.state.captureAmount).toFixed(2)
                                )}
                                </h2>
                                {this.state.paymentApproved ? (
                                <p>
                                    <BiCheckCircle
                                    style={{ fontSize: "28px", color: "rgb(136,201,82)" }}
                                    />{" "}
                                    Approved
                                </p>
                                ) : (
                                <>
                                    <p className="mb-2">
                                    <BiCheckCircle
                                        style={{
                                        fontSize: "28px",
                                        color: "rgb(255, 100, 82)",
                                        }}
                                    />{" "}
                                    Declined
                                    </p>
                                    <p className="small-grey mt-0 mb-2">
                                    {this.state.paymentResponseMessage}
                                    </p>
                                </>
                                )}
                            </div>

                            <div
                                className="review-total mb-3"
                                style={{ borderBottom: "none" }}
                            >
                                <div className="row mb-3 mt-3">
                                <div className="col-6 text-left">
                                    <small>
                                    Paid on{" "}
                                    {this.props.global.stringDateFormat(
                                        new Date().toString()
                                    )}
                                    ,{" "}
                                    {this.props.global.stringTimeFormat(
                                        new Date().toString()
                                    )}
                                    </small>
                                </div>
                                </div>

                                <div className="row">
                                <div className="col">Payment:</div>
                                <div className="col">
                                    $
                                    {this.props.global.numberWithCommas(
                                    parseFloat(this.state.captureAmount).toFixed(2)
                                    )}
                                </div>
                                </div>

                                <div className="top-border-grey mt-2 bottom-border-grey">
                                <div className="row total-amount">
                                    <div className="col">Total Amount:</div>
                                    <div className="col">
                                    $
                                    {this.props.global.numberWithCommas(
                                        parseFloat(this.state.captureAmount).toFixed(2)
                                    )}
                                    </div>
                                </div>
                                </div>

                                <div className="mt-2">
                                <div className="row total-amount">
                                    <div className="col-4">Reference Id: </div>
                                    <div className="col-8">
                                    {this.state.referenceId ? this.state.referenceId : ""}
                                    </div>
                                    {this.state.paymentApproved && (
                                    <>
                                        <div className="col-4">AuthCode: </div>
                                        <div className="col-8">
                                        {this.state.paymentAuthCode
                                            ? this.state.paymentAuthCode
                                            : ""}
                                        </div>
                                    </>
                                    )}
                                </div>
                                </div>
                            </div>

                            <Button
                                id="btnCloseReceipt"
                                className="btn full-w btn-lg"
                                onClick={this.closeCaptureReceiptModal}
                            >
                                Close
                            </Button>
                            </Modal.Body>
                </Modal>

              

                <RightPanel>
                    <h5 className="header mb-3" data-qaid="transactionMoneyInViewDetails">Transaction Details</h5>
                    <QuickViewTransaction ref={this.quickViewTransaction} />
                </RightPanel>
                <ParentFilterPanel report={'transactions'} customFields={this.state.customFields} customerId={this.props.match.params.id} paypointId={this.props.match.params.idPaypoint} transId={this.props.match.params.paymentTransId} />
                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">

                { (this.props.match.params.gobackData && this.state.gobackData) ?
                    <>
                        <CustomerLinks gobackLink={this.state.gobackData ? this.state.gobackData.url : ""} gobackText={this.state.gobackData ? this.state.gobackData.label : ""} history={this.props.history} goback={true} selected="payhistory" id={this.state.customerId} />
                    </>
                :
                    <>
                        {this.state.entry ?
                        <PaypointLinks idPaypoint={this.state.idPaypoint ? this.state.idPaypoint : ''} entry={this.state.entry ? this.state.entry : ''} gobackLink={"/"+PayabliStorageManager.getEntryName()+"/paypoints/overview/"+ (this.state.entry ? this.state.entry : '')+"/"+(this.state.idPaypoint ? this.state.idPaypoint : '')} goback={true}  gobackText="Paypoints Overview" history={this.props.history} selected="transactions" />
                        :
                            this.state.customerId ?
                                <CustomerLinks gobackLink={'/'+this.props.global.getURLEntry()+'/customer/'+this.state.customerId} gobackText="Customer Overview" history={this.props.history} goback={true} selected="payhistory" id={this.state.customerId} />
                            :
                                <ReportsLinks selected="transactions" id={this.state.customerId} history={this.props.history} goback={this.state.customerId ? true : false} />
                        }
                    </>
                }
                   
                </div>
                </TopBar>                

                <div className="mt-body4">
                    <MainBar
                        reportTitle={this.state.head ? this.props.global.capitalizeFirstLetter(this.state.head) : "All Transactions"}
                        reportName="Transactions"
                        dataQAName="TransactionsMoneyIn"
                        reportTransaction={true}
                        showHideFilters={this.showHideFilters}
                        selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                        handleShowColumn={(e) => this.handleShowColumn(e)}
                        getHeaders={this.props.reports.getHeaders}
                        headers={this.props.reports.headers}
                        refreshView={this.clearFilters}
                        buttonExport={true}
                        searchBar={false}
                        exportFile={this.exportFile}
                        masterName={this.props.match.params.idPaypoint && `Paypoint - ${this.state.currentPaypointName}`}
                        subMasterName={this.state?.currentCustomer && `Customer - ${this.props.global.capitalizeFirstLetterOfEachWord(this.state?.currentCustomer?.Firstname)} ${this.props.global.capitalizeFirstLetterOfEachWord(this.state?.currentCustomer?.Lastname)}`}
                        withTotal={true}
                        totalRecords={totalRecords || 0}
                        totalAmount={totalAmount ? this.props.global.numberWithCommas(totalAmount.toFixed(2)) : '$ 0.00'}
                        rangeDate={rangeDate}
                        flagItemAllColumn={this.state.flagItemAllColumn}
                        balanceLineEntry={ (this.props.match.path === '/:entryUrl/report/transactions/paypoint/:entry/:idPaypoint' && this.props.match.params.entry) ? this.props.match.params.entry : null}
                    /> 
                <div className="report-container">  
                <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                    {
                        this.props.reports.getHeaders.map((record, i) => (
                            (Object.entries(this.getMappingFields()).flat(1).includes(record[0]) &&
                            record[1].display && 
                            (<th key={i} scope="col" className={`${record[1].class} sortby-parent`}>{record[1].label}
                                <BiSortAlt2 className='cursorPointer sort-icon' onClick={(e) => this.sortBy(e, record)}/>
                            </th> ))
                            ||
                            (record[1].display && 
                            (<th key={i} scope="col" className={record[1].class}>{record[1].label}
                            </th> ))
                        ))
                    }
                    <th className='text-center sticky-row' scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>                
                { this.props.reports.records.map((record, i) => (
                        <tr key={i} onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} className="cursorPointer" onClick={(e) => this.viewRecordDetails(i,e)} data-qaid={"transactionMoneyInRow-"+(i)}>
                            { (this.props.reports.headers.paymentId && this.props.reports.headers.paymentId.display) &&
                                <td>
                                    {record.PaymentTransId ? record.PaymentTransId.substring(0, 20) : ''}...
                                </td>
                            }
                            { (this.props.reports.headers.gatewayTransId && this.props.reports.headers.gatewayTransId.display) &&
                                <td>{record && record.GatewayTransId ? record.GatewayTransId: '-'}</td>
                            }
                            { (this.props.reports.headers.orderId && this.props.reports.headers.orderId.display) &&
                               <td>{record && record.OrderId ? record.OrderId: '-'}</td>
                            }
                            { (this.props.reports.headers.batchNumber && this.props.reports.headers.batchNumber.display) &&
                                <td>{record.BatchNumber ? record.BatchNumber : '-'}</td>
                            }
                            { (this.props.reports.headers.ParentOrgName && this.props.reports.headers.ParentOrgName.display) &&
                                <td>{record && record.ParentOrgName ? this.props.global.capitalizeFirstLetterOfEachWord(record.ParentOrgName): '-'}</td>
                            }
                            { (this.props.reports.headers.PaypointLegalname && this.props.reports.headers.PaypointLegalname.display) &&
                                <td>
                                    <div className='d-flex align-items-center flex-nowrap gap-1'>
                                        <span>{record && record.PaypointLegalname ? this.props.global.capitalizeFirstLetterOfEachWord(record.PaypointLegalname) : '-'}</span>
                                    </div>
                                </td>
                            }
                            { (this.props.reports.headers.PaypointDbaname && this.props.reports.headers.PaypointDbaname.display) &&
                                <td>
                                    <div className='d-flex align-items-center flex-nowrap gap-1'>
                                        <span>{record && record.PaypointDbaname ? this.props.global.capitalizeFirstLetterOfEachWord(record.PaypointDbaname) : '-'}</span>
                                    </div>
                                </td>
                            }
                            { (this.props.reports.headers.paypointId && this.props.reports.headers.paypointId.display) &&
                                <td>{record?.PaypointId ? record.PaypointId : '-'}</td>
                            }
                            { (this.props.reports.headers.ExternalPaypointID && this.props.reports.headers.ExternalPaypointID.display) &&
                                <td>{record && record.externalPaypointID ? record.externalPaypointID : '-'}</td>
                            }
                            { (this.props.reports.headers.customer && this.props.reports.headers.customer.display) &&
                                <td>{record.Customer && record.Customer.FirstName ? this.props.global.capitalizeFirstLetterOfEachWord(record.Customer.FirstName): '-'} { record.Customer && record.Customer.LastName ? this.props.global.capitalizeFirstLetterOfEachWord(record.Customer.LastName) : ''}</td>
                            }
                            { (this.props.reports.headers.company && this.props.reports.headers.company.display) &&
                                <td>{record.Customer && record.Customer.CompanyName ? this.props.global.capitalizeFirstLetterOfEachWord(record.Customer.CompanyName): '-'}</td>
                            }                            
                            { (this.props.reports.headers.customerNumber && this.props.reports.headers.customerNumber.display) &&
                                <td>{record.Customer && record.Customer.CustomerNumber ? record.Customer.CustomerNumber.length > 15 ? record.Customer.CustomerNumber.substring(0, 15) + "..." : record.Customer.CustomerNumber : '-'} </td>
                            }
                            { (this.props.reports.headers.billingEmail && this.props.reports.headers.billingEmail.display) &&
                                <td>{record.Customer && record.Customer.BillingEmail ? record.Customer.BillingEmail : '-'} </td>
                            }                            
                            { (this.props.reports.headers.payorPhone && this.props.reports.headers.payorPhone.display) &&
                                <td>{record.Customer && record.Customer.BillingPhone ? this.props.global.phoneNumberFormat(record.Customer.BillingPhone): '-'} </td>
                            }                           
                            { (this.props.reports.headers.billingAddress && this.props.reports.headers.billingAddress.display) &&
                                <td>{record.Customer && record.Customer.BillingAddress1 ? record.Customer.BillingAddress1 +" "+ record.Customer.BillingAddress2+" "+ record.Customer.BillingCity+" "+ record.Customer.BillingState+" "+ record.Customer.BillingZip+" "+ record.Customer.BillingCountry: '-'} </td>
                            }                            
                            { (this.props.reports.headers.shippingAddress && this.props.reports.headers.shippingAddress.display) &&
                                <td>{record.Customer && record.Customer.ShippingAddress1 ? record.Customer.ShippingAddress1 +" "+ record.Customer.ShippingAddress2+" "+ record.Customer.ShippingCity+" "+ record.Customer.ShippingState+" "+ record.Customer.ShippingZip+" "+ record.Customer.ShippingCountry: '-'} </td>
                            }
                            { (this.props.reports.headers.invoiceNumber && this.props.reports.headers.invoiceNumber.display) &&
                                <td>{record.invoiceData && record.invoiceData.invoiceNumber ? record.invoiceData.invoiceNumber: '-'}</td>
                            }                            
                            { (this.props.reports.headers.date && this.props.reports.headers.date.display) &&
                                <td>{this.props.global.stringDateFormatV3(record.TransactionTime)}</td>
                            }
                            { (this.props.reports.headers.time && this.props.reports.headers.time.display) && 
                                <td>{this.props.global.stringTimeFormat(record.TransactionTime)}</td>
                            }
                            { (this.props.reports.headers.brand && this.props.reports.headers.brand.display) && 
                                <td className='text-center'>{this.props.global.getPaymethodImgPaymentData(record)}</td>
                            }
                            { (this.props.reports.headers.last4 && this.props.reports.headers.last4.display) && 
                                <td>{ this.renderLast4Report(record) }</td>
                            }
                            { (this.props.reports.headers.type && this.props.reports.headers.type.display) && 
                                <td>{record.Operation}</td>
                            }
                            { (this.props.reports.headers.cycle && this.props.reports.headers.cycle.display) && 
                                <td>{record.ScheduleReference > 0 ? 'Scheduled' : 'One-Time'}</td>
                            }
                            { (this.props.reports.headers.status && this.props.reports.headers.status.display) && 
                                <td>{this.props.global.getTransStatus(record.TransStatus)}</td>
                            }
                            { (this.props.reports.headers.settlementStatus && this.props.reports.headers.settlementStatus.display) && 
                                <td>
                                    {
                                        this.props.global.getSettlementTransStatus(record.SettlementStatus, record)                                        
                                    }                                    
                                    {
                                        Array.isArray(record.splitFundingInstructions) && 
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>Split Funded</Tooltip>}>
                                            <MdOutlineCallSplit style={{color:'#7B61FF', transform: 'rotate(90deg)', width:'16px', height:'16px'}}/>
                                        </OverlayTrigger>
                                    }
                                </td>
                            }
                            { (this.props.reports.headers.netAmount && this.props.reports.headers.netAmount.display) && 
                                <td className='text-right'>{this.props.global.numberWithCommasNegative(record.NetAmount.toFixed(2))}</td>
                            }
                            { (this.props.reports.headers.feeAmount && this.props.reports.headers.feeAmount.display) && 
                                <td className='text-right'>${this.props.global.numberWithCommas(record.FeeAmount.toFixed(2))}</td>
                            }                          
                            { (this.props.reports.headers.totalAmount && this.props.reports.headers.totalAmount.display) && 
                                <td className='text-right'>{this.props.global.numberWithCommasNegative(record.TotalAmount.toFixed(2))}</td>
                            }
                            { (this.props.reports.headers.currency && this.props.reports.headers.currency.display) && 
                                <td className='text-center'>{record.PaymentData ? record.PaymentData.paymentDetails.currency ? record.PaymentData.paymentDetails.currency : '-' : '-'}</td>
                            }                            
                            { (this.props.reports.headers.authCode && this.props.reports.headers.authCode.display) && 
                                <td>{record.ResponseData?.authcode ? record.ResponseData?.authcode : '-'}</td>
                            }                            
                            { (this.props.reports.headers.source && this.props.reports.headers.source.display) && 
                                <td>{record.Source ? this.props.global.capitalizeFirstLetter(record.Source) : '-'}</td>
                            }
                            { (this.props.reports.headers.notes && this.props.reports.headers.notes.display) && 
                                <td className='text-center'>
                                    {record.PaymentData && record.PaymentData.orderDescription ? 
                                        <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>{record.PaymentData.orderDescription}</Tooltip>}
                                        >
                                        <BiNotepad className="label-info-icon in-input"/>
                                        </OverlayTrigger>
                                    : '-'}
                                </td>
                            }
                            {
                                 this.state.customFields.map((field, i) => (
                                    (this.props.reports.headers[field.key] && this.props.reports.headers[field.key].display) && 
                                     <td key={i}>{record.Customer && record.Customer.AdditionalData && record.Customer.AdditionalData[field.key] ? record.Customer.AdditionalData[field.key] : '-'}</td>
                                 ))
                            }  
                            <td  className='text-center sticky-row'>
                                <DropdownButton
                                    menuAlign="right"
                                    title={<BiDotsVerticalRounded/>}
                                    data-qaid={"actionTransactionMoneyIn-"+(record.PaypointLegalname)}
                                    id="actionsMenuButton"
                                    size="sm"
                                    variant="default"
                                >
                                <Dropdown.Item onClick={(e) => this.viewRecordDetails(i)} data-qaid="transactionMoneyInQuickViewLink"><div>Quick View</div></Dropdown.Item>
                                <Dropdown.Item onClick={() => this.quickViewTransaction.current.sendTransactionReceipt(record.PaymentTransId, record.Customer?record.Customer.BillingEmail:'') } data-qaid="transactionMoneyInSendReceiptLink">Send Receipt</Dropdown.Item>
                                {(userPermissions.indexOf("Customers") !== -1 && record.PayorId>0)  &&
                                <Dropdown.Item as={Link} to={ record.Customer ? '/'+this.props.global.getURLEntry() +"/customer/"+ record.Customer.customerId : ''} data-qaid="transactionMoneyInViewCustomerLink">View Customer</Dropdown.Item>
                                }
                                {(userPermissions.indexOf("Accept Payments") !== -1 && record.PayorId > 0)   &&
                                <Dropdown.Item onClick={(e) => this.chargeAgain(record.Customer ,record.PaymentData.paymentDetails.categories, 0, record)} data-qaid="transactionMoneyInChargeAgainLink">Charge Again</Dropdown.Item>
                                }
                                {(userPermissions.indexOf("Accept Payments") !== -1 && this.allowCaptureTransaction(record)) &&
                                <Dropdown.Item onClick={(e) => this.captureTransaction(record.PaymentTransId,record.NetAmount)} data-qaid="transactionMoneyInCaptureLink">Capture</Dropdown.Item>
                                }
                                 {userPermissions.indexOf("Refund") !== -1  &&
                                 <>
                                {(record && record.SettlementStatus >= 1) &&
                                <Dropdown.Item onClick={() => this.quickViewTransaction.current.refundTransaction(record.PaymentTransId, record.NetAmount) } data-qaid="transactionMoneyInRefundLink">Refund</Dropdown.Item>
                                }
                                </>
                                }
                                {userPermissions.indexOf("Void") !== -1  &&
                                 <>
                                {(record && (record.SettlementStatus === 0 && (record.TransStatus === 1 || record.TransStatus === 11) )) &&
                                <Dropdown.Item onClick={() => this.quickViewTransaction.current.voidTransaction(record.PaymentTransId) } data-qaid="transactionMoneyInVoidLink">Void</Dropdown.Item>
                                }
                                </>
                                }
                                {record.Operation?.toLowerCase() === 'credit' && (record.TransStatus === 1 || record.TransStatus === 11) &&
                                <Dropdown.Item onClick={() => this.openReversalCreditModal(record.PaymentTransId) } data-qaid="transactionMoneyInCreditLink">Reversal Credit</Dropdown.Item>
                                }
                                { userPermissions.includes("Management") && this.renderActionHoldReleasedTrx(record) }
                                </DropdownButton>
                            </td>
                        </tr>
                    ))
                }
                
                </tbody>
                </table>
                {this.props.reports.records.length < 1 &&
                    <RecordsNotFound message="No transactions in this period" description={<span>There aren't any transactions for this period. Try filtering by a different period.<br />When your customers make payments, you can track transaction details here in real time.</span>}/>
                }
                </div>
                <ReportPaginator report="transactions" mode={this.state.idOrg}/>

                </div>
                <ToastContainer transition={Bounce} />
            </div>
            {this.renderModalRisks()}
            </Layout>
        )
       
    }
}

export { TransactionsReport };
