import { inject, observer } from 'mobx-react';
import React from 'react';
import { PayabliStorageManager } from '../api/localStorageManager';
import { BiInfoCircle } from 'react-icons/bi';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

@inject('management','global')
@observer
class BalanceLine extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            statisticsBalance: {},
            showBalance: false,
            updatedAt: false,
            userPermissions: [],
            loading: false
        };
        this.updateState = this.updateState.bind(this);
    }

    async componentDidMount(){
        const encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        const tempUserPermissions = encryptStorage?.getItem('pUser')?.permissions ? encryptStorage.getItem('pUser').permissions : [];

        if(tempUserPermissions.indexOf("Management") === -1){
            return;
        }

        this.updateState({loading: true, userPermissions: tempUserPermissions});
        let signing = await this.props.management.getSigning();
        let entry = this.props.entry ? this.props.entry : null;
        let availableBalance = {};
        if(entry && signing){
            try{
                availableBalance = await this.props.management.getAvailableBalanceMerchant(entry,signing);
                if(availableBalance && availableBalance.statisticsBalance){
                    this.updateState({
                        statisticsBalance : availableBalance.statisticsBalance,
                        showBalance : true,
                        updatedAt: availableBalance.updatedAt
                    });
                }
                this.updateState({loading: false});
            }catch(e){
                this.updateState({
                    showBalance : false,
                    loading: false
                });
            }
            
        }else{
            this.updateState({loading: false});
        }
    }

    async updateState(values){
        this.setState(values);
    }

    render() {
        let availableBalanceAmount = this.state.statisticsBalance.availableBalanceAmount;
        let pendingBalanceAmount = this.state.statisticsBalance.pendingBalanceAmount;
        let heldAmount = this.state.statisticsBalance.heldAmount;
        let statisticsBalance = this.state.statisticsBalance;
        let userPermissions = this.state.userPermissions;
        
        return (
            <>
            {this.state.loading ?  <div id="statisticsBalance" className='small-small' style={{display: 'inline-block', width: '300px'}}><p className="loading-bar skeleton ml-2"></p></div> :
            <>
            {((userPermissions.indexOf("Management") !== -1 && this.state.showBalance === true) || this.props.testMode === true) &&
            <div id="statisticsBalance" className='small-small mb-2' style={{display: 'inline-block'}}>
                {!this.props.moneyOut ? 
                <>
                <b>BALANCE</b>  <span>Available: {this.props.global.numberWithCommasNegative(availableBalanceAmount?availableBalanceAmount.toFixed(2): '0.00')}</span>
                &nbsp;| <span>Pending: {this.props.global.numberWithCommasNegative(pendingBalanceAmount?pendingBalanceAmount.toFixed(2): '0.00')}</span>
                &nbsp;| <span>Held: {this.props.global.numberWithCommasNegative(heldAmount?heldAmount.toFixed(2): '0.00')}</span>
                </>
                :
                <>
                <b>BALANCE</b>  <span>Available: {this.props.global.numberWithCommasNegative('0.00')}</span>
                &nbsp;| <span>Pending: {this.props.global.numberWithCommasNegative('0.00')}</span>
                &nbsp;| <span>Held: {this.props.global.numberWithCommasNegative('0.00')}</span>
                </>
                }

                {this.props.hideTooltip !== true &&
                <OverlayTrigger trigger={'click'} placement={this.props.placement ? this.props.placement : "right"} overlay={<Tooltip className='dataTooltip'>
                    <b>Payment Processing Velocities & Statistics </b> <br/>
                    {this.state.updatedAt &&
                        <>
                        Updated at: {this.props.global.stringDateFormat(this.state.updatedAt)}<br/>
                        </>
                    }
                    Available Balance Amount: {this.props.global.numberWithCommasNegative(statisticsBalance && statisticsBalance.availableBalanceAmount && !isNaN(statisticsBalance.availableBalanceAmount) ? statisticsBalance.availableBalanceAmount.toFixed(2) : "0.00")} <br/>
                    Pending Balance Amount:  {this.props.global.numberWithCommasNegative(statisticsBalance && statisticsBalance.pendingBalanceAmount && !isNaN(statisticsBalance.pendingBalanceAmount) ? statisticsBalance.pendingBalanceAmount.toFixed(2) : "0.00")} <br/>
                    Held Amount:  {this.props.global.numberWithCommasNegative(statisticsBalance && statisticsBalance.heldAmount && !isNaN(statisticsBalance.heldAmount) ? statisticsBalance.heldAmount.toFixed(2) : "0.00")} <br/>
                    Reserve Balance Amount:  {this.props.global.numberWithCommasNegative(statisticsBalance && statisticsBalance.reserveBalanceAmount && !isNaN(statisticsBalance.reserveBalanceAmount) ? statisticsBalance.reserveBalanceAmount.toFixed(2) : "0.00")} <br/>
                    Allowed Negative Balance Amount:  {this.props.global.numberWithCommasNegative(statisticsBalance && statisticsBalance.allowedNegativeBalanceAmount && !isNaN(statisticsBalance.allowedNegativeBalanceAmount) ? statisticsBalance.allowedNegativeBalanceAmount.toFixed(2) : "0.00")} <br/>
                    Bank Transfer Month To Date Processed Amount:  {this.props.global.numberWithCommasNegative(statisticsBalance && statisticsBalance.bankTransferMonthToDateProcessedAmount && !isNaN(statisticsBalance.bankTransferMonthToDateProcessedAmount) ? statisticsBalance.bankTransferMonthToDateProcessedAmount.toFixed(2) : "0.00")} <br/>
                    Bank Transfer Month To Date Processed Percentage: {statisticsBalance && statisticsBalance.bankTransferMonthToDateProcessedPercentage && !isNaN(statisticsBalance.bankTransferMonthToDateProcessedPercentage) ? `${statisticsBalance.bankTransferMonthToDateProcessedPercentage.toFixed(2)}%` : "0.00%"} <br/>
                    Bank Transfer Monthly Limit Amount: {this.props.global.numberWithCommasNegative(statisticsBalance && statisticsBalance.bankTransferMonthlyLimitAmount && !isNaN(statisticsBalance.bankTransferMonthlyLimitAmount) ? statisticsBalance.bankTransferMonthlyLimitAmount.toFixed(2) : "0.00")} <br/>
                    Bank Transfer Per Transaction Limit Amount:  {this.props.global.numberWithCommasNegative(statisticsBalance && statisticsBalance.bankTransferPerTransactionLimitAmount && !isNaN(statisticsBalance.bankTransferPerTransactionLimitAmount) ? statisticsBalance.bankTransferPerTransactionLimitAmount.toFixed(2) : "0.00")} <br/>
                    Card Month To Date Processed Amount:  {this.props.global.numberWithCommasNegative(statisticsBalance && statisticsBalance.cardMonthToDateProcessedAmount && !isNaN(statisticsBalance.cardMonthToDateProcessedAmount) ? statisticsBalance.cardMonthToDateProcessedAmount.toFixed(2) : "0.00")} <br/>
                    Card Month To Date Processed Percentage: {statisticsBalance && statisticsBalance.cardMonthToDateProcessedPercentage && !isNaN(statisticsBalance.cardMonthToDateProcessedPercentage) ? `${statisticsBalance.cardMonthToDateProcessedPercentage.toFixed(2)}%` : "0.00%"} <br/>
                    Card Monthly Limit Amount:  {this.props.global.numberWithCommasNegative(statisticsBalance && statisticsBalance.cardMonthlylimitAmount && !isNaN(statisticsBalance.cardMonthlylimitAmount) ? statisticsBalance.cardMonthlylimitAmount.toFixed(2) : "0.00")} <br/>
                    Card Transaction Limit Amount:  {this.props.global.numberWithCommasNegative(statisticsBalance && statisticsBalance.cardTransactionLimitAmount && !isNaN(statisticsBalance.cardTransactionLimitAmount) ? statisticsBalance.cardTransactionLimitAmount.toFixed(2) : "0.00")} <br/>
                    Disable Payout Bank Transfer Limit Percentage: {statisticsBalance && statisticsBalance.disablePayoutBankTransferLimitPercentage && !isNaN(statisticsBalance.disablePayoutBankTransferLimitPercentage) ? `${statisticsBalance.disablePayoutBankTransferLimitPercentage.toFixed(2)}%` : "0.00%"} <br/>
                    Disable Payout Card Limit Percentage: {statisticsBalance && statisticsBalance.disablePayoutCardLimitPercentage && !isNaN(statisticsBalance.disablePayoutCardLimitPercentage) ? `${statisticsBalance.disablePayoutCardLimitPercentage.toFixed(2)}%` : "0.00%"}
                </Tooltip>}>
                    <BiInfoCircle style={{fontSize: '12px', marginBottom: '2px', marginLeft: '6px', cursor: 'pointer'}}/>
                </OverlayTrigger>
                }
            </div>
            }
            </>
            }
            </>
        )
    }
}

export { BalanceLine };
