/* eslint-disable no-loop-func */
import { addBankAccountPOST, updateBankAccountPUT, updateMerchantBankAccountPUT, bankAccountDELETE, attachmentGET, attachmentPOST, attachmentDELETE } from '../Connections/BankConnections'
import { toast } from 'react-toastify'
import { action } from 'mobx'

export default function BankFunction({ payPoint, service, banking, setBanking, documentsRef, setDocumentsRef, signingKey, paramsURL, entryName }) {
	const FileDownload = require('js-file-download')
	const handleChangeBanking = action((indexArray, field, value) => {
		const newData = banking.bankData.map((data, index) => {
			if (index === indexArray) {
				return {
					...data,
					[field]: value
				}
			}
			return data
		})
		setBanking({ ...banking, bankData: newData })
	})
	const handleChangeBankingSpliter = action((type, field, value) => {
		const newData = banking
		newData[type][field] = value
		setBanking({ ...newData })
	})
	const handleChangeBankingSimple = action((field, value) => {
		setBanking({ ...banking, [field]: value })
	})
	const addBank = action(async (bankData) => {
		try {
			const res = await addBankAccountPOST(payPoint.idPaypoint, bankData)
			if (res.data.isSuccess === true) {
				toast.success('Bank added successfully!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
			}
			return res.data.isSuccess
		} catch (error) {
			if (error.response.data.isSuccess === false) {
				toast.success(error.response.data.responseData.explanation, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			} else {
				let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
				toast.error(errorMessage, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			}
		}
		return false
	})
	const updateBankAccount = action(async (idBank) => {
		try {
			const dataBank = banking.bankData.find(({ id }) => id === idBank)
			const oldDataBank = banking.bankDataOld.find(({ id }) => id === idBank)
			if (dataBank) {
				let res
				const NewBanking = {}
				const merchantID = dataBank.accountNumber !== oldDataBank.accountNumber ? getValuesForBankGP('merchantID', dataBank.id) : ''
				NewBanking.id = dataBank.id
				NewBanking.bankName = dataBank.bankName
				NewBanking.bankAccountHolderName = dataBank.bankAccountHolderName
				NewBanking.bankAccountHolderType = dataBank.bankAccountHolderType
				NewBanking.typeAccount = dataBank.typeAccount
				NewBanking.country = dataBank.country === null || dataBank.country === undefined || dataBank.country.trim().length === 0 ? 'US' : dataBank.country
				NewBanking.bankAccountFunction = dataBank.bankAccountFunction
				if (dataBank.routingAccount !== oldDataBank.routingAccount) {
					NewBanking.routingAccount = dataBank.routingAccount
				} else if (merchantID !== '') {
					NewBanking.routingAccount = dataBank.routingAccount
				}
				if (dataBank.accountNumber !== oldDataBank.accountNumber) {
					NewBanking.accountNumber = dataBank.accountNumber
					NewBanking.merchantID = merchantID
				}
				if (merchantID !== '') {
					res = await updateMerchantBankAccountPUT(entryName, signingKey, NewBanking)
				} else {
					res = await updateBankAccountPUT(idBank, NewBanking)
				}
				if (res.data.isSuccess === true) {
					toast.success('Bank added successfully!', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-success-container'
					})
				}
			} else {
				toast.success('Bank not found', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
				return false
			}
		} catch (error) {
			if (error.response.data.isSuccess === false) {
				toast.success(error.response.data.responseData.explanation, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			} else {
				let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
				toast.error(errorMessage, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			}
			return false
		}
		return true
	})
	const getValuesForBankGP = action((returnValue, idBank) => {
		const serviceCard = { moneyin: ['card', 'ach', 'check', 'cloud', 'cash', 'wallet'], moneyout: ['managed', 'ach', 'check', 'vcard', 'pushToCard'] }
		let serviceGP = { moneyin: {}, moneyout: {} }

		for (let serviceType in serviceCard) {
			serviceCard[serviceType].forEach((type) =>
				service[serviceType][type].forEach((dataService) => {
					if (dataService.Gateways.find((gateway) => gateway.Connector.Name.toLowerCase() === 'gp' && Array.isArray(gateway.BankData) && gateway.BankData.find((bank) => bank.id === idBank))) {
						serviceGP[serviceType][type] = []
						serviceGP[serviceType][type].push(dataService.Gateways.find((gateway) => gateway.Connector.Name.toLowerCase() === 'gp' && gateway.BankData.find((bank) => bank.id === idBank)))
					}
				})
			)
		}
		if (returnValue === 'service') return serviceGP

		let merchantID = ''
		for (let serviceType in serviceCard) {
			serviceCard[serviceType].forEach((type) => {
				if (Array.isArray(serviceGP[serviceType][type])) {
					serviceGP[serviceType][type].forEach((gateway) => {
						if (merchantID === '') merchantID = gateway.MerchantIdentifier
					})
				}
			})
		}
		if (returnValue === 'merchantID') return merchantID
		return serviceGP
	})
	const validaBankRemove = action((idBank) => {
		const msgValiue = []
		const serviceCard = {
			moneyin: ['card', 'ach', 'check', 'cloud', 'cash', 'wallet'],
			moneyout: ['managed', 'ach', 'check', 'vcard', 'pushToCard']
		}
		const serviceName = {
			moneyin: {
				card: 'Credit Card',
				ach: 'ACH',
				check: 'Check',
				cash: 'Cash',
				cloud: 'Cloud Devices',
				wallet: 'Wallet'
			},
			moneyout: {
				managed: 'Managed Payables',
				ach: 'ACH',
				vcard: 'Virtual Card',
				check: 'Physical Check',
				pushToCard: 'Push To Card'
			}
		}

		for (let serviceType in serviceCard) {
			serviceCard[serviceType].forEach((type) => {
				service[serviceType][type].forEach((dataService) => {
					dataService.Gateways.forEach((gateway) => {
						if (gateway.BankData !== null) {
							const res = gateway.BankData.find(({ id }) => id === idBank)
							if (res) {
								const data = msgValiue.find((value) => value === serviceName[serviceType][type])
								if (!data) msgValiue.push(serviceName[serviceType][type])
							}
						}
					})
				})
			})
		}

		if (msgValiue.toString()) {
			toast.error('The bank account cannot be deleted, because it is assigned to the following services that are detailed below (' + msgValiue.toString() + ')', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return false
		}
		return true
	})
	const removeBank = action(async (idBank) => {
		try {
			const res = await bankAccountDELETE(idBank)
			if (res.data.isSuccess === true) {
				toast.success('Bank removed successfully!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
				return true
			}
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return false
		}
	})
	const getBase64 = async (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()

			reader.onload = (res) => {
				resolve(res.target.result)
			}
			reader.onerror = (err) => reject(err)
			reader.readAsDataURL(file)
		})
	}
	const readAttachments = action(async (template_data) => {
		return new Promise((resolve) => {
			let fs = []
			let fp = []
			let ek = Object.keys(template_data)
			if (ek.length > 0) {
				let dc = ek.filter((element) => element.startsWith('doc_'))
				if (dc.length > 0) {
					for (var fi = 0; fi < dc.length; fi++) {
						if (template_data[dc[fi]].value && template_data[dc[fi]].value !== null) {
							fp.push(template_data[dc[fi]].value)
						}
					}
				}
			}
			if (fp.length === 0) {
				resolve(fs)
			}
			let xi = 0
			let pfx = 0
			while (xi < fp.length) {
				let ff = fp[xi]
				getBase64(ff)
					.then((res) => {
						let fo = {}
						fo.filename = ff.name
						fo.ftype = fo.filename.split('.').pop()
						fo.fContent = res.split(',').pop()
						fs.push(fo)
						pfx++
						if (pfx >= fp.length) {
							resolve(fs)
						}
					})
					.catch((err) => {
						console.log(err)
						pfx++
						if (pfx >= fp.length) {
							resolve(fs)
						}
					})
				xi++
			}
		})
	})
	const downLoadDocument = action(async (fileName, zipfile, type) => {
		try {
			const res = await attachmentGET(paramsURL.id, fileName, signingKey)
			if (res) {
				FileDownload(res.data, zipfile.replace('.zip', '') + '.' + type)
			}
			return true
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		}
		return false
	})
	const upLoadDocument = action(async (dataDoc) => {
		try {
			let newDataFile = {
				paypointId: paramsURL.id,
				attachments: dataDoc
			}
			const res = await attachmentPOST(newDataFile, signingKey)
			if (res.data.isSuccess === true) {
				toast.success('Documents added successfully!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
			}
			return true
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		}
		return false
	})
	const deleteDocument = action(async (fileName) => {
		try {
			const res = await attachmentDELETE(paramsURL.id, fileName, signingKey)
			if (res.data.isSuccess === true) {
				toast.success('Document deleted successfully!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
			}
			return true
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText + 'document ' + fileName : 'Something is wrong! with the document ' + fileName
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		}
		return false
	})
	const handleCheckUpdate = action((indexFile, value) => {
		const newData = documentsRef.documents.filelist.map((data, index) => {
			if (index === indexFile) {
				return {
					...data,
					check: value
				}
			}
			return data
		})
		setDocumentsRef({
			...documentsRef,
			documents: { ...documentsRef.documents, filelist: newData }
		})
		return false
	})

	return {
		handleChangeBanking,
		handleChangeBankingSpliter,
		handleChangeBankingSimple,
		addBank,
		updateBankAccount,
		validaBankRemove,
		removeBank,
		handleCheckUpdate,
		readAttachments,
		downLoadDocument,
		upLoadDocument,
		deleteDocument,
		getValuesForBankGP
	}
}
