import axios from 'axios'
import { PayabliStorageManager } from '../../../api/localStorageManager'

let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()

export const managementPaypointGET = async (id, signing) => {
	const res = await axios.get(process.env.REACT_APP_URL_API + 'Management/paypoint/' + id, {
		headers: {
			requestToken: encryptStorage.getItem('pToken'),
			signing: signing
		}
	})
	return res
}

export const payPointSettingsGET = async (entry) => {
	const res = await axios.get(process.env.REACT_APP_URL_API + 'Paypoint/settings/' + entry, {
		headers: {
			requestToken: encryptStorage.getItem('pToken')
		}
	})
	return res
}

export const payPointSettingsPUT = async (entry, settings) => {
	const res = await axios.put(process.env.REACT_APP_URL_API + 'Paypoint/settings/' + entry, settings, {
		headers: {
			requestToken: encryptStorage.getItem('pToken')
		}
	})
	return res
}

export const payPointStatusPUT = async (id, signing, state) => {
	const res = await axios.put(
		process.env.REACT_APP_URL_API + 'Management/paypointStatus/' + id,
		{ paypointStatus: state ? 1 : 0 },
		{
			headers: {
				requestToken: encryptStorage.getItem('pToken'),
				signing: signing
			}
		}
	)
	return res
}

export const payPointPOST = async (signing, payPointData) => {
	const res = await axios.post(process.env.REACT_APP_URL_API + 'Management/paypoint/', payPointData, {
		headers: {
			requestToken: encryptStorage.getItem('pToken'),
			signing: signing
		}
	})
	return res
}

export const payPointPUT = async (id, signing, payPointData) => {
	const res = await axios.put(process.env.REACT_APP_URL_API + 'Management/paypoint/' + id, payPointData, {
		headers: {
			requestToken: encryptStorage.getItem('pToken'),
			signing: signing
		}
	})
	return res
}
