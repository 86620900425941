import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { observable, action } from 'mobx'
import ReadFunction from './recordFunction/ReadFunction'
import BankFunction from './recordFunction/BankFunction'
import TiersFunction from './recordFunction/TiersFunction'
import ServicesFunctionAPI from './recordFunction/ServiceFunction/ServicesFunctionAPI'
import ServicesFunction from './recordFunction/ServiceFunction/ServiceFunction'
import ServiceFunctionHandle from './recordFunction/ServiceFunction/ServiceFunctionHandle'
import ServiceFunctionLoad from './recordFunction/ServiceFunction/ServiceFunctionLoad'
import PaypointFunction from './recordFunction/PaypointFunction'
import BusinessFunction from './recordFunction/BusinessFunction'
import OwnerFunction from './recordFunction/OwnerFunction'
import ProcessingFunction from './recordFunction/ProcessingFunction'
import FraudControlFunction from './recordFunction/FraudControlFunction'
import commandCenterContext from '../../views/commandCenter/context/commandCenter_context'
import { toast, ToastContainer, Bounce } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function ManagementPaypoint({ children, setLoading, isLoading }) {
	const params = useParams()
	const [paramsURL, SetParamsURL] = useState({ id: params.id, oid: params.oid })
	const [signingKey, SetSigningKey] = useState('')
	const [entryName, SetEntryName] = useState('')
	const [newPypoint, SetNewPypoint] = useState(false)
	const ParamsURL = observable(paramsURL)

	const [payPoint, setPayPoint] = useState({
		idPaypoint: 0,
		paypointStatus: 0,
		serviceData: {
			card: {
				acceptVisa: true,
				acceptMastercard: true,
				acceptDiscover: true,
				acceptAmex: true
			},
			ach: {
				acceptWeb: true,
				acceptPPD: true,
				acceptCCD: true
			}
		},
		salesCode: '',
		externalPaypointID: '',
		credentials: [
			{
				service: '',
				mode: 0,
				minticket: 0,
				maxticket: 0,
				cfeeFix: 0,
				cfeeFloat: 0,
				cfeeMin: 0,
				cfeeMax: 0,
				referenceId: 0,
				accountId: '',
				provider: '',
				providerID: '',
				data1: '',
				data2: '',
				data3: '',
				serviceSplitMode: 0,
				feeData: ''
			}
		],
		entryPoints: []
	})
	const PayPoint = observable(payPoint)
	const [business, setBusiness] = useState({
		dbaName: '',
		legalName: '',
		ownType: '',
		ein: '',
		taxfillname: '',
		license: '',
		licenseState: '',
		websiteAddress: '',
		startdate: new Date(),
		phone: '',
		fax: '',
		bTimezone: 0,
		address1: '',
		address2: '',
		city: '',
		zip: '',
		state: '',
		country: '',
		mailAddress1: '',
		mailAddress2: '',
		mailCity: '',
		mailZip: '',
		mailState: '',
		mailCountry: '',
		sameAddress: true
	})
	const Business = observable(business)
	const [owner, setOwner] = useState({
		ownership: [
			{
				ownername: '',
				ownertitle: '',
				ownerpercent: 0,
				ownerssn: '',
				ownerdob: new Date(),
				ownerphone1: '',
				ownerphone2: '',
				owneremail: '',
				ownerdriver: '',
				odriverstate: '',
				oaddress: '',
				ocity: '',
				ozip: '',
				ostate: '',
				ocountry: ''
			}
		],
		contacts: [
			{
				contactName: '',
				contactTitle: '',
				contactEmail: '',
				contactPhone: ''
			}
		]
	})
	const Owner = observable(owner)
	const [processing, setProcessing] = useState({
		mcc: '',
		binPerson: 0,
		binPhone: 0,
		binWeb: 0,
		summary: '',
		averageMonthlyVolume: 0,
		averageTicketAmount: 0,
		highTicketAmount: 0,
		whencharged: '',
		whenprovided: '',
		whendelivered: '',
		whenrefund: '',
		actualCurrencySet: ''
	})
	const Processing = observable(processing)
	const [banking, setBanking] = useState({
		bankData: [],
		bankDataOld: [
			{
				id: 0,
				bankName: '',
				typeAccount: '',
				routingAccount: '',
				accountNumber: '',
				bankAccountHolderName: '',
				bankAccountHolderType: '',
				bankAccountFunction: 0
			}
		]
	})
	const Banking = observable(banking)
	const [documentsRef, setDocumentsRef] = useState({ documents: { filelist: [] } })
	const DocumentsRef = observable(documentsRef)
	const [service, setService] = useState({
		moneyin: {
			card: [],
			ach: [],
			check: [],
			cloud: [],
			cash: [],
			wallet: []
		},
		moneyout: {
			managed: [],
			ach: [],
			check: [],
			vcard: [],
			pushToCard: []
		}
	})
	const services = observable(service)
	const [fraudControl, setFraudControl] = useState({
		id: 0,
		orgType: 1,
		orgId: 0,
		maxDeclinedPerIpHourly: 0,
		maxAmountPeriod: 0,
		period: 0,
		maxTx15Min: 0,
		maxDeclinedPerCardHourly: 0,
		maxDeclinedPerPayorHourly: 0,
		maxTxPerCardHourly: 0,
		maxDeclinedPerPointHourly: 0
	})
	const fraudControls = observable(fraudControl)
	const [paypointCurrency, setPaypointCurrency] = useState({
		readOnly: false,
		key: 'currency',
		value: 'USD'
	})
	const PaypointCurrency = observable(paypointCurrency)
	const [paypointSetting, setPaypointSetting] = useState({})

	const loadData = action(async (id) => {
		parseInt(paramsURL.id) === 0 ? SetNewPypoint(true) : SetNewPypoint(false)
		const signing = await getAuth()
		const dataPayPoint = await loadPaypoint(id ? id : paramsURL.id, signing)
		fillPayPoint(dataPayPoint)
		//console.log('dataPayPoint', dataPayPoint)
		loadPaypointService(signing, dataPayPoint && dataPayPoint.entryPoints.length > 0 ? dataPayPoint.entryPoints[0].entryName : '')
		loadPaypointSettings(dataPayPoint && dataPayPoint.entryPoints.length > 0 ? dataPayPoint.entryPoints[0].entryName : '')
		SetEntryName(dataPayPoint && dataPayPoint.entryPoints.length > 0 ? dataPayPoint.entryPoints[0].entryName : '')
		if (dataPayPoint && dataPayPoint.entryPoints.length <= 0) toastMessage('Entry point not found, will not be able to add services', false)
		if (dataPayPoint) loadFraudCtr(id ? id : paramsURL.id, signing)
		setLoading(false)
		//console.log('signing', signing)
		return signing
	})
	const toastMessage = action((message, success = true) => {
		if (success) {
			toast.success(message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-success-container'
			})
		} else {
			toast.error(message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		}
	})

	const { handleChangePayPoint, loadPaypoint, loadPaypointSettings, updateStatus, updatePayPoint } = PaypointFunction({ payPoint, business, owner, processing, setPaypointSetting, setLoading, setPaypointCurrency, paypointSetting, paypointCurrency, paramsURL, signingKey, SetParamsURL, loadData, setPayPoint })
	const { fillPayPoint, getAuth } = ReadFunction({ setPayPoint, setBusiness, setOwner, setProcessing, setBanking, setDocumentsRef, setLoading, SetSigningKey })
	const { handleChangeBusiness, sameAddressBusiness } = BusinessFunction({ business, setBusiness })
	const { handleChangeOwner, addOwner, removeOwner } = OwnerFunction({ owner, setOwner })
	const { handleChangeProcessing, handleChangeCurrency } = ProcessingFunction({ processing, setProcessing, paypointCurrency, setPaypointCurrency })
	const { handleChangeBanking, addBank, updateBankAccount, validaBankRemove, removeBank, handleCheckUpdate, readAttachments, downLoadDocument, upLoadDocument, deleteDocument, getValuesForBankGP } = BankFunction({ payPoint, service, banking, setBanking, documentsRef, setDocumentsRef, signingKey, paramsURL, entryName })
	const { handleChangeFraud, loadFraudCtr, updateFraudcontrol } = FraudControlFunction({ fraudControl, setFraudControl, signingKey, paramsURL, setLoading })
	const { structureService, selectGateway, selectProcess } = ServicesFunction({ service, banking })
	const { loadPaypointService, loadPaypointServiceName } = ServiceFunctionLoad({ service, setService, payPoint, signingKey })
	const { createService, updateService, deleteService } = ServicesFunctionAPI({ service, payPoint, signingKey, selectGateway, structureService, selectProcess, loadPaypointService, getValuesForBankGP, banking })
	const { handleChangeServices } = ServiceFunctionHandle({ service, setService })
	const { handleAddBankTier, handleRemoveBankTier } = TiersFunction({ service, setService })

	return (
		<commandCenterContext.Provider
			value={{
				setLoading,
				ParamsURL,
				loadData,
				PayPoint,
				PaypointCurrency,
				handleChangeCurrency,
				handleChangePayPoint,
				Business,
				handleChangeBusiness,
				sameAddressBusiness,
				Owner,
				handleChangeOwner,
				addOwner,
				removeOwner,
				Processing,
				handleChangeProcessing,
				Banking,
				DocumentsRef,
				handleChangeBanking,
				addBank,
				updateBankAccount,
				removeBank,
				handleCheckUpdate,
				readAttachments,
				downLoadDocument,
				upLoadDocument,
				deleteDocument,
				validaBankRemove,
				getAuth,
				loadPaypoint,
				updateStatus,
				updatePayPoint,
				services,
				handleChangeServices,
				createService,
				updateService,
				deleteService,
				handleAddBankTier,
				handleRemoveBankTier,
				loadPaypointService,
				loadPaypointServiceName,
				loadFraudCtr,
				updateFraudcontrol,
				fraudControls,
				handleChangeFraud,
				toastMessage,
				isLoading,
				newPypoint
			}}>
			<>
				{children}
				<ToastContainer transition={Bounce} />
			</>
		</commandCenterContext.Provider>
	)
}
