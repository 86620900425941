import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Layout } from '../../components/Layout';

import { ReadOnlySimpleLink } from '../../components/ReadOnlySimpleLink';
import { ReadOnlyCheckLink } from '../../components/ReadOnlyCheckLink';
import { Tab, Tabs, Row, Button, Table, InputGroup } from "react-bootstrap";

import { BiDetail } from '@react-icons/all-files/bi/BiDetail';
import { BiUserCheck } from '@react-icons/all-files/bi/BiUserCheck';
import { BiCog } from '@react-icons/all-files/bi/BiCog';
import { BiDollarCircle } from '@react-icons/all-files/bi/BiDollarCircle';
import { RiBankLine } from '@react-icons/all-files/ri/RiBankLine';
import { PayabliStorageManager } from '../../api/localStorageManager'
import { PaypointLinks } from '../../components/PaypointLinks';
import {BiTrash} from "@react-icons/all-files/bi/BiTrash";
import axios from 'axios';
import {BiInfoCircle} from '@react-icons/all-files/bi/BiInfoCircle';
const FileDownload = require('js-file-download');


@inject('reports','store','global', 'boarding')
@observer
class ApplicationReport extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            baseTemplate: null,
            baseData:null,
            appId: 0,
            currentSection: 'business',
            offOwn: true,
            offPrc: true,
            offSrv: true,
            offDoc: true,
            complete: false,
            bsameaddress: true,
            endLinkModalIsOpen:false,
            additionalOwners:[],
            lastowner:0,
            additionalContacts:[],
            lastcontact:0,
            additionalFiles: [],
            lastfile: 0,
            currentPos: 0,
            activePos: [],
            isLoading: true,
            cancelTplModalIsOpen: false,
            saveTplModalIsOpen: false,
            isRoot: false,
            authData: null
        };
        this.downloadFiles = this.downloadFiles.bind(this);
    }

    handlerGetBaseLink = async () => {
        this.props.global.setLoading(true);
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        axios.get(process.env.REACT_APP_URL_API + 'Paypoint/' + this.state.entry, {
            headers: {
                'requestToken': encryptStorage.getItem('pToken')
            }
        }).then( bdata => {
            if (!bdata.data.responseData || bdata.data.responseData === null) {
                this.props.global.setLoading(false);
                let errorMessage = "Missing Template Data!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
                return;
            }
            let appid = bdata.data.responseData.Paypoint.BoardingId
            axios.get(process.env.REACT_APP_URL_API + 'Boarding/read/' + appid, {
                headers: {
                    'requestToken': encryptStorage.getItem('pToken')
                }
            }).then(tdata => {
                if (!tdata.data.builderData || tdata.data.builderData === null) {
                    this.props.global.setLoading(false);
                    let errorMessage = "Missing Template Data!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                    return;
                }
                this.setState({ 
                    baseTemplate: tdata.data.builderData, 
                    baseData: tdata.data, 
                    appId: appid,
                    isLoadingBase: false, 
                    update:true,
                    signer : tdata.data.signer }, () => {
                    if (this.state.baseTemplate) {
                        if (this.state.baseTemplate.attributes && this.state.baseTemplate.attributes.minimumDocuments) {
                            let x = parseInt(this.state.baseTemplate.attributes.minimumDocuments, 10);
                            this.setState({ lastfile: x });
                        }
                        if (this.state.baseData.contactData !== null && this.state.baseData.contactData.length > 1) {
                            var cts = [];
                            for (var cc = 1; cc < this.state.baseData.contactData.length; cc++) cts.push(cc);
                            this.setState({ additionalContacts: cts, lastcontact: this.state.baseData.contactData.length });
                        }
                        if (this.state.baseData.ownerData !== null && this.state.baseData.ownerData.length > 1) {
                            var ots = [];
                            for (var oc = 1; oc < this.state.baseData.ownerData.length; oc++) ots.push(oc);
                            this.setState({ additionalOwners: ots, lastowner: this.state.baseData.ownerData.length });
                        }
                        //init positions
                        var atabs = [];
                        var cs = '';
                        if (this.state.baseTemplate.business !== null) {
                            atabs.push(0);
                            cs = 'business';
                        }
                        if (this.state.baseTemplate.owners !== null) {
                            atabs.push(1);
                            if (cs === '') cs = 'owners';
                        }
                        if (this.state.baseTemplate.processing !== null) {
                            atabs.push(2);
                            if (cs === '') cs = 'processing';
                        }
                        if (this.state.baseTemplate.services !== null) {
                            atabs.push(3);
                            if (cs === '') cs = 'services';
                        }
                        if (this.state.baseTemplate.banking !== null) {
                            atabs.push(4);
                            if (cs === '') cs = 'banking';
                        }
                        atabs.push(5);
                        this.setState({ currentSection: cs, activePos: atabs })
                        this.props.global.setLoading(false);
                    }
                });
            }).catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        })
        
    }

    componentDidMount() {
        this.props.global.protect(this.props.history);
        this.setState(
            {
                appId: this.props.match.params.id, 
                entry: this.props.match.params.entry ? this.props.match.params.entry : null,
                idPaypoint: this.props.match.params.idPaypoint ? this.props.match.params.idPaypoint : null
            }, function () {
                this.handlerGetBaseLink();
            });
       
        this.getAuth();

    }

    downloadFiles(zip, e){
        if(this.state.authData === null || this.state.appId === 0){
            toast.error("Reading data error", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
            return;
        }

        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        axios.get(process.env.REACT_APP_URL_API + 'Management/attachmentFromApp/'+this.state.appId, {
            responseType: "blob",
            headers: {
                'requestToken': encryptStorage.getItem('pToken'),
                'signing':this.state.authData
            }
        })
        .then(res => {
            FileDownload(res.data, zip.replace(".zip", "") + ".zip");
        })
        .catch(error => {
            toast.error("Error downloading file", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    getAuth(){
        //auth for management
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        axios.get(process.env.REACT_APP_URL_API + 'Management/auth', {
            headers: {
                'requestToken': encryptStorage.getItem('pToken')
            }
        }).then(dt => {
            let authData = dt.data.responseData;
            if (!authData || authData === null) {
                toast.error("Failed Authentication!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
                return;
            }
            
            this.setState({authData:authData});

        }).catch(error => {
           
        });
    }

    
    setTab(event) {
        this.setState({ currentSection: event });
    }

    getSignerBlock(){
        let signers = [];
        if(this.props.boarding.template_data['ownertitle_0']){
            signers.push({
                contactName: this.props.boarding.template_data['ownertitle_0'].value,
                contactEmail: this.props.boarding.template_data['owneremail_0'].value,
                contactPhone: this.props.boarding.template_data['ownerphone1_0'].value,
                contactDOB: this.props.boarding.template_data['ownerdob_0'].value,
                contactSSN: this.props.boarding.template_data['ownerssn_0'] ? this.props.boarding.template_data['ownerssn_0'].value : '',
            });
        }

        this.state.additionalOwners.forEach(element => {
            if(this.props.boarding.template_data['ownertitle_'+element]){
                signers.push({
                    contactName: this.props.boarding.template_data['ownername_'+element].value,
                    contactEmail: this.props.boarding.template_data['owneremail_'+element].value,
                    contactPhone: this.props.boarding.template_data['ownerphone1_'+element].value,
                    contactDOB: this.props.boarding.template_data['ownerdob_'+element].value,
                    contactSSN: this.props.boarding.template_data['ownerssn_'+element].value,
                });
            }
        });

        if(this.props.boarding.template_data['contactName_0']){
            signers.push({
                contactName: this.props.boarding.template_data['contactName_0'].value,
                contactEmail: this.props.boarding.template_data['contactEmail_0'].value,
                contactPhone: this.props.boarding.template_data['contactPhone_0'].value,
            });
        }

        this.state.additionalContacts.forEach(element => {
            if(this.props.boarding.template_data['contactName_'+element]){
                signers.push({
                    contactName: this.props.boarding.template_data['contactName_'+element].value,
                    contactEmail: this.props.boarding.template_data['contactEmail_'+element].value,
                    contactPhone: this.props.boarding.template_data['contactPhone_'+element].value,
                });
            }
        });

        const years = [];
        let year = new Date().getFullYear();
        for (let i = 1900; i <= year; i++) {
            years.push(i);
        }

        return (
            <>
                <h5 className="mt-5">Signer</h5>
                <p className="small mb-4 dark-grey">Signer information</p>
                <div className="row">
                    <div className="col-sm-6">
                        <ReadOnlySimpleLink iTitle="Name" iName="signer_name" iValue={this.state.signer && this.state.signer.name? this.state.signer.name: ""} />
                    </div>
                    <div className="col-sm-6">
                        <ReadOnlySimpleLink iHideShow={true} iMask="ssn" iTitle="Social Security Number" iName="signer_ssn" iValue={this.state.signer && this.state.signer.ssn ? this.state.signer.ssn : ''} />
                    </div>
                    
                    <div className="col-md-6">
                        <ReadOnlySimpleLink iClass="input-calendar" iTitle="Date of Birth" iName="signer_dob" iValue={this.props.global.stringDateFormat(this.state.signer.dob)}  />
                    </div>

                    <div className="col-sm-6">
                        <ReadOnlySimpleLink iTitle="Phone" iName="signer_phone" iValue={this.state.signer && this.state.signer.phone ? this.state.signer.phone : ''} />
                    </div>
                    <div className="col-sm-6">
                        <ReadOnlySimpleLink iTitle="Email" iName="signer_email" iValue={this.state.signer && this.state.signer.email? this.state.signer.email : ''}  />
                    </div>
                    <div className="col-sm-6">
                        <ReadOnlySimpleLink iTitle="Address" iName="signer_address" iValue={this.state.signer && this.state.signer.address ? this.state.signer.address :''} />
                    </div>
                    <div className="col-sm-6">
                        <ReadOnlySimpleLink iType="us_states" iTitle="State" iName="signer_state" iValue={this.state.signer && this.state.signer.state ? this.state.signer.state : '' } />
                    </div>
                    <div className="col-sm-6">
                        <ReadOnlySimpleLink iType="countries" iTitle="Country" iName="signer_country" iValue={this.state.signer && this.state.signer.country  ? this.state.signer.country : ''} />
                    </div>
                    <div className="col-sm-6">
                        <ReadOnlySimpleLink iTitle="City" iName="signer_city" iValue={this.state.signer && this.state.signer.city ? this.state.signer.city : '' }  />
                    </div>
                    <div className="col-sm-6">
                        <ReadOnlySimpleLink iTitle="Zip" iName="signer_zip" iValue={this.state.signer && this.state.signer.zip ? this.state.signer.zip : '' }  />
                    </div>
                </div>
                
            </>
        )
    }

    getOwnerBlock(k) {
        if (!this.state.baseTemplate.owners || this.state.baseTemplate.owners === null) return;
        var rows = [];

        if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ownername && this.state.baseTemplate.owners.own_list.ownername !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="Owner Name" iName={"ownername_" + k} iValue={(this.state.baseData.ownerData!==null && this.state.baseData.ownerData[k]) ? this.state.baseData.ownerData[k].ownername : this.state.baseTemplate.owners.own_list.ownername.value} />);
        }
        if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ownertitle && this.state.baseTemplate.owners.own_list.ownertitle !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="Owner Title" iName={"ownertitle_" + k} iValue={(this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k]) ? this.state.baseData.ownerData[k].ownertitle : this.state.baseTemplate.owners.own_list.ownertitle.value} />);
        }
        if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ownerpercent && this.state.baseTemplate.owners.own_list.ownerpercent !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="Ownership %" iName={"ownerpercent_" + k} iValue={(this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k]) ? this.state.baseData.ownerData[k].ownerpercent : this.state.baseTemplate.owners.own_list.ownerpercent.value} />);
        }
        if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ownerssn && this.state.baseTemplate.owners.own_list.ownerssn !== null) {
            rows.push(<ReadOnlySimpleLink iHideShow={true} iTitle="Owner SSN" iName={"ownerssn_" + k} iValue={(this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k]) ? this.state.baseData.ownerData[k].ownerssn : this.state.baseTemplate.owners.own_list.ownerssn.value} />);
        }
        if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ownerdob && this.state.baseTemplate.owners.own_list.ownerdob !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="Date of Birth" iClass="input-calendar" iName={"ownerdob_" + k} iValue={this.props.global.stringDateFormat((this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k]) ? this.state.baseData.ownerData[k].ownerdob : this.state.baseTemplate.owners.own_list.ownerdob.value)} />);
        }
        if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ownerphone1 && this.state.baseTemplate.owners.own_list.ownerphone1 !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="Phone Number" iName={"ownerphone1_" + k} iValue={(this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k]) ? this.state.baseData.ownerData[k].ownerphone1 : this.state.baseTemplate.owners.own_list.ownerphone1.value} />);
        }
        if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ownerphone2 && this.state.baseTemplate.owners.own_list.ownerphone2 !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="Additional Phone Number" iName={"ownerphone2_" + k} iValue={(this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k]) ? this.state.baseData.ownerData[k].ownerphone2 : this.state.baseTemplate.owners.own_list.ownerphone2.value} />);
        }
        if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.owneremail && this.state.baseTemplate.owners.own_list.owneremail !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="Email Address" iName={"owneremail_" + k} iValue={(this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k]) ? this.state.baseData.ownerData[k].owneremail : this.state.baseTemplate.owners.own_list.owneremail.value} />);
        }
        if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ownerdriver && this.state.baseTemplate.owners.own_list.ownerdriver !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="Driver's License #" iName={"ownerdriver_" + k} iValue={(this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k]) ? this.state.baseData.ownerData[k].ownerdriver : this.state.baseTemplate.owners.own_list.ownerdriver.value} />);
        }
        if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.odriverstate && this.state.baseTemplate.owners.own_list.odriverstate !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="Driver's State License" iName={"odriverstate_" + k} iValue={(this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k]) ? this.state.baseData.ownerData[k].odriverstate : this.state.baseTemplate.owners.own_list.odriverstate.value} />);
        }
        if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.oaddress && this.state.baseTemplate.owners.own_list.oaddress !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="Owner Address" iName={"oaddress_" + k} iValue={(this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k]) ? this.state.baseData.ownerData[k].oaddress : this.state.baseTemplate.owners.own_list.oaddress.value} />);
        }
        if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ocity && this.state.baseTemplate.owners.own_list.ocity !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="City" iName={"ocity_" + k} iValue={(this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k]) ? this.state.baseData.ownerData[k].ocity : this.state.baseTemplate.owners.own_list.ocity.value} />);
        }
        if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ostate && this.state.baseTemplate.owners.own_list.ostate !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="State" iName={"ostate_" + k} iValue={(this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k]) ? this.state.baseData.ownerData[k].ostate : this.state.baseTemplate.owners.own_list.ostate.value} />);
        }
        if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ozip && this.state.baseTemplate.owners.own_list.ozip !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="Zip" iName={"ozip_" + k} iValue={(this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k]) ? this.state.baseData.ownerData[k].ozip : this.state.baseTemplate.owners.own_list.ozip.value} />);
        }
        if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ocountry && this.state.baseTemplate.owners.own_list.ocountry !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="Country" iName={"ocountry_" + k} iValue={(this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k]) ? this.state.baseData.ownerData[k].ocountry : this.state.baseTemplate.owners.own_list.ocountry.value} />);
        }


        return (
            <Row className="mt-4">
                {rows}
            </Row>
            );
    }

    getContactBlock(k) {
        if (!this.state.baseTemplate.owners) return;
        if (this.state.baseTemplate.owners.contact_list === null) return;
        var rows = [];

        if (this.state.baseTemplate.owners.contact_list.contactName && this.state.baseTemplate.owners.contact_list.contactName !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="Contact Name" iName={"contactName_" + k} iValue={(this.state.baseData.contactData!==null && this.state.baseData.contactData[k]) ? this.state.baseData.contactData[k].contactName : this.state.baseTemplate.owners.contact_list.contactName.value} />);
        }
        if (this.state.baseTemplate.owners.contact_list.contactTitle && this.state.baseTemplate.owners.contact_list.contactTitle !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="Contact Title" iName={"contactTitle_" + k} iValue={(this.state.baseData.contactData !== null && this.state.baseData.contactData[k]) ? this.state.baseData.contactData[k].contactTitle : this.state.baseTemplate.owners.contact_list.contactTitle.value} />);
        }
        if (this.state.baseTemplate.owners.contact_list.contactEmail && this.state.baseTemplate.owners.contact_list.contactEmail !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="Contact Email" iName={"contactEmail_" + k} iValue={(this.state.baseData.contactData !== null && this.state.baseData.contactData[k]) ? this.state.baseData.contactData[k].contactEmail : this.state.baseTemplate.owners.contact_list.contactEmail.value} />);
        }
        if (this.state.baseTemplate.owners.contact_list.contactPhone && this.state.baseTemplate.owners.contact_list.contactPhone !== null) {
            rows.push(<ReadOnlySimpleLink iTitle="Contact Phone" iName={"contactPhone_" + k} iValue={(this.state.baseData.contactData !== null && this.state.baseData.contactData[k]) ? this.state.baseData.contactData[k].contactPhone : this.state.baseTemplate.owners.contact_list.contactPhone.value} />);
        }

        return (
            <Row className="mt-4">
                {rows}
            </Row>
        );
    }

    getDocumentBlock() {
        var rows = [];
        var files=[];
        let zipfile = '';
        if (this.state.baseData.documentsRef && this.state.baseData.documentsRef !== null) {
            if (this.state.baseData.documentsRef.zipfile !== null) zipfile = <b>{"Documents stored in: " + this.state.baseData.documentsRef.zipfile}</b>;
            if (this.state.baseData.documentsRef.filelist !== null && this.state.baseData.documentsRef.filelist.length > 0) {
                for (var j = 0; j < this.state.baseData.documentsRef.filelist.length; j++) {
                    files.push(<><span onClick={(e)=>this.downloadFiles(this.state.baseData.documentsRef.zipfile,e)} className="text-success cursorPointer">{this.state.baseData.documentsRef.filelist[j].originalName}</span><br /></>);
                }
            }
        }
        let x = parseInt(this.state.baseTemplate.attributes.minimumDocuments, 10);
        if (files.length < x) {
            x = x - files.length;
            for (var i = 0; i < x; i++) {
                rows.push(
                    <div className="col-md-6 mt-2" key={i}>
                        <input type="file" className="form-control input-error" id={"doc_" + i} name={"doc_" + i} onChange={this.onFileChange} accept="image/jpeg,image/gif,image/png,application/pdf,application/vnd.ms-excel,application/msword,text/plain" />
                    </div>
                );
                if (!this.props.boarding.existsTemplateData("doc_" + i)) {
                    this.props.boarding.setErrorData("doc_" + i, true);
                    this.props.boarding.setTemplateData("doc_" + i, null);
                }
            }
        }
        else { x = 0;}
        if (this.state.additionalFiles.length > 0) {
            for (let j = 0; j < this.state.additionalFiles.length; j++) {
                let f = this.state.additionalFiles[j];
                rows.push(
                    <div className="col-md-6 mt-2" key={f}>
                        <InputGroup>
                            <input type="file" className="form-control input-error" id={"doc_" + f} name={"doc_" + f} onChange={this.onFileChange} accept="image/jpeg,image/gif,image/png,application/pdf,application/vnd.ms-excel,application/msword,text/plain" />
                            <Button variant="danger" onClick={(e) => this.removeDoc(f)}><BiTrash fontSize="1.5em" /></Button>
                        </InputGroup>
                    </div>
                );
                if (!this.props.boarding.existsTemplateData("doc_" + f)) {
                    this.props.boarding.setErrorData("doc_" + f, true);
                    this.props.boarding.setTemplateData("doc_" + f, null);
                }
            }
        }
        

        return (
            <><Row className="mt-2">
                <div className="col-3">
                    <p>{zipfile}</p>
                </div>
                <div className="col-9">
                    {files}
                </div>
            </Row></>
        );
    }

    getBusinessBlock() {
        var rows = [];
        if (this.state.baseTemplate.business && this.state.baseTemplate.business.details && this.state.baseTemplate.business.details !== null) {
            if (this.state.baseTemplate.business.details.dbaname && this.state.baseTemplate.business.details.dbaname !== null) {
                    rows.push(<ReadOnlySimpleLink iTitle="Business DBA Name" iName="dbaname" iValue={this.state.baseData.dbaName} />);
            }
            if (this.state.baseTemplate.business.details.legalname && this.state.baseTemplate.business.details.legalname !== null) {
                    rows.push(<ReadOnlySimpleLink iTitle="Business Legal Name" iName="legalname" iValue={this.state.baseData.legalName} />)
            }
            if (this.state.baseTemplate.business.details.btype && this.state.baseTemplate.business.details.btype !== null) {
                    rows.push(<ReadOnlySimpleLink iTitle="Business Type" iName="btype" iValue={this.state.baseData.ownType} />);
            }
            if (this.state.baseTemplate.business.details.ein && this.state.baseTemplate.business.details.ein !== null) {
                    rows.push(<ReadOnlySimpleLink iHideShow={true} iTitle="Business EIN" iName="ein" iValue={this.state.baseData.ein} />);
            }
            if (this.state.baseTemplate.business.details.taxfillname && this.state.baseTemplate.business.details.taxfillname !== null) {
                    rows.push(<ReadOnlySimpleLink iTitle="Tax Filing Name" iName="taxfillname" iValue={this.state.baseData.taxfillname} />);
            }
            if (this.state.baseTemplate.business.details.license && this.state.baseTemplate.business.details.license !== null) {
                    rows.push(<ReadOnlySimpleLink iTitle="Business License" iName="license" iValue={this.state.baseData.license} />);
            }
            if (this.state.baseTemplate.business.details.licstate && this.state.baseTemplate.business.details.licstate !== null) {
                    rows.push(<ReadOnlySimpleLink iTitle="License State" iName="licstate" iValue={this.state.baseData.licenseState} />);
            }
            if (this.state.baseTemplate.business.details.website && this.state.baseTemplate.business.details.website !== null) {
                    rows.push(<ReadOnlySimpleLink iTitle="Website" iName="website" iValue={this.state.baseData.websiteAddress} />);
            }
            if (this.state.baseTemplate.business.details.startdate && this.state.baseTemplate.business.details.startdate !== null) {
                    rows.push(<ReadOnlySimpleLink iClass="input-calendar" iTitle="Business Start Date"  iName="startdate" iValue={this.props.global.stringDateFormat(this.state.baseData.bStartdate)} />);
            }
            if (this.state.baseTemplate.business.details.phonenumber && this.state.baseTemplate.business.details.phonenumber !== null) {
                    rows.push(<ReadOnlySimpleLink iTitle="Phone Number" iName="phonenumber" iValue={this.state.baseData.bPhone} />);
            }
            if (this.state.baseTemplate.business.details.faxnumber && this.state.baseTemplate.business.details.faxnumber !== null) {
                    rows.push(<ReadOnlySimpleLink iTitle="Fax Number" iName="faxnumber" iValue={this.state.baseData.bFax} />);
            }
        }
        return (
            <Row className="mt-4">
                {rows}
            </Row>
            );
    }

    getBusinessAddressBlock() {
        var rows = [];
        var mrows = [];

        if (this.state.baseTemplate.business && this.state.baseTemplate.business.address && this.state.baseTemplate.business.address !== null) {
            if (this.state.baseTemplate.business.address.baddress && this.state.baseTemplate.business.address.baddress !== null) {
                    rows.push(<ReadOnlySimpleLink iTitle="Address line 1" iName="baddress" iValue={this.state.baseData.bAddress1} />);
            }

            if (this.state.baseTemplate.business.address.baddress1 && this.state.baseTemplate.business.address.baddress1 !== null) {
                    rows.push(<ReadOnlySimpleLink iTitle="Address line 2" iName="baddress1" iValue={this.state.baseData.bAddress2} />);
            }
            if (this.state.baseTemplate.business.address.bcity && this.state.baseTemplate.business.address.bcity !== null) {
                    rows.push(<ReadOnlySimpleLink iTitle="City" iName="bcity" iValue={this.state.baseData.bCity} />);
            }
            if (this.state.baseTemplate.business.address.bstate && this.state.baseTemplate.business.address.bstate !== null) {
                    rows.push(<ReadOnlySimpleLink iTitle="State" iName="bstate" iValue={this.state.baseData.bState} />);
            }
            if (this.state.baseTemplate.business.address.bzip && this.state.baseTemplate.business.address.bzip !== null) {
                    rows.push(<ReadOnlySimpleLink iTitle="Zip" iName="bzip" iValue={this.state.baseData.bZip} />);
            }
            if (this.state.baseTemplate.business.address.bcountry && this.state.baseTemplate.business.address.bcountry !== null) {
                    rows.push(<ReadOnlySimpleLink iTitle="Country" iName="bcountry" iValue={this.state.baseData.bCountry} />);
            }
            if (this.state.baseTemplate.business.address.maddress && this.state.baseTemplate.business.address.maddress !== null) {
                    mrows.push(<ReadOnlySimpleLink iTitle="Mailing Line Address 1" iName="maddress" iValue={this.state.baseData.mAddress1} />);
            }

            if (this.state.baseTemplate.business.address.maddress1 && this.state.baseTemplate.business.address.maddress1 !== null) {
                    mrows.push(<ReadOnlySimpleLink iTitle="Mailing Line Address 2" iName="maddress1" iValue={this.state.baseData.mAddress2} />);
            }
            if (this.state.baseTemplate.business.address.mcity && this.state.baseTemplate.business.address.mcity !== null) {
                    mrows.push(<ReadOnlySimpleLink iTitle="Mailing City" iName="mcity" iValue={this.state.baseData.mCity} />);
            }
            if (this.state.baseTemplate.business.address.mstate && this.state.baseTemplate.business.address.mstate !== null) {
                    mrows.push(<ReadOnlySimpleLink iTitle="Mailing State" iName="mstate" iValue={this.state.baseData.mState} />);
            }
            if (this.state.baseTemplate.business.address.mzip && this.state.baseTemplate.business.address.mzip !== null) {
                    mrows.push(<ReadOnlySimpleLink iTitle="Mailing Zip" iName="mzip" iValue={this.state.baseData.mZip} />);
            }
            if (this.state.baseTemplate.business.address.mcountry && this.state.baseTemplate.business.address.mcountry !== null) {
                    mrows.push(<ReadOnlySimpleLink iTitle="Mailing Country" iName="mcountry" iValue={this.state.baseData.mCountry} />);
            }
        }
        return (
            <><Row className="mt-2">
                {rows}
            </Row><hr /><Row className="mt-2">
                    {mrows}
                </Row></>
        );
    }

    getProcessingBlock() {
        if (this.state.baseTemplate.processing === null) return;
        var rows = [];

        if (this.state.baseTemplate.processing.mcc && this.state.baseTemplate.processing.mcc !== null) {
                rows.push
                (<ReadOnlySimpleLink iTitle="Industry (Merchant Category Code)" iName="mcc" iValue={this.props.global.getMccOptionName(this.state.baseData.mccid)} />);
        }
        if (this.state.baseTemplate.processing.bsummary && this.state.baseTemplate.processing.bsummary !== null) {
                rows.push(<ReadOnlySimpleLink iTitle="Tell us about your Business" iName="bsummary" iValue={this.state.baseData.bSummary} />);
        }
        if (this.state.baseTemplate.processing.binperson && this.state.baseTemplate.processing.binperson !== null) {
                rows.push(<ReadOnlySimpleLink iTitle="Percent of payments in Person" iName="binperson" iValue={this.state.baseData.binPerson} />);
        }
        if (this.state.baseTemplate.processing.binphone && this.state.baseTemplate.processing.binphone !== null) {
                rows.push(<ReadOnlySimpleLink iTitle="Percent of payments by Phone" iName="binphone" iValue={this.state.baseData.binPhone} />);
        }
        if (this.state.baseTemplate.processing.binweb && this.state.baseTemplate.processing.binweb !== null) {
                rows.push(<ReadOnlySimpleLink iTitle="Percent of payments online" iName="binweb" iValue={this.state.baseData.binWeb} />);
        }
        if (this.state.baseTemplate.processing.avgmonthly && this.state.baseTemplate.processing.avgmonthly !== null) {
                rows.push(<ReadOnlySimpleLink iMask="money" iTitle="Average Monthly Volume" iName="avgmonthly" iValue={this.state.baseData.averageMonthlyVolume} />);
        }
        if (this.state.baseTemplate.processing.ticketamt && this.state.baseTemplate.processing.ticketamt !== null) {
                rows.push(<ReadOnlySimpleLink iMask="money" iTitle="Average Ticket Amount" iName="ticketamt" iValue={this.state.baseData.averageTicketAmount} />);
        }
        if (this.state.baseTemplate.processing.highticketamt && this.state.baseTemplate.processing.highticketamt !== null) {
                rows.push(<ReadOnlySimpleLink iMask="money" iTitle="High Ticket Amount" iName="highticketamt" iValue={this.state.baseData.highTicketAmount} />);
        }
        if (this.state.baseTemplate.processing.whenCharged && this.state.baseTemplate.processing.whenCharged !== null) {
                rows.push(<ReadOnlySimpleLink iTitle="When is Payment Charged" iName="whenCharged" iValue={this.state.baseData.whencharged} />);
        }
        if (this.state.baseTemplate.processing.whenProvided && this.state.baseTemplate.processing.whenProvided !== null) {
                rows.push(<ReadOnlySimpleLink iTitle="Service Generally Provided In" iName="whenProvided" iValue={this.state.baseData.whenprovided} />);
        }
        if (this.state.baseTemplate.processing.whenDelivered && this.state.baseTemplate.processing.whenDelivered !== null) {
                rows.push(<ReadOnlySimpleLink iTitle="Products/Services Delivered In" iName="whenDelivered" iValue={this.state.baseData.whendelivered} />);
        }
        if (this.state.baseTemplate.processing.whenRefunded && this.state.baseTemplate.processing.whenRefunded !== null) {
                rows.push(<ReadOnlySimpleLink iTitle="Refund Policy" iName="whenRefunded" iValue={this.state.baseData.whenrefund} />);
        }
        return (
            <Row className="mt-4">
                {rows}
            </Row>
        );
    }

    getCardAcceptance() {
        if ((this.state.baseTemplate.services && this.state.baseTemplate.services.card === null) || (this.state.baseTemplate.services && this.state.baseTemplate.services.card.acceptance === null)) return;
        var rows = [];
        if (this.state.baseTemplate.services && this.state.baseTemplate.services.card.acceptance.visa && this.state.baseTemplate.services.card.acceptance.visa !== null) {
                rows.push(<ReadOnlyCheckLink iTitle="Visa" iName="card_visa" iValue={this.state.baseTemplate.services.card.acceptance.visa.value} />);
        }
        if (this.state.baseTemplate.services && this.state.baseTemplate.services.card.acceptance.mastercard && this.state.baseTemplate.services.card.acceptance.mastercard !== null) {
                rows.push(<ReadOnlyCheckLink iTitle="Mastercard" iName="card_mastercard" iValue={this.state.baseTemplate.services.card.acceptance.mastercard.value} />);
        }
        if (this.state.baseTemplate.services && this.state.baseTemplate.services.card.acceptance.discover && this.state.baseTemplate.services.card.acceptance.discover !== null) {
                rows.push(<ReadOnlyCheckLink iTitle="Discover" iName="card_discover" iValue={this.state.baseTemplate.services.card.acceptance.discover.value} />);
        }
        if (this.state.baseTemplate.services && this.state.baseTemplate.services.card.acceptance.amex && this.state.baseTemplate.services.card.acceptance.amex !== null) {
                rows.push(<ReadOnlyCheckLink iTitle="Amex" iName="card_amex" iValue={this.state.baseTemplate.services.card.acceptance.amex.value} />);
        }
        return (
            <Row className="mt-2 mb-5">
                <label className="mb-2 small"><b>Card Acceptance</b></label><br />
                {rows}
            </Row>
        );
    }

    getAchAcceptance() {
        if ((this.state.baseTemplate.services && this.state.baseTemplate.services.ach === null) || (this.state.baseTemplate.services && this.state.baseTemplate.services.ach.acceptance === null)) return;
        var rows = [];
        if (this.state.baseTemplate.services && this.state.baseTemplate.services.ach.acceptance.web) {
                rows.push(<ReadOnlyCheckLink iTitle="Web" iName="ach_web" iValue={this.state.baseTemplate.services.ach.acceptance.web.value} />);
        }
        if (this.state.baseTemplate.services && this.state.baseTemplate.services.ach.acceptance.ppd) {
                rows.push(<ReadOnlyCheckLink iTitle="PPD" iName="ach_ppd" iValue={this.state.baseTemplate.services.ach.acceptance.ppd.value} />);
        }
        if (this.state.baseTemplate.services && this.state.baseTemplate.services.ach.acceptance.ccd) {
                rows.push(<ReadOnlyCheckLink iTitle="CCD" iName="ach_ccd" iValue={this.state.baseTemplate.services.ach.acceptance.ccd.value} />);
        }
        return (
            <Row className="mt-2 mb-5">
                <label className="mb-2 small"><b>ACH Acceptance</b></label><br />
                {rows}
            </Row>
        );
    }


    render() {
        return (
            <Layout {...this.props}>
                {this.props.global.isLoading ?
                    <div id="main-loading-layer" className="d-flex justify-content-center align-items-center">

                    </div>
                    :
                    <div>
                        <TopBar>
                            <MainTopBarMenu/>
                            <div className="top-bar-sub">
                            {
                                <PaypointLinks idPaypoint={this.state.idPaypoint ? this.state.idPaypoint : ''} entry={this.state.entry ? this.state.entry : ''} gobackLink={"/"+PayabliStorageManager.getEntryName()+"/paypoints/overview/"+ (this.state.entry ? this.state.entry : '')+"/"+(this.state.idPaypoint ? this.state.idPaypoint : '')} goback={true}  gobackText="Paypoints Overview" history={this.props.history} selected="applications" />

                            }
                            </div>
                        </TopBar>
                        
                        <div className="mt-body4">
                            
                        <div className="mb-5">
                                {this.state.baseData && this.state.baseData.dbaName ?
                                    <>
                                    <h5 className="mt-2 mb-0">{this.state.baseData.dbaName}</h5>
                                    <p className="small-grey mb-5 mt-0">View Application</p>
                                    </>
                                :
                                    <h5 className="mt-2 mb-5">View Application</h5>
                                }
                            </div>
                            {this.state.baseTemplate &&
                            <div className="application-form view-application-form" style={{marginTop: 0}}>
                           
                                    <div className="application-tabs" style={{padding: 0}}>
                                        
                                        
                                            <Tabs defaultActiveKey={this.state.currentSection} className="">
                                                <Tab eventKey="business" title={<><BiDetail className="icon"/><br/><span>Business Details</span></>}>
                                                    <h5>Business Details</h5>
                                                    <p className="small mb-4 dark-grey">You have an awesome business, tell us a little bit about it! </p>
                                                    {this.getBusinessBlock()}
                                                    {this.getBusinessAddressBlock()}
                                                </Tab>
                                                <Tab eventKey="owners" title={<><BiUserCheck className="icon"/><br/><span>Ownership and Contacts</span></>} >
                                                    <h5>Ownership and Contacts</h5>
                                                    <p className="small mb-4 dark-grey">Federal Law requires us to collect some information on the owners, the business, and whoever will be signing the Merchant Agreement.</p>
                                                    {this.getOwnerBlock(0)}
                                                    {this.state.additionalOwners.length > 0 &&
                                                        this.state.additionalOwners.map((k) => (
                                                            <Row className="mt-2" key={k}>
                                                               
                                                               {this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ownername &&
                                                                    <ReadOnlySimpleLink iTitle="Owner Name" iName={"ownername_" + k} iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownername : this.state.baseTemplate.owners.own_list.ownername.value} />}
                                                                {this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ownertitle &&
                                                                    <ReadOnlySimpleLink iTitle="Owner Title" iName={"ownertitle_" + k} iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownertitle : this.state.baseTemplate.owners.own_list.ownertitle.value} />}
                                                                {this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ownerpercent &&
                                                                    <ReadOnlySimpleLink iTitle="Ownership %" iName={"ownerpercent_" + k} iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerpercent : this.state.baseTemplate.owners.own_list.ownerpercent.value} />}
                                                                {this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ownerssn &&
                                                                    <ReadOnlySimpleLink iTitle="Owner SSN" iName={"ownerssn_" + k} iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerssn : this.state.baseTemplate.owners.own_list.ownerssn.value} />}
                                                                {this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ownerdob &&
                                                                    <ReadOnlySimpleLink iTitle="Date of Birth" iName={"ownerdob_" + k} iValue={this.props.global.stringDateFormat(this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerdob : this.state.baseTemplate.owners.own_list.ownerdob.value)} />}
                                                                {this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ownerphone1 &&
                                                                    <ReadOnlySimpleLink iTitle="Phone Number" iName={"ownerphone1_" + k} iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerphone1 : this.state.baseTemplate.owners.own_list.ownerphone1.value} />}
                                                                {this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ownerphone2 &&
                                                                    <ReadOnlySimpleLink iTitle="Additional Phone Number" iName={"ownerphone2_" + k} iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerphone2 : this.state.baseTemplate.owners.own_list.ownerphone2.value} />}
                                                                {this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.owneremail &&
                                                                    <ReadOnlySimpleLink iTitle="Email Address" iName={"owneremail_" + k} iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].owneremail : this.state.baseTemplate.owners.own_list.owneremail.value} />}
                                                                {this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ownerdriver &&
                                                                    <ReadOnlySimpleLink iTitle="Driver's License #" iName={"ownerdriver_" + k} iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerdriver : this.state.baseTemplate.owners.own_list.ownerdriver.value} />}
                                                                {this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.odriverstate &&
                                                                    <ReadOnlySimpleLink iTitle="Driver's State License" iName={"odriverstate_" + k} iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].odriverstate : this.state.baseTemplate.owners.own_list.odriverstate.value} />}
                                                                {this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.oaddress &&
                                                                    <ReadOnlySimpleLink iTitle="Owner Address" iName={"oaddress_" + k} iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].oaddress : this.state.baseTemplate.owners.own_list.oaddress.value} />}
                                                                {this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ocity &&
                                                                    <ReadOnlySimpleLink iTitle="City" iName={"ocity_" + k} iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ocity : this.state.baseTemplate.owners.own_list.ocity.value} />}
                                                                {this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ostate &&
                                                                    <ReadOnlySimpleLink iTitle="State" iName={"ostate_" + k} iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ostate : this.state.baseTemplate.owners.own_list.ostate.value} />}
                                                                {this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ozip &&
                                                                    <ReadOnlySimpleLink iTitle="Zip" iName={"ozip_" + k} iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ozip : this.state.baseTemplate.owners.own_list.ozip.value} />}
                                                                {this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ocountry &&
                                                                    <ReadOnlySimpleLink iTitle="Country" iName={"ocountry_" + k} iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ocountry : this.state.baseTemplate.owners.own_list.ocountry.value} />}
                                                            </Row>
                                                        ))}
                                                 
                                                    {this.getContactBlock(0)}
                                                    {(this.state.additionalContacts.length > 0 && this.state.baseTemplate.owners && this.state.baseTemplate.owners.contact_list!==null) &&                                                        this.state.additionalContacts.map((k) => (
                                                            <Row className="mt-2" key={k}>
                                                                {this.state.baseTemplate.owners.contact_list.contactName &&
                                                                    <ReadOnlySimpleLink iTitle="Contact Name" iName={"contactName_" + k} iValue={this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactName : this.state.baseTemplate.owners.contact_list.contactName.value} />}
                                                                {this.state.baseTemplate.owners.contact_list.contactTitle &&
                                                                    <ReadOnlySimpleLink iTitle="Contact Title" iName={"contactTitle_" + k} iValue={this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactTitle : this.state.baseTemplate.owners.contact_list.contactTitle.value} />}
                                                                {this.state.baseTemplate.owners.contact_list.contactEmail &&
                                                                    <ReadOnlySimpleLink iTitle="Contact Email" iName={"contactEmail_" + k} iValue={this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactEmail : this.state.baseTemplate.owners.contact_list.contactEmail.value} />}
                                                                {this.state.baseTemplate.owners.contact_list.contactPhone &&
                                                                    <ReadOnlySimpleLink iTitle="Contact Phone" iName={"contactPhone_" + k} iValue={this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactPhone : this.state.baseTemplate.owners.contact_list.contactPhone.value} />}
                                                            </Row>
                                                        ))}
                                                   
                                                </Tab>
                                                <Tab eventKey="processing" title={<><BiCog className="icon"/><br/><span>Processing Information</span></>} >
                                                    <h5>Processing Information</h5>
                                                    <p className="small mb-4 dark-grey">We are so proud to power your payment processing, share with us your needs.</p>
                                                    {this.getProcessingBlock()}
                                                </Tab>
                                                <Tab eventKey="services" title={<><BiDollarCircle className="icon"/><br/><span>Services and Pricing</span></>} >
                                                    <h5>Services and Pricing</h5>
                                                    <p className="small mb-4 dark-grey">Below you will find the rates associated with your Payment Processing Account.</p>
                                                    {this.state.baseTemplate.services && this.state.baseTemplate.services.card !== null &&
                                                        <Row className="mt-4">
                                                            <ReadOnlyCheckLink iTitle="Accept Card Processing Services" iName="card" iValue={true} />
                                                        </Row>}
                                                    {this.getCardAcceptance()}
                                                    {(this.state.baseTemplate.services && this.state.baseTemplate.services.card !== null && this.state.baseTemplate.services.card.price !== null) &&
                                                        <Table className="table-striped">
                                                            {this.state.baseTemplate.services.card.price.header !== null &&
                                                                <thead>
                                                                    <tr>
                                                                        {this.state.baseTemplate.services.card.price.header.columns.map((colr, index) => (
                                                                            <th key={index}>{colr.value}</th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>}
                                                            {this.state.baseTemplate.services.card.price.body !== null &&
                                                                <tbody>
                                                                    {this.state.baseTemplate.services.card.price.body.map((rowr, index) => (
                                                                        <tr key={index}>
                                                                            {rowr.columns.map((colr, ic) => (
                                                                                <td key={ic}>{colr.value}</td>
                                                                            ))}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>}
                                                        </Table>}
                                                    {(this.state.baseTemplate.services && this.state.baseTemplate.services.card !== null && this.state.baseTemplate.services.card.fees !== null) &&
                                                        <Table className="table-striped">
                                                            {this.state.baseTemplate.services.card.fees.header !== null &&
                                                                <thead>
                                                                    <tr>
                                                                        {this.state.baseTemplate.services.card.fees.header.columns.map((colr, index) => (
                                                                            <th key={index}>{colr.value}</th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>}
                                                            {this.state.baseTemplate.services.card.fees.body !== null &&
                                                                <tbody>
                                                                    {this.state.baseTemplate.services.card.fees.body.map((rowr, index) => (
                                                                        <tr key={index}>
                                                                            {rowr.columns.map((colr, ic) => (
                                                                                <td key={ic}>{colr.value}</td>
                                                                            ))}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>}
                                                        </Table>}
                                                    {this.state.baseTemplate.services && this.state.baseTemplate.services.ach !== null &&
                                                        <Row className="mt-4">
                                                            <ReadOnlyCheckLink iTitle="Accept ACH Processing Services" iName="ach" iValue={true} />
                                                        </Row>}
                                                    {this.getAchAcceptance()}
                                                    {(this.state.baseTemplate.services && this.state.baseTemplate.services.ach !== null && this.state.baseTemplate.services.ach.price !== null) &&
                                                        <Table className="table-striped">
                                                            {this.state.baseTemplate.services.ach.price.header !== null &&
                                                                <thead>
                                                                    <tr>
                                                                        {this.state.baseTemplate.services.ach.price.header.columns.map((colr, index) => (
                                                                            <th key={index}>{colr.value}</th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>}
                                                            {this.state.baseTemplate.services.ach.price.body !== null &&
                                                                <tbody>
                                                                    {this.state.baseTemplate.services.ach.price.body.map((rowr, index) => (
                                                                        <tr key={index}>
                                                                            {rowr.columns.map((colr, ic) => (
                                                                                <td key={ic}>{colr.value}</td>
                                                                            ))}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>}
                                                        </Table>}
                                                    {(this.state.baseTemplate.services && this.state.baseTemplate.services.ach !== null && this.state.baseTemplate.services.ach.fees !== null) &&
                                                        <Table className="table-striped">
                                                            {this.state.baseTemplate.services.ach.fees.header !== null &&
                                                                <thead>
                                                                    <tr>
                                                                        {this.state.baseTemplate.services.ach.fees.header.columns.map((colr, index) => (
                                                                            <th key={index}>{colr.value}</th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>}
                                                            {this.state.baseTemplate.services.ach.fees.body !== null &&
                                                                <tbody>
                                                                    {this.state.baseTemplate.services.ach.fees.body.map((rowr, index) => (
                                                                        <tr key={index}>
                                                                            {rowr.columns.map((colr, ic) => (
                                                                                <td key={ic}>{colr.value}</td>
                                                                            ))}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>}
                                                        </Table>}

                                                </Tab>
                                                <Tab eventKey="banking" title={<><RiBankLine className="icon"/><br/><span>Banking and Documents</span></>}>
                                                    <h5>Banking and Documents</h5>
                                                    <p className="small mb-4 dark-grey">We are almost done! Please provide us with some important supporting documents and where you want us to send your funds </p>
                                                    {(this.state.baseTemplate.banking !== null && (this.state.baseTemplate.banking.depositAccount !== null || this.state.baseTemplate.banking.withdrawalAccount !== null)) &&
                                                        <Row className="mt-4">
                                                            {this.state.baseTemplate.banking.depositAccount !== null &&
                                                                <div className="col-6">
                                                                     <p className="mb-3"><b>Deposit Account</b></p>
                                                                    {this.state.baseTemplate.banking.depositAccount.bankName &&
                                                            <ReadOnlySimpleLink full iTitle="Bank Name" iName="deposit_bankName" iValue={(this.state.baseData.bankData!==null && this.state.baseData.bankData[0]) ? this.state.baseData.bankData[0].bankName : this.state.baseTemplate.banking.depositAccount.bankName.value} />}
                                                                    {this.state.baseTemplate.banking.depositAccount.routingAccount &&
                                                            <ReadOnlySimpleLink full iTitle="Routing Number" iName="deposit_routingAccount" iValue={(this.state.baseData.bankData !== null && this.state.baseData.bankData[0]) ? this.state.baseData.bankData[0].routingAccount : this.state.baseTemplate.banking.depositAccount.routingAccount.value} />}
                                                                    {this.state.baseTemplate.banking.depositAccount.accountNumber &&
                                                            <ReadOnlySimpleLink iHideShow={true} full iTitle="Account Number" iName="deposit_accountNumber" iValue={(this.state.baseData.bankData !== null && this.state.baseData.bankData[0]) ? this.state.baseData.bankData[0].accountNumber : this.state.baseTemplate.banking.depositAccount.accountNumber.value} />}
                                                                    {this.state.baseTemplate.banking.depositAccount.typeAccount &&
                                                            <ReadOnlySimpleLink full iTitle="Account Type" iName="deposit_typeAccount" iValue={(this.state.baseData.bankData !== null && this.state.baseData.bankData[0]) ? this.state.baseData.bankData[0].typeAccount : this.state.baseTemplate.banking.depositAccount.typeAccount.value} />}
                                                                </div>}

                                                            {this.state.baseTemplate.banking.withdrawalAccount !== null &&
                                                                <div className="col-6">
                                                                     <p className="mb-3"><b>Withdrawal Account</b></p>
                                                                    {this.state.baseTemplate.banking.withdrawalAccount.bankName &&
                                                            <ReadOnlySimpleLink full iTitle="Bank Name" iName="withdrawal_bankName" iValue={(this.state.baseData.bankData !== null && this.state.baseData.bankData[1]) ? this.state.baseData.bankData[1].bankName : this.state.baseTemplate.banking.withdrawalAccount.bankName.value} />}
                                                                    {this.state.baseTemplate.banking.withdrawalAccount.routingAccount &&
                                                            <ReadOnlySimpleLink full iTitle="Routing Number" iName="withdrawal_routingAccount" iValue={(this.state.baseData.bankData !== null && this.state.baseData.bankData[1]) ? this.state.baseData.bankData[1].routingAccount : this.state.baseTemplate.banking.withdrawalAccount.routingAccount.value} />}
                                                                    {this.state.baseTemplate.banking.withdrawalAccount.accountNumber &&
                                                            <ReadOnlySimpleLink iHideShow={true} full iTitle="Account Number" iName="withdrawal_accountNumber" iValue={(this.state.baseData.bankData !== null && this.state.baseData.bankData[1]) ? this.state.baseData.bankData[1].accountNumber : this.state.baseTemplate.banking.withdrawalAccount.accountNumber.value} />}
                                                                    {this.state.baseTemplate.banking.withdrawalAccount.typeAccount &&
                                                            <ReadOnlySimpleLink full iTitle="Account Type" iName="withdrawal_typeAccount" iValue={(this.state.baseData.bankData !== null && this.state.baseData.bankData[1]) ? this.state.baseData.bankData[1].typeAccount : this.state.baseTemplate.banking.withdrawalAccount.typeAccount.value} />}
                                                                </div>}
                                                        </Row>}
                                                    <br />
                                                    {(this.state.baseTemplate.attributes && this.state.baseTemplate.attributes.minimumDocuments !== null && parseInt(this.state.baseTemplate.attributes.minimumDocuments, 10) > 0) &&
                                                        this.getDocumentBlock()}
                                                        {this.getSignerBlock()}
                                                </Tab>
                                            </Tabs> 
                                    </div>
                                    <ToastContainer transition={Bounce} />
                                </div>
                                || 
                                      
                                <div className="card-v2 mb-3 info-message-card"><BiInfoCircle style={{fontSize: "20px"}}/> Records not found</div>
                            }
                        </div>
                        <br />
                        <br />
                    </div>}
            </Layout>
            );
    }
}

export { ApplicationReport };